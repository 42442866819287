export const getStartDate = installations => {
  const sorted = installations.sort((a, b) => new Date(a.harvestDate) - new Date(b.harvestDate))

  return sorted[0]?.harvestDate
}

export const calculateNitrogenUnits = ({ water, volume, efficiency }) => {
  const PRIMARY_CALC = 0.000226

  return (PRIMARY_CALC * water * volume * efficiency) / 100
}
