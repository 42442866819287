import React, { useState, useEffect } from 'react'
import { Row, Col, Select, Form, Button, DatePicker, InputNumber, Input, Alert, Modal, Skeleton } from 'antd'
import { arrayOf, shape, string, number, func } from 'prop-types'
import { PlusCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import moment from 'moment'

import { getProvidersQuery, getFieldbookQuery, getProductsQuery, getPhenologicalStatesQuery } from '@graphql/queries'
import { useCreatePesticide, useUpdatePesticide } from '@graphql/mutations'
import { meteorologyTypes } from '@constants/AppConstants'

import ManageProductForm from '../../../ManageProduct/ManageProductForm'
import ManageProviderForm from '../../../ManageProvider/ManageProviderForm'
import ManagePhenologicalStateForm from '../../../ManagePhenologicalState/ManagePhenologicalStateForm'

const { Option } = Select

function FieldbookPesticideForm({ data, afterSubmit, editableData }) {
  const [form] = Form.useForm()
  const [createPesticide, { loading }] = useCreatePesticide()
  const [updatePesticide, { loading: updateLoading }] = useUpdatePesticide()
  const [newProduct, setNewProduct] = useState(false)
  const [newProvider, setNewProvider] = useState(false)
  const [newPhenologicalState, setNewPhenologicalState] = useState(false)
  const [autoSafeHarvestDate, setAutoSafeHarvestDate] = useState(false)
  const [editMode, setEditMode] = useState(false)
  const { loading: loadingProviders, data: providersData } = useQuery(getProvidersQuery)
  const { loading: loadingProducts, data: productsData } = useQuery(getProductsQuery)
  const { loading: loadingPhenologicalStates, data: phenologicalStatesData } = useQuery(getPhenologicalStatesQuery)
  const { id } = data

  const onFinish = fields => {
    const {
      product,
      date,
      area,
      plantingWeeks,
      sauce,
      provider,
      meteorology,
      safeHarvestDate,
      phenologicalState,
    } = fields
    const mutation = editMode ? updatePesticide : createPesticide
    const refetchQueries = [
      {
        query: getFieldbookQuery,
        variables: { id },
      },
    ].filter(Boolean)

    mutation({
      variables: {
        ...editMode && editableData && { id: editableData.id },
        data: {
          meteorology: editMode ? { set: meteorology } : meteorology,
          fieldbook: {
            connect: { id },
          },
          product: {
            connect: { id: product },
          },
          phenologicalState: {
            connect: { id: phenologicalState },
          },
          provider: {
            connect: { id: provider },
          },
          date: editMode ? { set: date } : date,
          area: editMode ? { set: Number(area) } : Number(area),
          plantingWeeks: editMode ? { set: plantingWeeks } : plantingWeeks,
          sauce: editMode ? { set: Number(sauce) } : Number(sauce),
          safeHarvestDate: editMode ? { set: safeHarvestDate } : safeHarvestDate,
        },
      },
      refetchQueries,
    }).then(() => {
      setNewProduct(false)
      setNewProvider(false)
      setAutoSafeHarvestDate(false)
      afterSubmit()
      form.resetFields()
    })
  }

  const calculateSafeHarvestDate = () => {
    const requiredFields = ['product', 'date']
    const { date, product } = form.getFieldsValue(requiredFields)
    const { activeSubstance } = product && productsData?.products.find(({ id }) => id === product)

    if (date && activeSubstance?.withdrawalPeriod) {
      form.setFieldsValue({ safeHarvestDate: moment(date).add(activeSubstance?.withdrawalPeriod, 'days') })
      setAutoSafeHarvestDate(true)
    }
  }

  useEffect(() => {
    setAutoSafeHarvestDate(false)
    setEditMode(false)
    form.resetFields()

    if (editableData) {
      const { date, product, provider, phenologicalState, safeHarvestDate, ...rest } = editableData

      form.setFieldsValue({
        date: moment(date),
        product: product.id,
        provider: provider.id,
        phenologicalState: phenologicalState.id,
        safeHarvestDate: moment(safeHarvestDate),
        ...rest,
      })

      setEditMode(true)
    }
  }, [editableData, form])

  return (
    <Form onFinish={onFinish} form={form}>
      <Skeleton loading={loadingProviders || loadingProducts || loadingPhenologicalStates} active>
        <Row>
          <Col span={24}>
            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => setNewProduct(!newProduct)}
              style={{ marginRight: 10 }}
            >
              Produto
            </Button>

            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => setNewProvider(!newProvider)}
              style={{ marginRight: 10 }}
            >
              Fornecedor
            </Button>

            <Button
              icon={<PlusCircleOutlined />}
              onClick={() => setNewPhenologicalState(!newPhenologicalState)}
            >
              Estado Fenológico
            </Button>
          </Col>
        </Row>

        <Row gutter={16} style={{ marginTop: 30 }}>
          <Col span={8}>
            {newProduct && (
              <Modal
                title="Novo produto"
                onCancel={() => setNewProduct(false)}
                footer={null}
                visible
              >
                <ManageProductForm afterSubmit={() => setNewProduct(false)} />
              </Modal>
            )}

            <Modal
              title="Novo estado fenológico"
              onCancel={() => setNewPhenologicalState(false)}
              footer={null}
              visible={newPhenologicalState}
            >
              <ManagePhenologicalStateForm afterSubmit={() => setNewPhenologicalState(false)} />
            </Modal>

            <Form.Item
              name="product"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <Select
                placeholder="Escolha um produto"
                size="large"
                optionFilterProp="children"
                showSearch
                onChange={calculateSafeHarvestDate}
                loading={loadingProducts}
              >
                {productsData && productsData?.products.map(({ id, name }) => (
                  <Option value={id} key={id}>{name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="date"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <DatePicker
                placeholder="Data da aplicação"
                size="large"
                style={{ width: '100%' }}
                onChange={calculateSafeHarvestDate}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="area"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <InputNumber
                placeholder="Área"
                size="large"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="plantingWeeks"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <Input
                placeholder="Semanas de Plantação"
                size="large"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="sauce"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <InputNumber
                placeholder="Calda"
                size="large"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            {newProvider && (
              <Modal
                title="Novo fornecedor"
                onCancel={() => setNewProvider(false)}
                footer={null}
                visible
              >
                <ManageProviderForm afterSubmit={() => setNewProvider(false)} />
              </Modal>
            )}

            <Form.Item
              name="provider"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <Select
                loading={loadingProviders}
                placeholder="Escolha um fornecedor"
                size="large"
                optionFilterProp="children"
                showSearch
              >
                {providersData && providersData.providers.map(({ id, name }) => (
                  <Option value={id} key={id}>{name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="safeHarvestDate"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <DatePicker
                placeholder="Data segura da colheita"
                size="large"
                style={{ width: '100%' }}
                onChange={() => setAutoSafeHarvestDate(false)}
              />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="meteorology"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <Select
                placeholder="Meteorologia"
                size="large"
                optionFilterProp="children"
              >
                {Object.keys(meteorologyTypes).map(key => (
                  <Option value={key} key={key}>{meteorologyTypes[key]}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="phenologicalState"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <Select
                placeholder="Escolha um estado fenológico"
                size="large"
                optionFilterProp="children"
                showSearch
                loading={loadingPhenologicalStates}
              >
                {phenologicalStatesData?.phenologicalStates.map(({ id, name }) => (
                  <Option value={id} key={id}>{name}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={8}>
            {autoSafeHarvestDate && (
              <Alert message="Data calculada automaticamente" type="info" showIcon style={{ marginBottom: 20 }} />
            )}
          </Col>
        </Row>

        <Row style={{ textAlign: 'right' }}>
          <Col span={24}>
            <Button htmlType="submit" size="large" type="primary" disabled={loading || updateLoading}>
              {editMode ? 'Actualizar' : 'Criar'} fitofármaco
            </Button>
          </Col>
        </Row>
      </Skeleton>
    </Form>
  )
}

FieldbookPesticideForm.propTypes = {
  data: shape({
    campaign: shape({
      culture: shape({
        varieties: arrayOf(
          shape({
            id: string,
            name: string,
            cicle: number,
          }),
        ),
      }),
    }),
  }).isRequired,
  editableData: shape({}),
  afterSubmit: func.isRequired,
}

FieldbookPesticideForm.defaultProps = {
  editableData: null,
}

export default FieldbookPesticideForm
