import React, { useState } from 'react'
import { arrayOf, shape, string, number } from 'prop-types'
import { Popconfirm, Table, Typography, Form, Button, Row, Col, Input, InputNumber } from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

import { useColumnFilterProps } from '@utils/columnFilter'
import { useCreateVariety, useUpdateVariety, useDeleteVariety } from '@graphql/mutations'

import EditableCell from '../../EditableCell'

const { Text } = Typography
const tableProps = {
  size: 'middle',
  pagination: false,
}

function ManageCultureVarieties({ cultureId, data }) {
  const [form] = Form.useForm()
  const [varietiesData, setVarietiesData] = useState(data)
  const [createParcel] = useCreateVariety()
  const [updateParcel] = useUpdateVariety()
  const [deleteParcel] = useDeleteVariety()
  const useFilterField = (field, fieldType) => useColumnFilterProps(field, fieldType)

  const handleSave = data => {
    const { id, name, cicle } = data
    const newData = [...varietiesData]
    const index = newData.findIndex(item => item.id === data.id)
    const item = newData[index]

    newData.splice(index, 1, { ...item, ...data })

    if (JSON.stringify(newData) !== JSON.stringify(varietiesData)) {
      updateParcel({
        variables: {
          culture: cultureId,
          id,
          data: {
            name: { set: name },
            cicle: { set: Number(cicle) },
          },
        },
      })

      setVarietiesData(newData)
    }
  }

  const handleDelete = id => {
    deleteParcel({
      variables: { culture: cultureId, id },
      refetchQueries: ['GetCultureQuery'],
    }).then(({ data: { updateOneCulture: { varieties } } }) => setVarietiesData(varieties))
  }

  const onFinish = ({ name, cicle }) => {
    createParcel({
      variables: {
        id: cultureId,
        data: {
          varieties: {
            create: {
              name,
              cicle: parseInt(cicle, 0),
            },
          },
        },
      },
      refetchQueries: ['GetCultureQuery'],
    }).then(({
      data: {
        updateOneCulture: { varieties },
      },
    }) => setVarietiesData(varieties), form.resetFields())
  }
  const columns = [
    {
      dataIndex: 'name',
      title: 'Nome',
      editable: true,
      ...useFilterField('name'),
      render: value => <Text code>{value}</Text>,
    },
    {
      dataIndex: 'cicle',
      title: 'Ciclo',
      editable: true,
      align: `center`,
      ...useFilterField(`cicle`),
      render: value => <Text code>{value}</Text>,
    },
    {
      align: 'center',
      render: (value, { id, name }) => (
        <Popconfirm
          title={`Tem a certeza que quer eliminar "${name}" ?`}
          onConfirm={() => handleDelete(id)}
          okText="Sim"
          cancelText="Não"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ]
  const mappedColumns = columns.map(col => {
    if (!col.editable) return col

    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        inputType: col.inputType,
        title: col.title,
        handleSave,
      }),
    }
  })

  return (
    <>
      <Form onFinish={onFinish} form={form}>
        <Row gutter={16}>
          <Col span={13}>
            <Form.Item
              name="name"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Nome da variedade" size="large" autoFocus />
            </Form.Item>
          </Col>

          <Col span={5}>
            <Form.Item
              name="cicle"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <InputNumber
                placeholder="Ciclo"
                size="large"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          <Col span={6}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              block
            >
              Adicionar variedade
            </Button>
          </Col>
        </Row>
      </Form>

      {varietiesData && (
        <Table
          components={EditableCell.Components}
          columns={mappedColumns}
          dataSource={varietiesData}
          rowKey={({ id }) => id}
          rowClassName={() => 'editable-row'}
          {...tableProps}
        />
      )}
    </>
  )
}

ManageCultureVarieties.propTypes = {
  cultureId: string.isRequired,
  data: arrayOf(
    shape({
      id: string,
      number: string,
      area: number,
    }),
  ),
}

ManageCultureVarieties.defaultProps = {
  data: [],
}

export default ManageCultureVarieties
