import { gql } from 'apollo-boost'

import useMutation, { mutationTypes } from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation(
    $id: String!
    $data: ActiveSubstanceUpdateInput!
  ) {
    updateOneActiveSubstance(
      where: {
        id: $id
      }
      data: $data
    ) {
      id
      activeSubstanceFamily {
        id
        name
      }
    }
  }
`

export default () => (
  useMutation(MUTATION, `Substância activa actualizada`, mutationTypes.UPDATE)
)

