import { gql } from 'apollo-boost'

import useMutation, { mutationTypes } from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation(
    $data: ApplicationTypeCreateInput!
  ) {
    createOneApplicationType(data: $data) {
      name
    }
  }
`

export default () => (
  useMutation(MUTATION, `Tipo de aplicação criado`, mutationTypes.CREATE)
)
