import { PlusCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/react-hooks";
import { Button, Col, Form, InputNumber, Modal, Row, Select, Skeleton } from "antd";
import { arrayOf, func, number, shape, string } from "prop-types";
import React, { useEffect, useState } from "react";

import {
  getApplicationTypesQuery,
  getFieldbookQuery,
  getUnitiesQuery,
} from "@graphql/queries";
import { useCreateFertilizationPlan, useUpdateFertilizationPlan } from "@graphql/mutations";

import ManageApplicationTypeForm from "../../ManageApplicationType/ManageApplicationTypeForm";

const { Option } = Select;

function FieldbookFertilizationForm({ data, afterSubmit, editableData }) {
  const [form] = Form.useForm();
  const [createFertilizationPlan, { loading }] = useCreateFertilizationPlan();
  const [updateFertilizationPlan, { loading: updateLoading }] = useUpdateFertilizationPlan();
  const [newApplicationType, setNewApplicationType] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { loading: loadingUnities, data: unitiesData } = useQuery(getUnitiesQuery);
  const { loading: loadingApplicationTypes, data: applicationTypesData } = useQuery(getApplicationTypesQuery);
  const { id } = data;

  const onFinish = fields => {
    const { kgOfN, n, p, k, dose, unity, applicationType } = fields;
    const mutation = editMode ? updateFertilizationPlan : createFertilizationPlan;
    const refetchQueries = [
      {
        query: getFieldbookQuery,
        variables: { id },
      },
    ].filter(Boolean);

    mutation({
      variables: {
        ...(editMode && editableData && { id: editableData.id }),
        data: {
          fieldbook: {
            connect: { id },
          },
          unity: {
            connect: { id: unity },
          },
          applicationType: {
            connect: { id: applicationType },
          },
          dose: editMode ? { set: dose } : dose,
          kgOfN: editMode ? { set: kgOfN } : kgOfN,
          n: editMode ? { set: n } : n,
          p: editMode ? { set: p } : p,
          k: editMode ? { set: k } : k,
        },
      },
      refetchQueries,
    }).then(() => {
      afterSubmit();
      form.resetFields();
    });
  };

  useEffect(() => {
    setEditMode(false);
    form.resetFields();

    if (editableData) {
      const { unity, applicationType, ...rest } = editableData;

      form.setFieldsValue({
        unity: unity.id,
        applicationType: applicationType.id,
        ...rest,
      });

      setEditMode(true);
    }
  }, [editableData, form]);

  return (
    <>
      <Form onFinish={onFinish} form={form}>
        <Skeleton loading={loadingUnities} active>
          <Row style={{ marginBottom: 15 }}>
            <Col span={24}>
              <Button icon={<PlusCircleOutlined />} onClick={() => setNewApplicationType(!newApplicationType)}>
                Tipo de Aplicação
              </Button>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="applicationType"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <Select
                  placeholder="Tipo de aplicação"
                  size="large"
                  optionFilterProp="children"
                  showSearch
                  loading={loadingApplicationTypes}
                >
                  {applicationTypesData?.applicationTypes.map(({ id, name }) => (
                    <Option value={id} key={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="unity"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <Select
                  loading={loadingUnities}
                  placeholder="Escolha uma unidade"
                  size="large"
                  optionFilterProp="children"
                  showSearch
                >
                  {unitiesData?.unities.map(({ id, name }) => (
                    <Option value={id} key={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="dose"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <InputNumber placeholder="Dose" size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="kgOfN"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <InputNumber placeholder="Kg de N" size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Row gutter={16}>
                <Col span={8}>
                  <Form.Item
                    name="n"
                    rules={[
                      {
                        required: true,
                        message: `Campo obrigatório`,
                      },
                    ]}
                    hasFeedback
                  >
                    <InputNumber placeholder="N" size="large" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="p"
                    rules={[
                      {
                        required: true,
                        message: `Campo obrigatório`,
                      },
                    ]}
                    hasFeedback
                  >
                    <InputNumber placeholder="P" size="large" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>

                <Col span={8}>
                  <Form.Item
                    name="k"
                    rules={[
                      {
                        required: true,
                        message: `Campo obrigatório`,
                      },
                    ]}
                    hasFeedback
                  >
                    <InputNumber placeholder="K" size="large" style={{ width: "100%" }} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row style={{ textAlign: "right" }}>
            <Col span={24}>
              <Button htmlType="submit" size="large" type="primary" disabled={loading || updateLoading}>
                {editMode ? "Actualizar" : "Criar"} plano de fertilização
              </Button>
            </Col>
          </Row>
        </Skeleton>
      </Form>

      <Modal
        title="Novo tipo de aplicação"
        onCancel={() => setNewApplicationType(false)}
        footer={null}
        visible={newApplicationType}
      >
        <ManageApplicationTypeForm afterSubmit={() => setNewApplicationType(false)} />
      </Modal>
    </>
  );
}

FieldbookFertilizationForm.propTypes = {
  data: shape({
    campaign: shape({
      culture: shape({
        varieties: arrayOf(
          shape({
            id: string,
            name: string,
            cicle: number,
          }),
        ),
      }),
    }),
  }).isRequired,
  editableData: shape({}),
  afterSubmit: func.isRequired,
};

FieldbookFertilizationForm.defaultProps = {
  editableData: null,
};

export default FieldbookFertilizationForm;
