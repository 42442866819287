import React, { useState, useEffect } from "react";
import { shape } from "prop-types";
import { Row, Col, Button, Modal, Table, Skeleton, Popconfirm } from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
  RedoOutlined,
} from "@ant-design/icons";

import { getFieldbookQuery } from "@graphql/queries";
import { useDeleteFertilization } from "@graphql/mutations";
import { formatDate } from "@utils/dateUtils";

import FieldbookFertilizationsHistoric from "../FieldbookFertilizationsHistoric";

import FieldbookFertilizationForm from "./FieldbookFertilizationForm";

const tableProps = {
  size: "middle",
  pagination: false,
};

function FieldbookFertilizations({ fieldbook }) {
  const [modalVisible, setModalVisible] = useState(false);
  const toggleModal = () => setModalVisible(!modalVisible);
  const [editableData, setEditableData] = useState();
  const [importHistoric, setImportHistoric] = useState(false);
  const toggleImportHistoric = () => setImportHistoric(!importHistoric);
  const [deleteFertilization] = useDeleteFertilization();
  const data = fieldbook.fertilizations;

  const handleEdit = (record) => {
    setEditableData(record);
    setModalVisible(true);
  };

  const handleDelete = (id) => {
    deleteFertilization({
      variables: { id },
      refetchQueries: [
        {
          query: getFieldbookQuery,
          variables: { id: fieldbook.id },
        },
      ],
    });
  };
  const columns = [
    {
      dataIndex: "date",
      title: "Data",
      render: formatDate,
    },
    {
      dataIndex: "fertilizer",
      title: "Fertilizante",
      render: (value) => value?.name,
    },
    {
      dataIndex: "provider",
      title: "Fornecedor",
      render: (value) => value?.name,
    },
    {
      dataIndex: "dose",
      title: "Dose",
      render: (value, data) => `${value} ${data?.unity?.name}`,
    },
    {
      dataIndex: "applicationType",
      title: "Tipo de Aplicação",
      render: (value) => value?.name,
    },
    {
      align: "center",
      render: (_, record) =>
        !fieldbook.isArchived && (
          <>
            <EditOutlined
              style={{ marginRight: 10 }}
              onClick={() => handleEdit(record)}
            />

            <Popconfirm
              title="Tem a certeza que quer eliminar esta fertilização?"
              onConfirm={() => handleDelete(record.id)}
              okText="Sim"
              cancelText="Não"
            >
              <DeleteOutlined />
            </Popconfirm>
          </>
        ),
    },
  ];

  useEffect(() => {
    if (!modalVisible) {
      setEditableData();
    }
  }, [modalVisible]);

  return (
    <>
      {!fieldbook.isArchived && (
        <>
          <Modal
            title="Histórico de Fertilizações"
            width="90%"
            footer={null}
            onCancel={toggleImportHistoric}
            visible={importHistoric}
          >
            <FieldbookFertilizationsHistoric
              fieldbookId={fieldbook.id}
              afterSubmit={toggleImportHistoric}
              producersIds={fieldbook?.producers?.map(
                ({ producer }) => producer.id
              )}
            />
          </Modal>

          <Modal
            title={`${editableData ? "Editar" : "Criar"} fertilização`}
            visible={modalVisible}
            onCancel={() => toggleModal()}
            footer={null}
            width={900}
            centered
          >
            <FieldbookFertilizationForm
              data={fieldbook}
              afterSubmit={() => {
                toggleModal();
                setEditableData();
              }}
              editableData={editableData}
            />
          </Modal>

          <Row style={{ marginTop: 30 }}>
            <Col md={24} style={{ textAlign: "right" }}>
              <Button
                icon={<RedoOutlined />}
                onClick={toggleImportHistoric}
                style={{ marginRight: 10 }}
              >
                Importar Histórico
              </Button>

              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() => toggleModal()}
              >
                Fertilização
              </Button>
            </Col>
          </Row>
        </>
      )}

      <br />

      <Skeleton loading={!data} active>
        {data && (
          <Table
            columns={columns}
            dataSource={data}
            rowKey={({ id }) => id}
            rowClassName={() => "editable-row"}
            pagination={false}
            {...tableProps}
          />
        )}
      </Skeleton>
    </>
  );
}

FieldbookFertilizations.propTypes = {
  fieldbook: shape({}).isRequired,
};

export default FieldbookFertilizations;
