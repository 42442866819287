import React from 'react'
import { Form, Input, Button, Row, Col } from 'antd'
import { func } from 'prop-types'

import { useCreateFertilizer } from '@graphql/mutations'

function ManageFertilizerForm({ afterSubmit }) {
  const [form] = Form.useForm()
  const [createFertilizer, { loading }] = useCreateFertilizer()

  const onFinish = ({ name, n, p, k }) => {
    createFertilizer({
      variables: {
        data: {
          name,
          n: parseInt(n, 0),
          p: parseInt(p, 0),
          k: parseInt(k, 0),
        },
      },
      refetchQueries: ['GetFertilizersQuery'],
    }).then(() => afterSubmit(), form.resetFields())
  }

  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: `Campo obrigatório`,
          },
        ]}
        hasFeedback
      >
        <Input placeholder="Nome do fertilizante" size="large" autoFocus />
      </Form.Item>

      <Row gutter={16}>
        <Col span={8}>
          <Form.Item
            name="n"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Input placeholder="N" size="large" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="p"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Input placeholder="P" size="large" />
          </Form.Item>
        </Col>

        <Col span={8}>
          <Form.Item
            name="k"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Input placeholder="K" size="large" />
          </Form.Item>
        </Col>
      </Row>

      <Button
        type="primary"
        htmlType="submit"
        size="large"
        disabled={loading}
        block
      >
        Criar fertilizante
      </Button>
    </Form>
  )
}

ManageFertilizerForm.propTypes = {
  afterSubmit: func,
}

ManageFertilizerForm.defaultProps = {
  afterSubmit: null,
}

export default ManageFertilizerForm
