import { gql } from "apollo-boost";

import useMutation, { mutationTypes } from "@utils/graphqlUtils";

const MUTATION = gql`
  mutation ($id: String!) {
    deleteOneWeeklyWateringLog(where: { id: $id }) {
      id
    }
  }
`;

export default () => useMutation(MUTATION, `Registo Semanal Rega eliminado`, mutationTypes.DELETE);
