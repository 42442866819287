import { gql } from 'apollo-boost'

import useMutation, { mutationTypes } from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation(
    $data: ExpertCreateInput!
  ) {
    createOneExpert(data: $data) {
      name
    }
  }
`

export default () => (
  useMutation(MUTATION, `Técnico criado`, mutationTypes.CREATE)
)
