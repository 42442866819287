import { gql } from 'apollo-boost'

import useMutation, { mutationTypes } from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation(
    $id: String!
    $data: PortionUpdateInput!
  ) {
    updateOnePortion(
      where: { id: $id }
      data: $data
    ) {
      parcels {
        id
        number
        area
        history(
          take: 3,
          orderBy: {
            year: desc
          }
        ) {
          id
          year
          culture {
            id
            name
          }
        }
      }
    }
  }
`

export default () => (
  useMutation(MUTATION, `Parcelário criado`, mutationTypes.CREATE)
)
