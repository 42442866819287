import React from 'react'
import { useQuery } from '@apollo/react-hooks'
import { Card } from 'antd'
import { Route, useParams } from 'react-router-dom'

import Loading from '@packages/Loading'
import ResultError from '@packages/ResultError'
import { getArchivedFieldbookQuery } from '@graphql/queries'
import { ARCHIVED_FIELDBOOK_PATH } from '@constants/RoutesConstants'

import FieldbookHeader from '../FieldbookHeader'
import FieldbookMenu from '../FieldbookMenu'
import FieldbookInstallations from '../FieldbookInstallations'
import FieldbookPesticides from '../FieldbookPesticides'
import FieldbookFertilizations from '../FieldbookFertilizations'
import FieldbookWaterings from '../FieldbookWaterings'
import FieldbookDetails from '../FieldbookDetails'

function FieldbookSetupArchived() {
  const { id, version } = useParams()
  const queryVars = { variables: { fieldbook: id, version: parseInt(version, 0) } }
  const { loading, error, data } = useQuery(getArchivedFieldbookQuery, { ...queryVars })

  if (loading) return <Loading />
  if (error) return <ResultError />

  const fieldbook = data.archivedFieldbooks[0].data

  return (
    !loading && !error && (
      <>
        <FieldbookHeader fieldbook={fieldbook} />
        <FieldbookDetails fieldbook={fieldbook} />

        <br />
        <br />

        <Card>
          <FieldbookMenu id={id} version={parseInt(version, 0)} isArchived />

          <Route
            path={`${ARCHIVED_FIELDBOOK_PATH}/:id/:version/installations`}
            render={() => <FieldbookInstallations fieldbook={fieldbook} />}
          />

          <Route
            path={`${ARCHIVED_FIELDBOOK_PATH}/:id/:version/pesticides`}
            render={() => <FieldbookPesticides fieldbook={fieldbook} />}
          />

          <Route
            path={`${ARCHIVED_FIELDBOOK_PATH}/:id/:version/fertilizations`}
            render={() => <FieldbookFertilizations fieldbook={fieldbook} />}
          />
          <Route
            path={`${ARCHIVED_FIELDBOOK_PATH}/:id/:version/waterings`}
            render={() => <FieldbookWaterings fieldbook={fieldbook} />}
          />
        </Card>
      </>
    )
  )
}

export default FieldbookSetupArchived
