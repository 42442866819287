import React from 'react'
import { Form, Input, Button } from 'antd'
import { func } from 'prop-types'

import { useCreateCulture } from '@graphql/mutations'
import { getCulturesQuery } from '@graphql/queries'

function ManageCultureForm({ afterSubmit }) {
  const [form] = Form.useForm()
  const [createCulture, { loading }] = useCreateCulture()

  const onFinish = data => {
    createCulture({
      variables: { data },
      refetchQueries: [
        {
          query: getCulturesQuery,
        },
      ],
    }).then(() => afterSubmit(), form.resetFields())
  }

  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: `Campo obrigatório`,
          },
        ]}
        hasFeedback
      >
        <Input placeholder="Nome da cultura" size="large" autoFocus />
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        size="large"
        disabled={loading}
        block
      >
        Criar cultura
      </Button>
    </Form>
  )
}

ManageCultureForm.propTypes = {
  afterSubmit: func,
}

ManageCultureForm.defaultProps = {
  afterSubmit: null,
}

export default ManageCultureForm
