import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Form,
  Button,
  DatePicker,
  Input,
} from "antd";
import { arrayOf, shape, string, number, func } from "prop-types";
import moment from "moment";

import {
  getFieldbookQuery,
} from "@graphql/queries";
import { useCreateVisitReport, useUpdateVisitReport } from "@graphql/mutations";

function Intform({ data, afterSubmit, editableData }) {
  const [form] = Form.useForm();
  const [createVisitReport, { loading }] = useCreateVisitReport();
  const [updateVisitReport, { loading: updateLoading }] = useUpdateVisitReport();
  const [editMode, setEditMode] = useState(false);
  const { id } = data;

  const onFinish = fields => {
    const { date, intervention, recommendedActiveSubstance } = fields;
    const mutation = editMode ? updateVisitReport : createVisitReport;
    const refetchQueries = [
      {
        query: getFieldbookQuery,
        variables: { id },
      },
    ].filter(Boolean);

    mutation({
      variables: {
        ...(editMode && editableData && { id: editableData.id }),
        data: {
          fieldbook: {
            connect: { id },
          },
          date: editMode ? { set: date } : date,
          intervention: editMode ? { set: intervention } : intervention,
          recommendedActiveSubstance: editMode ? { set: recommendedActiveSubstance } : recommendedActiveSubstance,
        },
      },
      refetchQueries,
    }).then(() => {
      afterSubmit();
      form.resetFields();
    });
  };

  useEffect(() => {
    setEditMode(false);

    if (editableData) {
      const { date, intervention, recommendedActiveSubstance, ...rest } = editableData;

      form.setFieldsValue({
        date: moment(date),
        intervention,
        recommendedActiveSubstance,
        ...rest,
      });

      setEditMode(true);
    }
  }, [editableData, form]);

  return (
    <>
      <Form onFinish={onFinish} form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="date"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <DatePicker
                placeholder="Data"
                size="large"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="intervention"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <Input
                placeholder="Intervenção / Doença / Praga / Infestante detectada acima do NEA"
                size="large"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="recommendedActiveSubstance"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <Input
                placeholder=" Substância Ativa Recomendada"
                size="large"
                style={{ width: "100%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ textAlign: "right" }}>
          <Col span={24}>
            <Button
              htmlType="submit"
              size="large"
              type="primary"
              disabled={loading || updateLoading}
            >
              {editMode ? "Actualizar" : "Criar"} relatório de visita
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

Intform.propTypes = {
  data: shape({
    campaign: shape({
      culture: shape({
        varieties: arrayOf(
          shape({
            id: string,
            name: string,
            cicle: number,
          }),
        ),
      }),
    }),
  }).isRequired,
  editableData: shape({}),
  afterSubmit: func.isRequired,
};

Intform.defaultProps = {
  editableData: null,
};

export default Intform;
