import { gql } from 'apollo-boost'

import useMutation, { mutationTypes } from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation($id: String!) {
    deleteOneCulture(
      where: { id: $id }
    ) {
      id
    }
  }
`

export default () => (
  useMutation(MUTATION, `Cultura eliminada`, mutationTypes.DELETE)
)
