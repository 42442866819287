import React from "react";
import { Form, Input, Button } from "antd";
import { func } from "prop-types";

import { useCreatePortion } from "@graphql/mutations";
import { getPortionsQuery } from "@graphql/queries";

function ManagePortionForm({ afterSubmit }) {
  const [form] = Form.useForm();
  const [createPortion, { loading }] = useCreatePortion();

  const onFinish = (data) => {
    createPortion({
      variables: { data },
      refetchQueries: [
        {
          query: getPortionsQuery,
        },
      ],
    }).then(
      ({
        data: {
          createOnePortion: { id },
        },
      }) => afterSubmit(id),
      form.resetFields()
    );
  };

  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: `Campo obrigatório`,
          },
        ]}
        hasFeedback
      >
        <Input placeholder="Nome da parcela" size="large" autoFocus />
      </Form.Item>

      <Form.Item
        name="location"
        rules={[
          {
            required: true,
            message: `Campo obrigatório`,
          },
        ]}
        hasFeedback
      >
        <Input placeholder="Localização da parcela" size="large" />
      </Form.Item>

      <Form.Item
        name="identification"
        rules={[
          {
            required: true,
            message: `Campo obrigatório`,
          },
        ]}
        hasFeedback
      >
        <Input placeholder="Identificação" size="large" />
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        size="large"
        disabled={loading}
        block
      >
        Criar parcela
      </Button>
    </Form>
  );
}

ManagePortionForm.propTypes = {
  afterSubmit: func,
};

ManagePortionForm.defaultProps = {
  afterSubmit: null,
};

export default ManagePortionForm;
