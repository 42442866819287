import { gql } from 'apollo-boost'

import useMutation, { mutationTypes } from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation(
    $data: PortionCreateInput!
  ) {
    createOnePortion(
      data: $data
    ) {
      id
      name
    }
  }
`

export default () => (
  useMutation(MUTATION, `Parcela criada`, mutationTypes.CREATE)
)
