import React, { useState } from 'react'
import { Modal, Result, Button } from 'antd'
import { bool, shape, func } from 'prop-types'

import Loading from '@packages/Loading'
import { useCreateArchiveFieldbook, useUpdateFieldbook } from '@graphql/mutations'
import { getFieldbookQuery } from '@graphql/queries'

const messages = {
  SUCCESS: 'Caderno de campo arquivado com sucesso',
  ARE_SURE: 'Tem a certeza que quer arquivar este caderno de campo?',
}

function FieldbookArchive({ fieldbook, visible, toggleModal }) {
  const [archiveFieldbook, { loading }] = useCreateArchiveFieldbook()
  const [updateFieldbook] = useUpdateFieldbook()
  const [archived, setArchived] = useState(false)

  const archive = () => {
    setArchived(false)

    const version = (fieldbook.currentVersion || 0) + 1

    archiveFieldbook({
      variables: {
        data: {
          fieldbook: { connect: { id: fieldbook.id } },
          data: {
            ...fieldbook,
            isArchived: true,
          },
          version,
        },
      },
    }).then(() => {
      setArchived(true)

      updateFieldbook({
        variables: {
          id: fieldbook.id,
          data: {
            isArchived: { set: true },
            currentVersion: { set: version },
          },
        },
        refetchQueries: [
          {
            query: getFieldbookQuery,
            variables: {
              id: fieldbook.id,
            },
          },
        ],
      }).then(() => {
        toggleModal()
        setArchived(false)
      })
    })
  }

  return (
    <Modal
      title="Arquivar caderno de campo"
      onCancel={() => toggleModal()}
      footer={null}
      visible={visible}
      width={800}
    >
      {loading ? (
        <div style={{ textAlign: 'center', margin: '80px 0' }}>
          <Loading />
          <h3>O caderno de campo está a ser arquivado, aguarde ...</h3>
        </div>
      ) : (
        <Result
          status={archived ? 'success' : 'warning'}
          title={messages[archived ? 'SUCCESS' : 'ARE_SURE']}
          extra={(
            !archived && (
              <Button onClick={archive} type="danger" size="large" key="archive">
                Arquivar
              </Button>
            )
          )}
        />
      )}
      {/* <pre>
        <code>
          {JSON.stringify(fieldbook, null, 2)}
        </code>
      </pre> */}
    </Modal>
  )
}

FieldbookArchive.propTypes = {
  visible: bool,
  fieldbook: shape({}).isRequired,
  toggleModal: func.isRequired,
}

FieldbookArchive.defaultProps = {
  visible: false,
}

export default FieldbookArchive
