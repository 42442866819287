import * as routes from "@constants/RoutesConstants";

import ManageCulture from "../ManageCulture";
import ManageCampaign from "../ManageCampaign";
import ManageProducer from "../ManageProducer";
import ManageProvider from "../ManageProvider";
import ManagePortion from "../ManagePortion";
import ManageFieldbook from "../ManageFieldbook";
import ManageArchivedFieldbook from "../ManageFieldbook/FieldbookSetupArchived";
import ManageFertilizer from "../ManageFertilizer";
import ManageProduct from "../ManageProduct";
import ManageManufacturer from "../ManageManufacturer";
import ManageExpert from "../ManageExpert";
import ManageOperator from "../ManageOperator";
import ManageEquipment from "../ManageEquipment";
import ManageApplicationType from "../ManageApplicationType";
import ManagePhenologicalState from "../ManagePhenologicalState";
import ManageActiveSubstanceFamily from "../ManageActiveSubstanceFamily";
import ManageIrrigationType from "../ManageIrrigationType";
import ManagePumpingSystem from "../ManagePumpingSystem";
import ManageGoal from "../ManageGoal";
import Export from "../Export";

const Routes = [
  {
    path: routes.CAMPAIGNS_PATH,
    component: ManageCampaign.Table,
    exact: true,
  },
  {
    path: `${routes.CAMPAIGNS_PATH}/:id/details`,
    component: ManageCampaign.Details,
    exact: true,
  },
  {
    path: routes.CULTURES_PATH,
    component: ManageCulture.Table,
    exact: true,
  },
  {
    path: `${routes.CULTURES_PATH}/:id/details`,
    component: ManageCulture.Details,
    exact: true,
  },
  {
    path: routes.PRODUCERS_PATH,
    component: ManageProducer.Table,
    exact: true,
  },
  {
    path: routes.PROVIDERS_PATH,
    component: ManageProvider.Table,
    exact: true,
  },
  {
    path: routes.MANUFACTURERS_PATH,
    component: ManageManufacturer.Table,
    exact: true,
  },
  {
    path: routes.PORTIONS_PATH,
    component: ManagePortion.Table,
    exact: true,
  },
  {
    path: `${routes.PORTIONS_PATH}/:id/details`,
    component: ManagePortion.Details,
    exact: true,
  },
  {
    path: `${routes.FIELDBOOKS_PATH}/:id`,
    component: ManageFieldbook.Setup,
    exact: false,
  },
  {
    path: `${routes.ARCHIVED_FIELDBOOK_PATH}/:id/:version`,
    component: ManageArchivedFieldbook,
    exact: false,
  },
  {
    path: routes.FERTILIZERS_PATH,
    component: ManageFertilizer.Table,
    exact: true,
  },
  {
    path: routes.PRODUCTS_PATH,
    component: ManageProduct.Table,
    exact: true,
  },
  {
    path: routes.EXPERTS_PATH,
    component: ManageExpert.Table,
    exact: true,
  },
  {
    path: routes.OPERATORS_PATH,
    component: ManageOperator.Table,
    exact: true,
  },
  {
    path: routes.EQUIPMENTS_PATH,
    component: ManageEquipment.Table,
    exact: true,
  },
  {
    path: routes.APPLICATION_TYPES_PATH,
    component: ManageApplicationType.Table,
    exact: true,
  },
  {
    path: routes.PHENOLOGICAL_STATES_PATH,
    component: ManagePhenologicalState.Table,
    exact: true,
  },
  {
    path: routes.IRRIGATION_TYPES_PATH,
    component: ManageIrrigationType.Table,
    exact: true,
  },
  {
    path: routes.PUMPING_SYSTEMS_PATH,
    component: ManagePumpingSystem.Table,
    exact: true,
  },
  {
    path: routes.ACTIVE_SUBSTANCE_FAMILIES_PATH,
    component: ManageActiveSubstanceFamily.Table,
    exact: true,
  },
  {
    path: routes.GOALS_PATH,
    component: ManageGoal.Table,
    exact: true,
  },
  {
    path: routes.EXPORT_PATH,
    component: Export,
    exact: true,
  },
];

export default Routes;
