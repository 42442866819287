export const DASHBOARD_ICON = `https://image.flaticon.com/icons/svg/1340/1340311.svg`
export const CAMPAIGNS_ICON = `/svgs/campaigns.svg`
export const CAMPAIGN_DETAILS_ICON = `/svgs/details.png`
export const PRODUCERS_ICON = `/svgs/producers.png`
export const PROVIDERS_ICON = `/svgs/providers.png`
export const PORTIONS_ICON = `/svgs/portions.png`
export const CULTURES_ICON = `/svgs/cultures.png`
export const PRODUCTS_ICON = `/svgs/products.png`
export const FIELDBOOKS_ICON = `/svgs/fieldbooks.png`
export const FERTILIZERS_ICON = `/svgs/fertilizers.png`
export const MANUFACTURERS_ICON = `/svgs/manufacturers.png`
export const EXPERTS_ICON = `/svgs/experts.png`
export const OPERATORS_ICON = `/svgs/operators.png`
export const EQUIPMENTS_ICON = `/svgs/equipments.png`
export const APPLICATION_TYPES_ICON = `/svgs/equipment_types.png`
export const IRRIGATION_TYPES_ICON = `/svgs/irrigation_types.png`
export const PHENOLOGICAL_STATES_ICON = `/svgs/phenological_states.png`
export const ACTIVE_SUBSTANCE_FAMILIES_ICON = `/svgs/active_substances.png`
export const PUMPING_SYSTEMS_ICON = `/svgs/pumping_systems.png`
export const GOALS_ICON = `/svgs/goals.png`
export const EXPORT_ICON = `/svgs/export.png`
