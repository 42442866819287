import { gql } from 'apollo-boost'

import useMutation from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation(
    $data: ParcelHistoryCreateInput!
  ) {
    createOneParcelHistory(data: $data) {
      id
      year
      culture {
        id
        name
      }
    }
  }
`

export default () => (
  useMutation(MUTATION)
)
