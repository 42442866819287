import React, { useState, useEffect } from "react";
import { shape } from "prop-types";
import {
  Row,
  Col,
  Button,
  Modal,
  Table,
  Skeleton,
  Popconfirm,
  Typography,
} from "antd";
import {
  PlusCircleOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";

import { getFieldbookQuery } from "@graphql/queries";
import { useDeleteInstallation } from "@graphql/mutations";
import { formatDate } from "@utils/dateUtils";

import FieldbookInstallationForm from "./FieldbookInstallationForm";

const tableProps = {
  size: "middle",
  pagination: false,
};
const { Text } = Typography;

function FieldbookInstallations({ fieldbook }) {
  const [modalVisible, setModalVisible] = useState(false);
  const toggleModal = () => setModalVisible(!modalVisible);
  const [editableData, setEditableData] = useState();
  const [deleteInstallation] = useDeleteInstallation();
  const data = fieldbook.installations;

  const handleEdit = (record) => {
    setEditableData(record);
    setModalVisible(true);
  };

  const handleDelete = (id) => {
    deleteInstallation({
      variables: { id },
      refetchQueries: [
        {
          query: getFieldbookQuery,
          variables: { id: fieldbook.id },
        },
      ],
    });
  };
  const columns = [
    {
      dataIndex: "date",
      title: "Data",
      render: formatDate,
      fixed: "left",
    },
    {
      dataIndex: "variety",
      title: "Variedade",
      render: (value) => <Text code>{value.name}</Text>,
      fixed: "left",
    },
    {
      dataIndex: "area",
      title: "Área (ha)",
    },
    {
      dataIndex: "plantingWeeks",
      title: "Sem. Plantação",
    },
    {
      dataIndex: "density",
      title: "Densidade (n plantas / ha)",
    },
    {
      dataIndex: "seedLot",
      title: "Lote Sem.",
    },
    {
      dataIndex: "provider",
      title: "Fornecedor",
      render: (value) => value.name,
    },
    {
      dataIndex: "adjacentCrops",
      title: "Culturas Adjacentes",
    },
    {
      dataIndex: "harvestDate",
      title: "Data Colheita",
      render: formatDate,
    },
    {
      align: "center",
      render: (_, record) =>
        !fieldbook.isArchived && (
          <>
            <EditOutlined
              style={{ marginRight: 10 }}
              onClick={() => handleEdit(record)}
            />

            <Popconfirm
              title="Tem a certeza que quer eliminar esta instalação?"
              onConfirm={() => handleDelete(record.id)}
              okText="Sim"
              cancelText="Não"
            >
              <DeleteOutlined />
            </Popconfirm>
          </>
        ),
    },
  ];

  useEffect(() => {
    if (!modalVisible) {
      setEditableData();
    }
  }, [modalVisible]);

  return (
    <>
      {!fieldbook.isArchived && (
        <>
          <Modal
            title={`${editableData ? "Editar" : "Criar"} instalação`}
            visible={modalVisible}
            onCancel={() => toggleModal()}
            footer={null}
            width={900}
            centered
          >
            <FieldbookInstallationForm
              data={fieldbook}
              afterSubmit={() => {
                toggleModal();
                setEditableData();
              }}
              editableData={editableData}
            />
          </Modal>

          <Row style={{ marginTop: 30 }}>
            <Col md={24} style={{ textAlign: "right" }}>
              <Button
                type="primary"
                icon={<PlusCircleOutlined />}
                onClick={() => toggleModal()}
              >
                Instalação
              </Button>
            </Col>
          </Row>
        </>
      )}

      <br />

      <Skeleton loading={!data} active>
        {data && (
          <Table
            columns={columns}
            dataSource={data}
            rowKey={({ id }) => id}
            rowClassName={() => "editable-row"}
            pagination={false}
            {...tableProps}
          />
        )}
      </Skeleton>
    </>
  );
}

FieldbookInstallations.propTypes = {
  fieldbook: shape({}).isRequired,
};

export default FieldbookInstallations;
