import { gql } from "apollo-boost";

import useMutation, { mutationTypes } from "@utils/graphqlUtils";

const MUTATION = gql`
  mutation(
    $data: FertilizationPlanCreateInput!
  ) {
    createOneFertilizationPlan(data: $data) {
      id
    }
  }
`;

export default () => (
  useMutation(MUTATION, `Plano de fertilização criado`, mutationTypes.CREATE)
);
