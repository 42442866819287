import { gql } from 'apollo-boost'

import useMutation, { mutationTypes } from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation(
    $data: ActiveSubstanceFamilyCreateInput!
  ) {
    createOneActiveSubstanceFamily(data: $data) {
      name
    }
  }
`

export default () => (
  useMutation(MUTATION, `Familía criada`, mutationTypes.CREATE)
)
