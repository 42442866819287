import React, { useState } from 'react'
import { Form, Input, Button, Select, Modal, Row, Col } from 'antd'
import { func } from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { PlusCircleOutlined } from '@ant-design/icons'

import { getCulturesQuery, getCampaignsQuery } from '@graphql/queries'
import { useCreateCampaign } from '@graphql/mutations'

import ManageCultureForm from '../../ManageCulture/ManageCultureForm'

const { Option } = Select

function ManageCampaignForm({ afterSubmit }) {
  const [form] = Form.useForm()
  const [createCampaign, { loading }] = useCreateCampaign()
  const { loading: loadingCultures, data: culturesData } = useQuery(getCulturesQuery)
  const [newCulture, setNewCulture] = useState(false)

  const onFinish = ({ year, cultureName, culture }) => {
    const cultureData = cultureName ? { create: { name: cultureName } } : { connect: { id: culture } }

    createCampaign({
      variables: {
        data: {
          year,
          culture: { ...cultureData },
        },
      },
      refetchQueries: [
        {
          query: getCampaignsQuery,
        },
        {
          query: getCulturesQuery,
        },
      ],
    }).then(() => afterSubmit(), form.resetFields())
  }

  return (
    <Form onFinish={onFinish} form={form}>
      <Row>
        <Col span={24}>
          <Button
            size="small"
            icon={<PlusCircleOutlined />}
            onClick={() => setNewCulture(!newCulture)}
          >
            Cultura
          </Button>
        </Col>
      </Row>

      <Modal
        title="Criar nova cultura"
        visible={newCulture}
        onCancel={() => setNewCulture(!newCulture)}
        footer={null}
      >
        <ManageCultureForm afterSubmit={() => setNewCulture(!newCulture)} />
      </Modal>

      <br />

      <Form.Item
        name="year"
        rules={[
          {
            required: true,
            message: `Campo obrigatório`,
          },
        ]}
        hasFeedback
      >
        <Input placeholder="Ano da campanha" size="large" autoFocus />
      </Form.Item>

      <Form.Item
        name="culture"
        rules={[
          {
            required: true,
            message: `Campo obrigatório`,
          },
        ]}
        hasFeedback
      >
        <Select
          loading={loadingCultures}
          placeholder="Escolha uma cultura"
          size="large"
          optionFilterProp="children"
          showSearch
        >
          {culturesData && culturesData.cultures.map(({ id, name }) => (
            <Option value={id} key={id}>{name}</Option>
          ))}
        </Select>
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        size="large"
        disabled={loading}
        block
      >
        Criar campanha
      </Button>
    </Form>
  )
}

ManageCampaignForm.propTypes = {
  afterSubmit: func,
}

ManageCampaignForm.defaultProps = {
  afterSubmit: null,
}

export default ManageCampaignForm
