import React, { useState } from 'react'
import { Table, Popconfirm, Skeleton, Button, Modal } from 'antd'
import { string } from 'prop-types'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'

import { useColumnFilterProps } from '@utils/columnFilter'
import { useUpdateProduct, useDeleteProduct } from '@graphql/mutations'
import { getProductsByActiveSubstanceQuery, getManufacturersQuery, getUnitiesQuery } from '@graphql/queries'

import ManageManufacturerForm from '../../ManageManufacturer/ManageManufacturerForm'
import EditableCell from '../../EditableCell'

const tableProps = {
  size: 'middle',
  pagination: false,
}

function ManageProductList({ activeSubstanceId }) {
  const [newManufacturer, setNewManufacturer] = useState(false)
  const useFilterField = field => useColumnFilterProps(field)
  const { loading, data } = useQuery(getProductsByActiveSubstanceQuery, {
    variables: {
      activeSubstance: activeSubstanceId,
    },
  })
  const { data: manufacturersData } = useQuery(getManufacturersQuery)
  const { data: unitiesData } = useQuery(getUnitiesQuery)
  const [updateProduct] = useUpdateProduct()
  const [deleteProduct] = useDeleteProduct()

  const handleSave = data => {
    const {
      id,
      name,
      apv,
      dose,
      unity,
      content,
      manufacturer,
      formulation,
    } = data

    updateProduct({
      variables: {
        id,
        data: {
          name: { set: name },
          apv: { set: Number(apv) },
          dose: { set: Number(dose) },
          unity: {
            connect: { id: unity.id || unity },
          },
          content: { set: Number(content) },
          manufacturer: {
            connect: { id: manufacturer.id || manufacturer },
          },
          formulation: { set: formulation },
        },
      },
      refetchQueries: [
        {
          query: getProductsByActiveSubstanceQuery,
          variables: { activeSubstance: activeSubstanceId },
        },
      ],
    })
  }

  const handleDelete = id => {
    deleteProduct({
      variables: { id },
      refetchQueries: [
        {
          query: getProductsByActiveSubstanceQuery,
          variables: { activeSubstance: activeSubstanceId },
        },
      ],
    })
  }
  const columns = [
    {
      dataIndex: 'name',
      title: 'Nome',
      editable: true,
      ...useFilterField('name'),
    },
    {
      dataIndex: 'apv',
      title: 'APV',
      editable: true,
      ...useFilterField('apv'),
    },
    {
      dataIndex: 'dose',
      title: 'Dose',
      editable: true,
      ...useFilterField('dose'),
    },
    {
      dataIndex: 'unity',
      title: 'Unidade',
      editable: true,
      inputType: `select`,
      inputData: unitiesData?.unities,
      render: value => value?.name,
    },
    {
      dataIndex: 'content',
      title: 'Teor',
      editable: true,
      ...useFilterField('content'),
    },
    {
      dataIndex: `manufacturer`,
      title: 'Fabricante',
      editable: true,
      inputType: `select`,
      inputData: manufacturersData?.manufacturers,
      render: value => value?.name,
    },
    {
      dataIndex: `formulation`,
      title: 'Formulação',
      editable: true,
    },
    {
      align: 'center',
      render: (value, { id, name }) => (
        <Popconfirm
          title={`Tem a certeza que quer eliminar ${name} ?`}
          onConfirm={() => handleDelete(id)}
          okText="Sim"
          cancelText="Não"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ]
  const mappedColumns = columns.map(col => {
    if (!col.editable) return col

    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        inputType: col.inputType,
        inputData: col.inputData,
        title: col.title,
        handleSave,
      }),
    }
  })

  return (
    <Skeleton loading={loading} active>
      <Button
        icon={<PlusCircleOutlined />}
        onClick={() => setNewManufacturer(!newManufacturer)}
        style={{ marginBottom: 15 }}
      >
        Fabricante
      </Button>

      {newManufacturer && (
        <Modal
          title="Novo fabricante"
          onCancel={() => setNewManufacturer(false)}
          footer={null}
          visible
        >
          <ManageManufacturerForm afterSubmit={() => setNewManufacturer(false)} />
        </Modal>
      )}

      <Table
        components={EditableCell.Components}
        columns={mappedColumns}
        dataSource={data?.products}
        rowKey={({ id }) => id}
        rowClassName={() => 'editable-row'}
        {...tableProps}
      />
    </Skeleton>
  )
}

ManageProductList.propTypes = {
  activeSubstanceId: string.isRequired,
}

export default ManageProductList
