import React, { useState, useEffect } from "react";
import { Row, Col, Form, Button, InputNumber, Input } from "antd";
import { arrayOf, shape, string, number, func } from "prop-types";

import { getFieldbookQuery } from "@graphql/queries";
import { useCreateWeeklyWateringLog, useUpdateWeeklyWateringLog } from "@graphql/mutations";

function FieldbookWeeklyWateringLogForm({ data, afterSubmit, editableData }) {
  const [form] = Form.useForm();
  const [create, { loading }] = useCreateWeeklyWateringLog();
  const [update, { loading: updateLoading }] = useUpdateWeeklyWateringLog();
  const [editMode, setEditMode] = useState(false);
  const { id } = data;

  const onFinish = (fields) => {
    const { week, mm, hours } = fields;
    const mutation = editMode ? update : create;
    const refetchQueries = [
      {
        query: getFieldbookQuery,
        variables: { id },
      },
    ].filter(Boolean);

    mutation({
      variables: {
        ...(editMode && editableData && { id: editableData.id }),
        data: {
          fieldbook: {
            connect: { id },
          },
          week: editMode ? { set: week } : week,
          mm: editMode ? { set: Number(mm) } : Number(mm),
          hours: editMode ? { set: Number(hours) } : Number(hours),
        },
      },
      refetchQueries,
    }).then(() => {
      afterSubmit();
      form.resetFields();
    });
  };

  useEffect(() => {
    setEditMode(false);

    if (editableData) {
      const { mm, hours, ...rest } = editableData;

      form.setFieldsValue({
        mm: parseFloat(mm),
        hours: parseFloat(hours),
        ...rest,
      });

      setEditMode(true);
    }
  }, [editableData, form]);

  return (
    <>
      <Form onFinish={onFinish} form={form}>
        <Row gutter={16}>
          <Col span={8}>
            <Form.Item
              name="week"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Semana" size="large" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="mm"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <InputNumber placeholder="Registo de Rega (mm)" size="large" style={{ width: "100%" }} />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="hours"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <InputNumber placeholder="Registo de Rega (horas)" size="large" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>

        <Row style={{ textAlign: "right" }}>
          <Col span={24}>
            <Button htmlType="submit" size="large" type="primary" disabled={loading || updateLoading}>
              {editMode ? "Actualizar" : "Criar"} registo semanal rega
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

FieldbookWeeklyWateringLogForm.propTypes = {
  data: shape({
    campaign: shape({
      culture: shape({
        varieties: arrayOf(
          shape({
            id: string,
            name: string,
            cicle: number,
          }),
        ),
      }),
    }),
  }).isRequired,
  editableData: shape({}),
  afterSubmit: func.isRequired,
};

FieldbookWeeklyWateringLogForm.defaultProps = {
  editableData: null,
};

export default FieldbookWeeklyWateringLogForm;
