import React from 'react'
import { Result } from 'antd'

function ResultError() {
  return (
    <Result
      status="500"
      title="Ocorreu um erro"
      subTitle={(
        <div
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: (
              `A equipa já foi notificada, caso persista entre em <a href="mailto:telmogoncalves@me.com">contacto</a>.`
            ),
          }}
        />
      )}
    />
  )
}

export default ResultError
