import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  Form,
  Button,
  DatePicker,
  Input,
  InputNumber,
  Alert,
  Modal,
  Skeleton,
} from "antd";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";
import { arrayOf, shape, string, number, func } from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";

import { getProvidersQuery, getFieldbookQuery } from "@graphql/queries";
import {
  useCreateInstallation,
  useUpdateInstallation,
} from "@graphql/mutations";

import ManageProviderForm from "../../../ManageProvider/ManageProviderForm";

const { Option } = Select;

function FieldbookInstallationForm({ data, afterSubmit, editableData }) {
  const [form] = Form.useForm();
  const [createInstallation, { loading }] = useCreateInstallation();
  const [updateInstallation, { loading: updateLoading }] = useUpdateInstallation();
  const [newVariety, setNewVariety] = useState(false);
  const [newProvider, setNewProvider] = useState(false);
  const [autoHarvestDate, setAutoHarvestDate] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { loading: loadingProviders, data: providersData } = useQuery(getProvidersQuery);
  const { id, campaign } = data;
  const {
    culture: { id: cultureId, varieties },
  } = campaign;

  const onFinish = fields => {
    const {
      variety,
      varietyName,
      varietyCicle,
      date,
      area,
      plantingWeeks,
      density,
      seedLot,
      provider,
      harvestDate,
      adjacentCrops,
    } = fields;
    const mutation = editMode ? updateInstallation : createInstallation;
    const refetchQueries = [
      {
        query: getFieldbookQuery,
        variables: { id },
      },
      newVariety ? { query: getFieldbookQuery, variables: { id } } : null,
    ].filter(Boolean);

    mutation({
      variables: {
        ...(editMode && editableData && { id: editableData.id }),
        data: {
          fieldbook: {
            connect: { id },
          },
          variety: {
            ...(!newVariety && { connect: { id: variety } }),
            ...(newVariety && {
              create: {
                culture: { connect: { id: cultureId } },
                name: varietyName,
                cicle: varietyCicle,
              },
            }),
          },
          provider: {
            connect: { id: provider },
          },
          date: editMode ? { set: date } : date,
          area: editMode ? { set: Number(area) } : Number(area),
          plantingWeeks: editMode ? { set: plantingWeeks } : plantingWeeks,
          density: editMode ? { set: Number(density) } : Number(density),
          seedLot: editMode ? { set: seedLot } : seedLot,
          harvestDate: editMode ? { set: harvestDate } : harvestDate,
          adjacentCrops: editMode ? { set: adjacentCrops } : adjacentCrops,
        },
      },
      refetchQueries,
    }).then(() => {
      setNewVariety(false);
      setNewProvider(false);
      setAutoHarvestDate(false);
      afterSubmit();
      form.resetFields();
    });
  };

  const calculateHarvestDate = () => {
    const requiredFields = ["harvestDate", "date", "variety", "varietyCicle"];
    const { date, variety, varietyCicle } = form.getFieldsValue(requiredFields);
    const cicleValue = varietyCicle
      || (variety && varieties.find(({ id }) => id === variety).cicle);

    if (date && cicleValue) {
      form.setFieldsValue({
        harvestDate: moment(date).add(cicleValue, "days"),
      });
      setAutoHarvestDate(true);
    }
  };

  useEffect(() => {
    setAutoHarvestDate(false);
    setEditMode(false);
    form.resetFields();

    if (editableData) {
      const { date, variety, provider, harvestDate, ...rest } = editableData;

      form.setFieldsValue({
        date: moment(date),
        variety: variety.id,
        provider: provider.id,
        harvestDate: moment(harvestDate),
        ...rest,
      });

      setEditMode(true);
    }
  }, [editableData, form]);

  return (
    <>
      <Form onFinish={onFinish} form={form}>
        <Skeleton loading={loadingProviders} active>
          <Row>
            <Col span={24}>
              <Button
                icon={
                  newVariety ? <MinusCircleOutlined /> : <PlusCircleOutlined />
                }
                onClick={() => setNewVariety(!newVariety)}
                style={{ marginRight: 10 }}
              >
                Variedade
              </Button>

              <Button
                icon={<PlusCircleOutlined />}
                onClick={() => setNewProvider(!newProvider)}
                style={{ marginRight: 10 }}
              >
                Fornecedor
              </Button>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: 30 }}>
            <Col span={8}>
              {newVariety ? (
                <Row gutter={16}>
                  <Col span={16}>
                    <Form.Item
                      name="varietyName"
                      rules={[
                        {
                          required: true,
                          message: `Campo obrigatório`,
                        },
                      ]}
                      hasFeedback
                    >
                      <Input
                        placeholder="Nome variedade"
                        size="large"
                        style={{ width: "100%" }}
                      />
                    </Form.Item>
                  </Col>

                  <Col span={8}>
                    <Form.Item
                      name="varietyCicle"
                      rules={[
                        {
                          required: true,
                          message: `Campo obrigatório`,
                        },
                      ]}
                      hasFeedback
                    >
                      <InputNumber
                        placeholder="Ciclo"
                        size="large"
                        style={{ width: "100%" }}
                        onChange={calculateHarvestDate}
                      />
                    </Form.Item>
                  </Col>
                </Row>
              ) : (
                <Form.Item
                  name="variety"
                  rules={[
                    {
                      required: true,
                      message: `Campo obrigatório`,
                    },
                  ]}
                  hasFeedback
                >
                  <Select
                    placeholder="Escolha uma variedade"
                    size="large"
                    optionFilterProp="children"
                    showSearch
                    onChange={calculateHarvestDate}
                  >
                    {varieties
                      && varieties.map(({ id, name }) => (
                        <Option value={id} key={id}>
                          {name}
                        </Option>
                      ))}
                  </Select>
                </Form.Item>
              )}
            </Col>

            <Col span={8}>
              <Form.Item
                name="date"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <DatePicker
                  placeholder="Data da instalação"
                  size="large"
                  style={{ width: "100%" }}
                  onChange={calculateHarvestDate}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="area"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <InputNumber
                  placeholder="Área"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="plantingWeeks"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <Input
                  placeholder="Semanas de Plantação"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="density"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <InputNumber
                  placeholder="Densidade"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item name="seedLot">
                <Input
                  placeholder="Lote Sem."
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              {newProvider && (
                <Modal
                  title="Novo fornecedor"
                  onCancel={() => setNewProvider(false)}
                  footer={null}
                  visible
                >
                  <ManageProviderForm
                    afterSubmit={() => setNewProvider(false)}
                  />
                </Modal>
              )}

              <Form.Item
                name="provider"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <Select
                  loading={loadingProviders}
                  placeholder="Escolha um fornecedor"
                  size="large"
                  optionFilterProp="children"
                  showSearch
                >
                  {providersData
                    && providersData.providers.map(({ id, name }) => (
                      <Option value={id} key={id}>
                        {name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="harvestDate"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <DatePicker
                  placeholder="Data da colheita"
                  size="large"
                  style={{ width: "100%" }}
                  onChange={() => setAutoHarvestDate(false)}
                />
              </Form.Item>

              {autoHarvestDate && (
                <Alert
                  message="Data calculada automaticamente"
                  type="info"
                  showIcon
                />
              )}
            </Col>

            <Col span={8}>
              <Form.Item
                name="adjacentCrops"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <Input
                  placeholder="Culturas Adjacentes"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ textAlign: "right" }}>
            <Col span={24}>
              <Button
                htmlType="submit"
                size="large"
                type="primary"
                disabled={loading || updateLoading}
              >
                {editMode ? "Actualizar" : "Criar"} instalação
              </Button>
            </Col>
          </Row>
        </Skeleton>
      </Form>
    </>
  );
}

FieldbookInstallationForm.propTypes = {
  data: shape({
    campaign: shape({
      culture: shape({
        varieties: arrayOf(
          shape({
            id: string,
            name: string,
            cicle: number,
          }),
        ),
      }),
    }),
  }).isRequired,
  editableData: shape({}),
  afterSubmit: func.isRequired,
};

FieldbookInstallationForm.defaultProps = {
  editableData: null,
};

export default FieldbookInstallationForm;
