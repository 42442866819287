import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Skeleton, Divider, Alert, Table, Row, Col, Button } from "antd";
import { arrayOf, string, func } from "prop-types";

import {
  getPesticidesHistoricQuery,
  getFieldbookQuery,
} from "@graphql/queries";
import { useCreatePesticide } from "@graphql/mutations";
import { formatDate } from "@utils/dateUtils";

const tableProps = {
  size: "middle",
  pagination: false,
};

function FieldbookPesticidesHistoric({
  fieldbookId,
  producersIds,
  afterSubmit,
}) {
  const [createPesticide] = useCreatePesticide();
  const mappedIds = producersIds.map((id) => id);
  const [selectedRows, setSelectedRows] = useState([]);
  const queryVars = { variables: { where: mappedIds } };
  const { data, error, loading } = useQuery(getPesticidesHistoricQuery, {
    ...queryVars,
  });
  const rowSelection = {
    onChange: (_, selectedRows) => setSelectedRows(selectedRows),
  };
  const columns = [
    {
      dataIndex: "date",
      title: "Data",
      render: formatDate,
    },
    {
      dataIndex: "area",
      title: "Área (ha)",
    },
    {
      dataIndex: "product",
      title: "Produto",
      render: (value) => value?.name,
    },
    {
      dataIndex: "sauce",
      title: "Calda (lt / ha)",
    },
    {
      dataIndex: "provider",
      title: "Fornecedor",
      render: (value) => value?.name,
    },
    {
      dataIndex: "phenologicalState",
      title: "Estado Fenológico",
      render: (value) => value?.name,
    },
    {
      dataIndex: "safeHarvestDate",
      title: "Data Segura Colheita",
      render: formatDate,
    },
  ];

  const createMultiplePesticides = () => {
    selectedRows.map((fields) => {
      const {
        product,
        date,
        area,
        sauce,
        provider,
        meteorology,
        safeHarvestDate,
        phenologicalState,
      } = fields;
      const refetchQueries = [
        {
          query: getFieldbookQuery,
          variables: { id: fieldbookId },
        },
      ].filter(Boolean);

      return createPesticide({
        variables: {
          data: {
            meteorology,
            fieldbook: {
              connect: { id: fieldbookId },
            },
            product: {
              connect: { id: product.id },
            },
            phenologicalState: {
              connect: { id: phenologicalState.id },
            },
            provider: {
              connect: { id: provider.id },
            },
            date,
            area: parseFloat(area),
            sauce: parseInt(sauce, 0),
            safeHarvestDate,
          },
        },
        refetchQueries,
      }).then(() => afterSubmit());
    });
  };

  if (loading) return <Skeleton />;
  if (error) {
    return (
      <Alert
        message="Algo correu mal"
        description={
          <pre>
            <code>{JSON.stringify(error, null, 2)}</code>
          </pre>
        }
        type="error"
        showIcon
      />
    );
  }

  return (
    <>
      {data?.fieldbooks.map(({ id, portion, pesticides }) => (
        <div key={id}>
          <Divider>Parcela: {portion.name}</Divider>

          <Table
            columns={columns}
            dataSource={pesticides}
            rowKey={({ id }) => id}
            pagination={false}
            rowSelection={{
              ...rowSelection,
            }}
            {...tableProps}
          />
        </div>
      ))}

      {/* <pre>
        <code>
          {JSON.stringify(data, null, 2)}
        </code>
      </pre> */}

      <Row style={{ marginTop: 30, textAlign: "center" }}>
        <Col span={24}>
          <Button
            type="primary"
            disabled={selectedRows.length < 1}
            onClick={createMultiplePesticides}
          >
            {selectedRows.length > 0
              ? `Copiar ${selectedRows.length} fitofármacos`
              : `Selecione fitofármacos`}
          </Button>
        </Col>
      </Row>
    </>
  );
}

FieldbookPesticidesHistoric.propTypes = {
  fieldbookId: string.isRequired,
  producersIds: arrayOf(string).isRequired,
  afterSubmit: func.isRequired,
};

export default FieldbookPesticidesHistoric;
