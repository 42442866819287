import React, { useEffect, useState } from 'react'
import { notification } from 'antd'
import useOnlineStatus from '@rehooks/online-status'

const NOTIFICATION_PLACEMENT = 'bottomRight'

function noNetworkDetected() {
  notification.warning({
    message: 'Sem ligação à Internet',
    description: 'Não existe nenhuma ligação à Internet.',
    placement: NOTIFICATION_PLACEMENT,
    duration: 0,
  })
}

function networkDetected() {
  notification.success({
    message: 'Ligação restaurada',
    description: 'Pode continuar a trabalhar.',
    placement: NOTIFICATION_PLACEMENT,
  })
}

const withNetworkProvider = WrappedComponent => {
  function NetworkProvider(props) {
    const [hasDisconnected, setHasDisconnected] = useState(false)
    const onlineStatus = useOnlineStatus()

    useEffect(() => {
      notification.destroy()

      if (onlineStatus && hasDisconnected) {
        networkDetected()
        setHasDisconnected(false)
      }

      if (!onlineStatus && !hasDisconnected) {
        noNetworkDetected()
        setHasDisconnected(true)
      }
    }, [onlineStatus, hasDisconnected])

    return <WrappedComponent {...props} />
  }

  return NetworkProvider
}

export default withNetworkProvider
