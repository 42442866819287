import React, { useState, useEffect } from 'react'
import { Form, Upload, Result, Button } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/react-hooks'
import { csv } from 'csvtojson'
import { func, string } from 'prop-types'

import { camelize, normalize } from '@utils/textUtils'
import { useCreateProduct, useCreateActiveSubstance } from '@graphql/mutations'
import {
  getGoalsQuery,
  getActiveSubstancesQuery,
  getActiveSubstanceFamiliesQuery,
  getUnitiesQuery,
  getManufacturersQuery,
} from '@graphql/queries'

const options = {
  trim: true,
  ignoreEmpty: true,
  delimiter: ';',
}

function ManageProductCsvUpload({ afterSubmit, importType }) {
  const [data, setData] = useState()
  const [uploadComplete, setUploadComplete] = useState(false)
  const [createProduct, { loading }] = useCreateProduct()
  const [createActiveSubstance] = useCreateActiveSubstance()
  const { data: goalsData } = useQuery(getGoalsQuery)
  const { data: activeSubstanceFamiliesData } = useQuery(getActiveSubstanceFamiliesQuery)
  const { data: unitiesData } = useQuery(getUnitiesQuery)
  const { data: manufacturersData } = useQuery(getManufacturersQuery)
  const { data: activeSubstancesData } = useQuery(getActiveSubstancesQuery)

  const normFile = e => {
    if (Array.isArray(e)) return e

    return e && e.fileList
  }

  useEffect(() => {
    const createAllProducts = async data => {
      if (importType === 'activeSubstance') {
        await data.map(async d => {
          const { finalidade, substActiva, familia, intervalo } = d
          const activeSubstanceFamily = activeSubstanceFamiliesData?.activeSubstanceFamilies.find(
            ({ name }) => name.toLowerCase() === familia.toLowerCase(),
          )
          const goal = goalsData?.goals.find(({ name }) => name.toLowerCase() === finalidade.toLowerCase())

          await createActiveSubstance({
            variables: {
              data: {
                name: substActiva,
                goal: {
                  connect: { id: goal?.id },
                },
                withdrawalPeriod: parseInt(intervalo, 0),
                activeSubstanceFamily: {
                  connect: { id: activeSubstanceFamily?.id },
                },
              },
            },
            refetchQueries: [
              {
                query: getActiveSubstancesQuery,
              },
            ],
          })
        })
      } else {
        data.map(async d => {
          const {
            nomeComercial,
            unidades,
            substActiva,
            dose,
            teorSA,
            fabricante,
            apv,
          } = d
          const manufacturer = manufacturersData?.manufacturers.find(
            ({ name }) => name.toLowerCase() === fabricante.toLowerCase(),
          )
          const unity = unitiesData?.unities.find(({ name }) => name.toLowerCase() === unidades.toLowerCase())
          const substance = activeSubstancesData.activeSubstances?.find(
            ({ name }) => name.toLowerCase() === substActiva.toLowerCase(),
          )

          await createProduct({
            variables: {
              data: {
                name: nomeComercial.toUpperCase(),
                activeSubstance: {
                  connect: { id: substance?.id },
                },
                unity: {
                  connect: { id: unity?.id },
                },
                dose: parseInt(dose, 0),
                content: parseInt(teorSA, 0),
                apv: parseInt(apv, 0),
                manufacturer: {
                  ...manufacturer && {
                    connect: { id: manufacturer?.id },
                  },
                  ...!manufacturer && {
                    create: {
                      name: fabricante,
                    },
                  },
                },
              },
            },
          })
        })
      }

      setUploadComplete(true)
    }

    if (data) createAllProducts(data)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, createProduct])

  const handleUpload = () => {}
  const props = {
    accept: `.txt, .csv, .xlsx`,
    name: `files`,
    beforeUpload: file => {
      const reader = new FileReader()

      reader.onload = e => {
        // const parsed = Papa.parse(e.target.result)

        csv(options)
          .fromString(e.target.result)
          .preFileLine((fileLineString, lineIdx) => {
            if (lineIdx === 0) {
              return normalize(
                camelize(fileLineString.toLowerCase()),
              )
            }

            return fileLineString
          })
          .then(data => setData(data))
      }

      reader.readAsText(file)

      return false
    },
  }

  if (uploadComplete) {
    return (
      <Result
        status="success"
        title="Produtos importados"
        subTitle="Pode fechar esta caixa."
        extra={[
          <Button type="primary" key="close" onClick={() => afterSubmit()}>
            Fechar
          </Button>,
        ]}
      />
    )
  }

  return (
    <Form onFinish={handleUpload}>
      <Form.Item>
        {!loading ? (
          <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
            <Upload.Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Clique ou arraste uma ficheiro para efectuar o upload.
              </p>
            </Upload.Dragger>
          </Form.Item>
        ) : `Loading ...`}
      </Form.Item>
    </Form>
  )
}

ManageProductCsvUpload.propTypes = {
  afterSubmit: func.isRequired,
  importType: string.isRequired,
}

export default ManageProductCsvUpload
