import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Button,
  Table,
  Skeleton,
  Typography,
  Popconfirm,
  Modal,
} from "antd";
import { useQuery } from "@apollo/react-hooks";
import { PlusOutlined, DeleteOutlined, CloudDownloadOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { json2excel } from "js2excel";

import ResultError from "@packages/ResultError";
import { PRODUCTS_ICON } from "@constants/IconsConstants";
import { useColumnFilterProps } from "@utils/columnFilter";
import {
  getActiveSubstancesQuery,
  getActiveSubstanceFamiliesQuery,
  getGoalsQuery,
} from "@graphql/queries";
import {
  useUpdateActiveSubstance,
  useDeleteActiveSubstance,
} from "@graphql/mutations";

import EditableCell from "../../EditableCell";
import ManageProductForm from "../ManageProductForm";
import ManageProductCsvUpload from "../ManageProductCsvUpload";
import ManageProductList from "../ManageProductList";

const { Text } = Typography;
const tableProps = {
  size: "middle",
  pagination: false,
};
const newButtonProps = {
  type: "primary",
  icon: <PlusOutlined />,
  key: "newProduct",
};

function ManageProductTable() {
  const { search } = useLocation();
  const [activeSubstancesData, setActiveSubstanceData] = useState(false);
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [selectedActiveSubstance, setSelectedActiveSubstance] = useState();
  const { error, data } = useQuery(getActiveSubstancesQuery);
  const { data: activeSubstanceFamiliesData } = useQuery(
    getActiveSubstanceFamiliesQuery,
  );
  const { data: goalsData } = useQuery(getGoalsQuery);
  const [updateActiveSubstance] = useUpdateActiveSubstance();
  const [deleteActiveSubstance] = useDeleteActiveSubstance();
  const useFilterField = field => useColumnFilterProps(field);
  const toggleFormModal = () => setFormModalVisible(!formModalVisible);

  useEffect(() => {
    if (data) setActiveSubstanceData(data.activeSubstances);
  }, [data]);

  const handleSave = data => {
    const { id, name, withdrawalPeriod, goal, activeSubstanceFamily } = data;
    const newData = [...activeSubstancesData];
    const index = newData.findIndex(item => item.id === data.id);
    const item = newData[index];

    newData.splice(index, 1, { ...item, ...data });

    if (JSON.stringify(newData) !== JSON.stringify(activeSubstancesData)) {
      updateActiveSubstance({
        variables: {
          id,
          data: {
            name: { set: name },
            goal: {
              connect: { id: goal.id || goal },
            },
            withdrawalPeriod: { set: Number(withdrawalPeriod) },
            activeSubstanceFamily: {
              connect: {
                id: activeSubstanceFamily.id || activeSubstanceFamily,
              },
            },
          },
        },
        refetchQueries: ["GetActiveSubstancesQuery"],
      });
    }
  };

  const handleDelete = id => {
    deleteActiveSubstance({
      variables: { id },
      refetchQueries: ["GetActiveSubstancesQuery"],
    });
  };
  const columns = [
    {
      dataIndex: "name",
      title: "Nome",
      editable: true,
      ...useFilterField("name"),
      fixed: `left`,
    },
    {
      dataIndex: "products",
      title: "Produtos",
      render: (value, { id, name }) => (
        <Button
          size="small"
          onClick={() => setSelectedActiveSubstance({ id, name })}
        >
          {value.length} produtos
        </Button>
      ),
    },
    {
      dataIndex: "goal",
      title: "Finalidade",
      editable: true,
      inputType: `select`,
      inputData: goalsData?.goals,
      render: value => <Text code>{value?.name}</Text>,
    },
    {
      dataIndex: "withdrawalPeriod",
      title: "Interv. Seg.",
      editable: true,
      ...useFilterField("withdrawalPeriod"),
      render: value => <Text code>{value}</Text>,
    },
    {
      dataIndex: "activeSubstanceFamily",
      title: "Familia",
      editable: true,
      inputType: `select`,
      inputData: activeSubstanceFamiliesData?.activeSubstanceFamilies,
      render: value => value?.name,
    },
    {
      align: "center",
      render: (value, { id, name }) => (
        <Popconfirm
          title={`Tem a certeza que quer eliminar ${name} ?`}
          onConfirm={() => handleDelete(id)}
          okText="Sim"
          cancelText="Não"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];
  const mappedColumns = columns.map(col => {
    if (!col.editable) return col;

    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        inputType: col.inputType,
        inputData: col.inputData,
        handleSave,
      }),
    };
  });

  const download = () => {
    json2excel({
      data: activeSubstancesData.map(({ name, goal, withdrawalPeriod, activeSubstanceFamily }) => ({
        Nome: name,
        Finalidade: goal?.name,
        "Interv. Seg.": withdrawalPeriod,
        Familia: activeSubstanceFamily?.name,

      })),
      name: "Fitofarmacos",
    });
  };

  return (
    <>
      {selectedActiveSubstance && (
        <Modal
          title={`Produtos de ${selectedActiveSubstance?.name}`}
          visible
          onCancel={() => setSelectedActiveSubstance(null)}
          width={960}
          footer={null}
        >
          <ManageProductList activeSubstanceId={selectedActiveSubstance?.id} />
        </Modal>
      )}

      <Modal
        title="Novo produto"
        visible={formModalVisible}
        onCancel={() => toggleFormModal()}
        footer={null}
        width={720}
      >
        <ManageProductForm afterSubmit={() => toggleFormModal()} />
      </Modal>

      <Modal
        title="Carregar ficheiro CSV"
        visible={search.includes("?csv=true")}
        footer={null}
      >
        <ManageProductCsvUpload
          afterSubmit={() => {}}
          importType={
            search.includes("&type=product") ? "product" : "activeSubstance"
          }
        />
      </Modal>

      <PageHeader
        title="Produtos"
        avatar={{ src: PRODUCTS_ICON }}
        extra={[
          <Button {...newButtonProps} onClick={() => toggleFormModal()}>
            Novo produto
          </Button>,
          <Button icon={<CloudDownloadOutlined />} onClick={download}>
            Exportar
          </Button>,
        ]}
      />

      {error && <ResultError />}

      <Skeleton loading={!error && !activeSubstancesData} active>
        {activeSubstancesData && (
          <Table
            components={EditableCell.Components}
            columns={mappedColumns}
            dataSource={activeSubstancesData}
            rowKey={({ id }) => id}
            rowClassName={() => "editable-row"}
            {...tableProps}
          />
        )}
      </Skeleton>
    </>
  );
}

export default ManageProductTable;
