export const ROOT_PATH = '/'
export const LOGIN_PATH = '/login'
export const DASHBOARD_PATH = '/dashboard'
export const CAMPAIGNS_PATH = '/campaigns'
export const PRODUCERS_PATH = '/producers'
export const PROVIDERS_PATH = '/providers'
export const MANUFACTURERS_PATH = '/manufacturers'
export const PORTIONS_PATH = '/portions'
export const CULTURES_PATH = '/cultures'
export const PRODUCTS_PATH = '/products'
export const FIELDBOOKS_PATH = '/fieldbooks'
export const FERTILIZERS_PATH = '/fertilizers'
export const ARCHIVED_FIELDBOOK_PATH = `/archived${FIELDBOOKS_PATH}`
export const EXPERTS_PATH = '/experts'
export const OPERATORS_PATH = '/operators'
export const EQUIPMENTS_PATH = '/equipments'
export const APPLICATION_TYPES_PATH = '/application-types'
export const PHENOLOGICAL_STATES_PATH = '/phenological-states'
export const ACTIVE_SUBSTANCE_FAMILIES_PATH = '/active-substance-families'
export const IRRIGATION_TYPES_PATH = '/irrigation-types'
export const PUMPING_SYSTEMS_PATH = '/pumping-systems'
export const GOALS_PATH = '/goals'
export const EXPORT_PATH = '/export'
