/* eslint-disable react/no-array-index-key */
import React, { useState, useEffect, Fragment, createRef } from 'react'
import {
  GoogleMap,
  Autocomplete,
  DrawingManager,
  useLoadScript,
  Marker,
  Polygon,
  InfoBox,
} from '@react-google-maps/api'
import { arrayOf, number, shape, string } from 'prop-types'
import { Button, Input, Row, Col, Popover, Form } from 'antd'
import {
  DragOutlined,
  SaveOutlined,
  DeleteOutlined,
  EnvironmentOutlined,
  HighlightOutlined,
} from '@ant-design/icons'

import { useUpdatePortionMap } from '@graphql/mutations'

const MAP_TYPE = `hybrid`
const ButtonGroup = Button.Group
const BUTTON_SIZE = `large`
const styles = {
  container: {
    height: '80vh',
    borderRadius: 6,
    marginTop: 15,
  },
  infoBox: {
    background: `#fff`,
    padding: `3px 7px`,
    borderRadius: `2px`,
    boxShadow: `0 2px 2px rgba(0, 0, 0, .2)`,
    fontWeight: `500`,
    fontSize: `13px`,
    marginTop: `5px`,
  },
}
const center = {
  lat: 39.16181660000001,
  lng: -8.7888254,
}
const drawingOptions = {
  drawingControl: false,
  // markerOptions: {
  //   draggable: true,
  // },
  // polygonOptions: {
  //   // draggable: true,
  //   // editable: true,
  //   fillColor: `#d8125a`,
  // },
}
const markerOptions = {}
const polygonOptions = {
  // strokeColor: `#F9627D`,
  // fillColor: `#F9627D`,
}
const scriptOptions = {
  googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  libraries: [
    'places',
    'drawing',
  ],
}

function ManagePortionMap({ portionId, data }) {
  const [map, setMap] = useState()
  const [drawingManager, setDrawingManager] = useState()
  const [drawingMode, setDrawingMode] = useState(null)
  const [canSave, setCanSave] = useState(false)
  const [autocomplete, setAutocomplete] = useState()
  const [coords, setCoords] = useState(data.coords || center)
  const [markers, setMarkers] = useState(data.markers)
  const [polygons, setPolygons] = useState(data.polygons)
  const [popoverVisible, setPopoverVisible] = useState(false)
  const [currentPin, setCurrentPin] = useState()
  const [mapZoom, setMapZoom] = useState(data.mapZoom || 13)
  const [updatePortion, { loading }] = useUpdatePortionMap()
  const { isLoaded } = useLoadScript({ ...scriptOptions })
  const onMapLoad = map => setMap(map)

  const onPlaceChanged = () => {
    const { geometry: { location } } = autocomplete.getPlace()

    setCoords({ ...location.toJSON() })
  }
  const handleUpdate = () => (
    updatePortion({
      variables: {
        id: portionId,
        data: { coords, markers, polygons: polygons ?? undefined, mapZoom: { set: mapZoom } },
      },
    }).then(() => setCanSave(false))
  )

  const handleDrawingMode = type => {
    drawingManager.setDrawingMode(type === `delete` ? null : type)
    setDrawingMode(type)
  }

  const addMarker = e => {
    const position = e.position.toJSON()
    const newMarkers = [...(markers || []), { position }]

    setMarkers(newMarkers)
    setPopoverVisible(true)
    setCurrentPin(position)

    // We'll set `null` because we're handling markers
    // through `setState`
    e.setMap(null)
  }

  const addPolygon = e => {
    const paths = []

    e.getPaths().getArray()[0].getArray().map(polygon => (
      paths.push(polygon.toJSON())
    ))

    const newPolygons = [...(polygons || []), { paths }]

    // We'll set `null` because we're handling markers
    // through `setState`
    e.setMap(null)

    setPolygons(newPolygons)
  }

  const updateMarker = ({ current: { state } }, marker) => {
    const position = state.marker.position.toJSON()

    setMarkers(
      markers.map(m => (
        m === marker ? { ...m, position } : m
      )),
    )
  }

  const updatePolygon = ({ current: { state } }, polygon) => {
    const paths = []

    state.polygon.getPaths().getArray()[0].getArray().map(polygon => (
      paths.push(polygon.toJSON())
    ))

    setPolygons(
      polygons.map(p => (
        p === polygon ? { ...p, paths } : p
      )),
    )
  }

  const removeMarker = marker => {
    if (drawingMode === `delete`) {
      setMarkers(markers.filter(m => m !== marker))
    }
  }

  const removePolygon = polygon => {
    if (drawingMode === `delete`) {
      setPolygons(polygons.filter(p => p !== polygon))
    }
  }

  const saveLabel = ({ description: title }) => {
    setMarkers(
      markers.map(m => (
        m.position === currentPin ? { ...m, title } : m
      )),
    )

    setCurrentPin(null)
    setPopoverVisible(false)
  }

  const cancelSave = () => {
    setMarkers(data.markers)
    setPolygons(data.polygons)
    setCanSave(false)
  }

  const updateCoords = ({ current: { state } }) => {
    const coords = state.map.getCenter().toJSON()

    setCoords(coords)
    setCanSave(true)
  }

  useEffect(() => {
    if (
      (markers !== data.markers)
      || (polygons !== data.polygons)
    ) {
      setCanSave(true)
    }
  }, [markers, polygons, data])

  if (!isLoaded) return `A carregar mapa ...`

  const mapRef = createRef()

  return (
    <>
      <Row gutter={16}>
        <Col span={6}>
          <Autocomplete
            onLoad={ac => setAutocomplete(ac)}
            onPlaceChanged={onPlaceChanged}
          >
            <Input
              type="text"
              placeholder="Procurar localização"
              size={BUTTON_SIZE}
            />
          </Autocomplete>
        </Col>

        <Col span={6}>
          <ButtonGroup>
            <Button
              type={!drawingMode ? `primary` : ``}
              size={BUTTON_SIZE}
              onClick={() => handleDrawingMode(null)}
            >
              <DragOutlined />
            </Button>

            <Popover
              content={(
                <Form onFinish={saveLabel}>
                  <Form.Item
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: `Campo obrigatório`,
                      },
                    ]}
                    hasFeedback
                  >
                    <Input placeholder="Descrição" />
                  </Form.Item>

                  <Button
                    type="primary"
                    htmlType="submit"
                    icon={<SaveOutlined />}
                    block
                  >
                    Guardar
                  </Button>
                </Form>
              )}
              title="Descrição no Pin"
              placement="bottom"
              visible={popoverVisible}
            >
              <Button
                type={drawingMode === `marker` ? `primary` : ``}
                size={BUTTON_SIZE}
                onClick={() => handleDrawingMode(`marker`)}
              >
                <EnvironmentOutlined />
              </Button>
            </Popover>

            <Button
              type={drawingMode === `polygon` ? `primary` : ``}
              size={BUTTON_SIZE}
              onClick={() => handleDrawingMode(`polygon`)}
            >
              <HighlightOutlined />
            </Button>

            <Button
              type={drawingMode === `delete` ? `danger` : ``}
              size={BUTTON_SIZE}
              onClick={() => handleDrawingMode(`delete`)}
            >
              <DeleteOutlined />
            </Button>
          </ButtonGroup>
        </Col>

        <Col span={12} style={{ textAlign: `right` }}>
          {canSave && (
            <Button
              onClick={() => cancelSave()}
              size={BUTTON_SIZE}
              style={{ marginRight: 10 }}
            >
              Cancelar
            </Button>
          )}

          <Button
            type="primary"
            onClick={() => handleUpdate()}
            icon={<SaveOutlined />}
            size={BUTTON_SIZE}
            disabled={!canSave || loading}
            loading={loading}
          >
            {loading ? `A guardar` : `Guardar mapa`}
          </Button>
        </Col>
      </Row>

      <GoogleMap
        ref={mapRef}
        center={coords}
        onLoad={onMapLoad}
        zoom={mapZoom}
        mapContainerStyle={styles.container}
        onDragEnd={() => updateCoords(mapRef)}
        options={{
          streetViewControl: false,
          controlSize: 24,
          tilt: 0,
          mapTypeId: MAP_TYPE,
        }}
        onZoomChanged={() => map && setMapZoom(map.zoom)}
      >
        <DrawingManager
          onLoad={dm => setDrawingManager(dm)}
          onMarkerComplete={addMarker}
          onPolygonComplete={addPolygon}
          options={drawingOptions}
        />

        {markers && markers.map((marker, index) => {
          const ref = createRef()

          return (
            <Fragment key={index}>
              <Marker
                ref={ref}
                {...marker}
                onClick={() => removeMarker(marker)}
                onDragEnd={() => updateMarker(ref, marker)}
                options={markerOptions}
                draggable
                editable
              />

              {marker.title && (
                <InfoBox
                  {...marker}
                  options={{ closeBoxURL: '', enableEventPropagation: true }}
                >
                  <div style={styles.infoBox}>
                    {marker.title}
                  </div>
                </InfoBox>
              )}
            </Fragment>
          )
        })}

        {polygons && polygons.map((polygon, index) => {
          const ref = createRef()

          return (
            <Polygon
              ref={ref}
              key={index}
              {...polygon}
              onClick={() => removePolygon(polygon)}
              onDragEnd={() => updatePolygon(ref, polygon)}
              onMouseUp={() => updatePolygon(ref, polygon)}
              options={polygonOptions}
              draggable
              editable
            />
          )
        })}
      </GoogleMap>
    </>
  )
}

ManagePortionMap.propTypes = {
  portionId: string.isRequired,
  data: shape({
    markers: arrayOf(
      shape({
        position: shape({
          lat: number,
          lng: number,
        }),
      }),
    ),
    circles: arrayOf(
      shape({
        center: shape({
          lat: number,
          lng: number,
        }),
        radius: number,
      }),
    ),
    polygons: arrayOf(
      shape({
        paths: arrayOf(
          shape({
            lat: number,
            lng: number,
          }),
        ),
      }),
    ),
  }),
}

ManagePortionMap.defaultProps = {
  data: {
    circles: [],
    polygons: [],
  },
}

export default ManagePortionMap
