import React, { useState, useEffect } from 'react'
import { PageHeader, Button, Table, Typography, Skeleton, Popconfirm, Modal } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { PlusOutlined, DeleteOutlined, CloudUploadOutlined } from '@ant-design/icons'

import ResultError from '@packages/ResultError'
import { PRODUCERS_ICON } from '@constants/IconsConstants'
import { useColumnFilterProps } from '@utils/columnFilter'
import { getProducersQuery } from '@graphql/queries'
import { useUpdateProducer, useDeleteProducer } from '@graphql/mutations'

import EditableCell from '../../EditableCell'
import ManageProducerForm from '../ManageProducerForm'
import ManageProducerCsvUpload from '../ManageProducerCsvUpload'

const { Text } = Typography
const tableProps = {
  size: 'middle',
  pagination: false,
}
const newButtonProps = {
  type: 'primary',
  icon: <PlusOutlined />,
  key: 'newProducer',
}
const csvButtonProps = {
  icon: <CloudUploadOutlined />,
  key: 'uploadCsv',
}

function ManageProducerTable() {
  const [producersData, setProducersData] = useState(false)
  const [formModalVisible, setFormModalVisible] = useState(false)
  const [csvModalVisible, setCsvModalVisible] = useState(false)
  const { error, data } = useQuery(getProducersQuery)
  const [updateProducer] = useUpdateProducer()
  const [deleteProducer] = useDeleteProducer()
  const useFilterField = field => useColumnFilterProps(field)
  const toggleFormModal = () => setFormModalVisible(!formModalVisible)
  const toggleCsvModal = () => setCsvModalVisible(!csvModalVisible)

  useEffect(() => {
    if (data) setProducersData(data.producers)
  }, [data])

  const handleSave = data => {
    const { id, number, name, nifap } = data
    const newData = [...producersData]
    const index = newData.findIndex(item => item.id === data.id)
    const item = newData[index]

    newData.splice(index, 1, { ...item, ...data })

    if (JSON.stringify(newData) !== JSON.stringify(producersData)) {
      updateProducer({
        variables: {
          id,
          data: {
            number: { set: Number(number) },
            name: { set: name },
            nifap: { set: nifap },
          },
        },
      })

      setProducersData(newData)
    }
  }

  const handleDelete = id => {
    deleteProducer({
      variables: { id },
      refetchQueries: ['GetProducersQuery'],
    })
  }
  const columns = [
    {
      dataIndex: 'number',
      title: 'Número',
      ...useFilterField('number'),
      editable: true,
      render: value => <Text code>{value}</Text>,
    },
    {
      dataIndex: 'name',
      title: 'Nome',
      editable: true,
      ...useFilterField('name'),
    },
    {
      dataIndex: 'vatNumber',
      title: 'Contribuinte',
      ...useFilterField('vatNumber'),
      editable: true,
      render: value => <Text code>{value}</Text>,
    },
    {
      dataIndex: 'nifap',
      title: 'NIFAP',
      editable: true,
      ...useFilterField('nifap'),
      render: value => value && <Text code>{value}</Text>,
    },
    {
      align: 'center',
      render: (value, { id, name }) => (
        <Popconfirm
          title={`Tem a certeza que quer eliminar ${name} ?`}
          onConfirm={() => handleDelete(id)}
          okText="Sim"
          cancelText="Não"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ]
  const mappedColumns = columns.map(col => {
    if (!col.editable) return col

    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    }
  })

  return (
    <>
      <Modal
        title="Novo produtor"
        visible={formModalVisible}
        onCancel={() => toggleFormModal()}
        footer={null}
      >
        <ManageProducerForm afterSubmit={() => toggleFormModal()} />
      </Modal>

      <Modal
        title="Carregar ficheiro CSV"
        visible={csvModalVisible}
        onCancel={() => toggleCsvModal()}
        footer={null}
      >
        <ManageProducerCsvUpload afterSubmit={() => toggleCsvModal()} />
      </Modal>

      <PageHeader
        title="Produtores"
        avatar={{ src: PRODUCERS_ICON }}
        extra={[
          <Button {...newButtonProps} onClick={() => toggleFormModal()}>
            Novo produtor
          </Button>,
          <Button {...csvButtonProps} onClick={() => toggleCsvModal()}>
            CSV
          </Button>,
        ]}
      />

      {error && <ResultError />}

      <Skeleton loading={!error && !producersData} active>
        {producersData && (
          <Table
            components={EditableCell.Components}
            columns={mappedColumns}
            dataSource={producersData}
            rowKey={({ id }) => id}
            rowClassName={() => 'editable-row'}
            {...tableProps}
          />
        )}
      </Skeleton>
    </>
  )
}

export default ManageProducerTable
