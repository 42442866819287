import { gql } from 'apollo-boost'

import useMutation, { mutationTypes } from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation(
    $data: FertilizationCreateInput!
  ) {
    createOneFertilization(data: $data) {
      id
    }
  }
`

export default () => (
  useMutation(MUTATION, `Fertilização criada`, mutationTypes.CREATE)
)
