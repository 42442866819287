import React from 'react'
import { Table, TableHeader, TableBody, DataTableCell } from '@david.kucsai/react-pdf-table'
import { arrayOf, shape, func } from 'prop-types'

import { formatDate } from '@utils/dateUtils'
import { meteorologyTypes } from '@constants/AppConstants'

import { cellStyle } from './style'

function Pesticides({ data, cell: Cell }) {
  return (
    <Table data={data}>
      <TableHeader>
        <Cell>Data</Cell>
        <Cell>Área (ha)</Cell>
        <Cell>Sem. Plantação</Cell>
        <Cell style={{ flexGrow: 2 }}>Produto</Cell>
        <Cell style={{ flexGrow: 2 }}>Finalidade</Cell>
        <Cell>Família</Cell>
        <Cell style={{ flexGrow: 2 }}>Subs. Activa</Cell>
        <Cell>APV/AV</Cell>
        <Cell>Dose (ha)</Cell>
        <Cell>I.S.</Cell>
        <Cell>Calda (lt / ha)</Cell>
        <Cell style={{ flexGrow: 2 }}>Fornecedor</Cell>
        <Cell style={{ flexGrow: 2 }}>Estado Fenológico</Cell>
        <Cell>Metereo.</Cell>
        <Cell>Data Segura Colheita</Cell>
      </TableHeader>
      <TableBody>
        <DataTableCell style={cellStyle} getContent={({ date }) => formatDate(date)} />
        <DataTableCell style={cellStyle} getContent={({ area }) => area} />
        <DataTableCell style={cellStyle} getContent={({ plantingWeeks }) => plantingWeeks} />
        <DataTableCell style={{ ...cellStyle, flexGrow: 2 }} getContent={({ product }) => product?.name} />
        <DataTableCell
          style={{ ...cellStyle, flexGrow: 2 }}
          getContent={({ product }) => product?.activeSubstance?.goal?.name}
        />
        <DataTableCell
          style={cellStyle}
          getContent={({ product }) => product?.activeSubstance?.activeSubstanceFamily?.name}
        />
        <DataTableCell
          style={{ ...cellStyle, flexGrow: 2 }}
          getContent={({ product }) => product?.activeSubstance?.name}
        />
        <DataTableCell style={cellStyle} getContent={({ product }) => product?.apv} />
        <DataTableCell style={cellStyle} getContent={({ product }) => `${product?.dose} ${product?.unity?.name}`} />
        <DataTableCell
          style={{ ...cellStyle }}
          getContent={({ product }) => product?.activeSubstance?.withdrawalPeriod}
        />
        <DataTableCell style={cellStyle} getContent={({ sauce }) => sauce} />
        <DataTableCell style={{ ...cellStyle, flexGrow: 2 }} getContent={({ provider }) => provider?.name} />
        <DataTableCell
          style={{ ...cellStyle, flexGrow: 2 }}
          getContent={({ phenologicalState }) => phenologicalState?.name}
        />
        <DataTableCell style={cellStyle} getContent={({ meteorology }) => meteorologyTypes[meteorology]} />
        <DataTableCell style={cellStyle} getContent={({ safeHarvestDate }) => formatDate(safeHarvestDate)} />
      </TableBody>
    </Table>
  )
}

Pesticides.propTypes = {
  data: arrayOf(
    shape({}),
  ),
  cell: func.isRequired,
}

Pesticides.defaultProps = {
  data: [],
}

export default Pesticides
