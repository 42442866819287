import { gql } from "apollo-boost";

import useMutation, { mutationTypes } from "@utils/graphqlUtils";

const MUTATION = gql`
  mutation ($id: String!) {
    deleteOneFieldbook(where: { id: $id }) {
      id
    }
  }
`;

export default () =>
  useMutation(MUTATION, `Caderno de campo eliminado`, mutationTypes.DELETE);
