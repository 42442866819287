import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Skeleton, Divider, Alert, Table, Row, Col, Button } from "antd";
import { arrayOf, string, func } from "prop-types";

import {
  getFertilizationPlansHistoricQuery,
  getFieldbookQuery,
} from "@graphql/queries";
import { useCreateFertilizationPlan } from "@graphql/mutations";

const tableProps = {
  size: "middle",
  pagination: false,
};

function FieldbookFertilizationPlansHistoric({
  fieldbookId,
  producersIds,
  afterSubmit,
}) {
  const [createFertilizationPlan] = useCreateFertilizationPlan();
  const mappedIds = producersIds.map(id => id);
  const [selectedRows, setSelectedRows] = useState([]);
  const queryVars = { variables: { where: mappedIds } };
  const { data, error, loading } = useQuery(getFertilizationPlansHistoricQuery, {
    ...queryVars,
  });
  const rowSelection = {
    onChange: (_, selectedRows) => setSelectedRows(selectedRows),
  };
  const columns = [
    {
      dataIndex: "applicationType",
      title: "Tipo de Aplicação",
      render: value => value.name,
    },
    {
      dataIndex: "unity",
      title: "Unidade",
      render: value => value.name,
    },
    {
      dataIndex: "dose",
      title: "Dose",
    },
    {
      dataIndex: "kgOfN",
      title: "Kg de N",
    },
    {
      title: "NPK",
      render: (_, record) => `${record.n}-${record.p}-${record.k}`,
    },
  ];

  const createMultiplePesticides = () => {
    selectedRows.map(fields => {
      const { kgOfN, n, p, k, dose, unity, applicationType } = fields;
      const refetchQueries = [
        {
          query: getFieldbookQuery,
          variables: { id: fieldbookId },
        },
      ].filter(Boolean);

      return createFertilizationPlan({
        variables: {
          data: {
            fieldbook: {
              connect: { id: fieldbookId },
            },
            unity: {
              connect: { id: unity.id },
            },
            applicationType: {
              connect: { id: applicationType.id },
            },
            dose,
            kgOfN,
            n,
            p,
            k,
          },
        },
        refetchQueries,
      }).then(() => afterSubmit());
    });
  };

  if (loading) return <Skeleton />;
  if (error) {
    return (
      <Alert
        message="Algo correu mal"
        description={
          <pre>
            <code>{JSON.stringify(error, null, 2)}</code>
          </pre>
        }
        type="error"
        showIcon
      />
    );
  }

  return (
    <>
      {data?.fieldbooks.map(({ id, portion, fertilizationPlans }) => (
        <div key={id}>
          <Divider>Parcela: {portion.name}</Divider>

          <Table
            columns={columns}
            dataSource={fertilizationPlans}
            rowKey={({ id }) => id}
            pagination={false}
            rowSelection={{
              ...rowSelection,
            }}
            {...tableProps}
          />
        </div>
      ))}

      <Row style={{ marginTop: 30, textAlign: "center" }}>
        <Col span={24}>
          <Button
            type="primary"
            disabled={selectedRows.length < 1}
            onClick={createMultiplePesticides}
          >
            {selectedRows.length > 0
              ? `Copiar ${selectedRows.length} planos de fertilização`
              : `Selecione planos de fertilização`}
          </Button>
        </Col>
      </Row>
    </>
  );
}

FieldbookFertilizationPlansHistoric.propTypes = {
  fieldbookId: string.isRequired,
  producersIds: arrayOf(string).isRequired,
  afterSubmit: func.isRequired,
};

export default FieldbookFertilizationPlansHistoric;
