import { Menu } from "antd";
import { bool, number, string } from "prop-types";
import React from "react";
import { Link } from "react-router-dom";

import { ARCHIVED_FIELDBOOK_PATH, FIELDBOOKS_PATH } from "@constants/RoutesConstants";

const MENU_MODE = "horizontal";

function FieldbookMenu({ id, version, isArchived }) {
  const mainRoute = isArchived ? `${ARCHIVED_FIELDBOOK_PATH}/${id}/${version}` : `${FIELDBOOKS_PATH}/${id}`;
  const availableMenus = [
    {
      path: `${mainRoute}/installations`,
      name: `Instalações`,
    },
    {
      path: `${mainRoute}/pesticides`,
      name: `Fitofármacos`,
    },
    {
      path: `${mainRoute}/fertilizations`,
      name: `Fertilizações`,
    },
    {
      path: `${mainRoute}/waterings`,
      name: `Regas`,
    },
    {
      path: `${mainRoute}/weekly-watering-logs`,
      name: `Registos Semanal Rega`,
    },
    {
      path: `${mainRoute}/fertilization-plans`,
      name: "Planos de Fertilização",
    },
    {
      path: `${mainRoute}/visit-reports`,
      name: "Relatórios de Visita",
    },
  ];

  return (
    <Menu mode={MENU_MODE} defaultSelectedKeys={[global.location.pathname]}>
      {availableMenus.map(({ path, name }) => (
        <Menu.Item key={path}>
          <Link to={path}>{name}</Link>
        </Menu.Item>
      ))}
    </Menu>
  );
}

FieldbookMenu.propTypes = {
  id: string.isRequired,
  version: number,
  isArchived: bool,
};

FieldbookMenu.defaultProps = {
  isArchived: false,
  version: null,
};

export default FieldbookMenu;
