import React, { useState } from 'react'
import { Form, Input, InputNumber, Button, Row, Col, Select, Modal } from 'antd'
import { func } from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { PlusCircleOutlined, MinusCircleOutlined } from '@ant-design/icons'

import {
  getProductsQuery,
  getActiveSubstancesQuery,
  getManufacturersQuery,
  getUnitiesQuery,
  getActiveSubstanceFamiliesQuery,
  getGoalsQuery,
} from '@graphql/queries'
import { useCreateProduct } from '@graphql/mutations'

import ManageManufacturerForm from '../../ManageManufacturer/ManageManufacturerForm'
import ManageActiveSubstanceFamilyForm from '../../ManageActiveSubstanceFamily/ManageActiveSubstanceFamilyForm'
import ManageGoalForm from '../../ManageGoal/ManageGoalForm'

const { Option } = Select

function ManageProductForm({ afterSubmit }) {
  const [form] = Form.useForm()
  const [createProduct, { loading }] = useCreateProduct()
  const [newActiveSubstance, setNewActiveSubstance] = useState(false)
  const [newGoal, setNewGoal] = useState(false)
  const [newManufacturer, setNewManufacturer] = useState(false)
  const [newActiveSubstanceFamily, setNewActiveSubstanceFamily] = useState(false)
  const { loading: loadingActiveSubstances, data: activeSubstances } = useQuery(getActiveSubstancesQuery)
  const { loading: loadingManufacturers, data: manufacturers } = useQuery(getManufacturersQuery)
  const { loading: loadingUnities, data: unitiesData } = useQuery(getUnitiesQuery)
  const { loading: loadingGoals, data: goalsData } = useQuery(getGoalsQuery)
  const {
    loading: loadingActiveSubstanceFamilies,
    data: activeSubstanceFamiliesData,
  } = useQuery(getActiveSubstanceFamiliesQuery)

  const onFinish = ({
    name,
    apv,
    dose,
    unity,
    content,
    manufacturer,
    activeSubstance,
    activeSubstanceName,
    activeSubstanceGoal,
    activeSubstanceWithdrawalPeriod,
    activeSubstanceFamily,
    formulation,
  }) => {
    createProduct({
      variables: {
        data: {
          name,
          unity: { connect: { id: unity } },
          manufacturer: { connect: { id: manufacturer } },
          content: parseInt(content, 0),
          apv: parseInt(apv, 0),
          dose: parseFloat(dose),
          activeSubstance: {
            ...!newActiveSubstance && { connect: { id: activeSubstance } },
            ...newActiveSubstance && {
              create: {
                name: activeSubstanceName,
                goal: { connect: { id: activeSubstanceGoal } },
                withdrawalPeriod: parseInt(activeSubstanceWithdrawalPeriod, 0),
                activeSubstanceFamily: {
                  connect: { id: activeSubstanceFamily },
                },
              },
            },
          },
          formulation,
        },
      },
      refetchQueries: [
        'GetActiveSubstancesQuery',
        {
          query: getProductsQuery,
        },
      ],
    }).then(() => afterSubmit(), form.resetFields())
  }

  return (
    <Form onFinish={onFinish} form={form}>
      {newManufacturer && (
        <Modal
          title="Novo fabricante"
          onCancel={() => setNewManufacturer(false)}
          footer={null}
          visible
        >
          <ManageManufacturerForm afterSubmit={() => setNewManufacturer(false)} />
        </Modal>
      )}

      <Modal
        title="Nova familia"
        onCancel={() => setNewActiveSubstanceFamily(false)}
        footer={null}
        visible={newActiveSubstanceFamily}
      >
        <ManageActiveSubstanceFamilyForm afterSubmit={() => setNewActiveSubstanceFamily(false)} />
      </Modal>

      {newActiveSubstance && (
        <Modal
          title="Nova finalidade"
          onCancel={() => setNewGoal(false)}
          footer={null}
          visible={newGoal}
        >
          <ManageGoalForm afterSubmit={() => setNewGoal(false)} />
        </Modal>
      )}

      <Row style={{ marginBottom: 20 }}>
        <Col span={24}>
          <Button
            icon={newActiveSubstance ? <MinusCircleOutlined /> : <PlusCircleOutlined />}
            onClick={() => setNewActiveSubstance(!newActiveSubstance)}
            style={{ marginRight: 10 }}
          >
            Substância activa
          </Button>

          <Button
            icon={<PlusCircleOutlined />}
            onClick={() => setNewManufacturer(!newManufacturer)}
            style={{ marginRight: 10 }}
          >
            Fabricante
          </Button>

          {newActiveSubstance && (
            <>
              <Button
                icon={<PlusCircleOutlined />}
                onClick={() => setNewActiveSubstanceFamily(!newActiveSubstanceFamily)}
                style={{ marginRight: 10 }}
              >
                Familía
              </Button>

              <Button
                icon={<PlusCircleOutlined />}
                onClick={() => setNewGoal(!newGoal)}
              >
                Finalidade
              </Button>
            </>
          )}
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={24}>
          {newActiveSubstance ? (
            <>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="activeSubstanceName"
                    rules={[
                      {
                        required: true,
                        message: `Campo obrigatório`,
                      },
                    ]}
                    hasFeedback
                  >
                    <Input placeholder="Nome da substância activa" size="large" autoFocus />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="activeSubstanceFamily"
                    rules={[
                      {
                        required: true,
                        message: `Campo obrigatório`,
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      loading={loadingActiveSubstanceFamilies}
                      placeholder="Escolha uma familía"
                      size="large"
                      optionFilterProp="children"
                      showSearch
                    >
                      {activeSubstanceFamiliesData?.activeSubstanceFamilies.map(({ id, name }) => (
                        <Option value={id} key={id}>{name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    name="activeSubstanceGoal"
                    rules={[
                      {
                        required: true,
                        message: `Campo obrigatório`,
                      },
                    ]}
                    hasFeedback
                  >
                    <Select
                      loading={loadingGoals}
                      placeholder="Escolha uma finalidade"
                      size="large"
                      optionFilterProp="children"
                      showSearch
                    >
                      {goalsData?.goals.map(({ id, name }) => (
                        <Option value={id} key={id}>{name}</Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <Form.Item
                    name="activeSubstanceWithdrawalPeriod"
                    rules={[
                      {
                        required: true,
                        message: `Campo obrigatório`,
                      },
                    ]}
                    hasFeedback
                  >
                    <Input placeholder="Intervalo de Segurança" size="large" />
                  </Form.Item>
                </Col>
              </Row>
            </>
          ) : (
            <Form.Item
              name="activeSubstance"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <Select
                loading={loadingActiveSubstances}
                placeholder="Escolha uma substância activa"
                size="large"
                optionFilterProp="children"
                showSearch
              >
                {activeSubstances?.activeSubstances.map(({ id, name }) => (
                  <Option value={id} key={id}>{name}</Option>
                ))}
              </Select>
            </Form.Item>
          )}
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Nome do produto" size="large" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="apv"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <InputNumber placeholder="APV" size="large" />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="dose"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <InputNumber placeholder="Dose" size="large" />
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="unity"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Select
              loading={loadingUnities}
              placeholder="Escolha uma unidade"
              size="large"
              optionFilterProp="children"
              showSearch
            >
              {unitiesData?.unities.map(({ id, name }) => (
                <Option value={id} key={id}>{name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="manufacturer"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Select
              loading={loadingManufacturers}
              placeholder="Escolha um fabricante"
              size="large"
              optionFilterProp="children"
              showSearch
            >
              {manufacturers?.manufacturers.map(({ id, name }) => (
                <Option value={id} key={id}>{name}</Option>
              ))}
            </Select>
          </Form.Item>
        </Col>

        <Col span={12}>
          <Form.Item
            name="content"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Teor" size="large" />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={12}>
          <Form.Item
            name="formulation"
            rules={[
              {
                required: true,
                message: `Campo obrigatório`,
              },
            ]}
            hasFeedback
          >
            <Input placeholder="Formulação" size="large" />
          </Form.Item>
        </Col>
      </Row>

      <Button
        type="primary"
        htmlType="submit"
        size="large"
        disabled={loading}
        block
      >
        Criar produto
      </Button>
    </Form>
  )
}

ManageProductForm.propTypes = {
  afterSubmit: func,
}

ManageProductForm.defaultProps = {
  afterSubmit: null,
}

export default ManageProductForm
