import React, { useState, useEffect } from 'react'
import { Form, Upload, Result, Button } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { csv } from 'csvtojson'
import { func } from 'prop-types'

import { camelize, normalize } from '@utils/textUtils'
import { useCreateProducer } from '@graphql/mutations'

const options = {
  trim: true,
  ignoreEmpty: true,
}

function ManageProducerCsvUpload({ afterSubmit }) {
  const [data, setData] = useState()
  const [uploadComplete, setUploadComplete] = useState(false)
  const [createProducer, { loading }] = useCreateProducer()

  const normFile = e => {
    if (Array.isArray(e)) return e

    return e && e.fileList
  }

  useEffect(() => {
    const createAllProducers = data => {
      data.map(async d => {
        const { numero, nome, contribuinte, nifap } = d

        await createProducer({
          variables: {
            data: {
              number: parseInt(numero, 0),
              name: nome,
              vatNumber: contribuinte,
              nifap,
            },
          },
          refetchQueries: ['GetProducersQuery'],
        })
      })

      setUploadComplete(true)
    }

    if (data) createAllProducers(data)
  }, [data, createProducer])

  const handleUpload = () => {}
  const props = {
    accept: `.txt, .csv, .xlsx`,
    name: `files`,
    beforeUpload: file => {
      const reader = new FileReader()

      reader.onload = e => {
        // const parsed = Papa.parse(e.target.result)

        csv(options)
          .fromString(e.target.result)
          .preFileLine((fileLineString, lineIdx) => {
            if (lineIdx === 0) {
              return normalize(
                camelize(fileLineString.toLowerCase()),
              )
            }

            return fileLineString
          })
          .then(data => setData(data))
      }

      reader.readAsText(file)

      return false
    },
  }

  if (uploadComplete) {
    return (
      <Result
        status="success"
        title="Produtores importados"
        subTitle="Pode fechar esta caixa."
        extra={[
          <Button type="primary" key="close" onClick={() => afterSubmit()}>
            Fechar
          </Button>,
        ]}
      />
    )
  }

  return (
    <Form onFinish={handleUpload}>
      <Form.Item>
        {!loading ? (
          <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
            <Upload.Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Clique ou arraste uma ficheiro para efectuar o upload.
              </p>
            </Upload.Dragger>
          </Form.Item>
        ) : `Loading ...`}
      </Form.Item>
    </Form>
  )
}

ManageProducerCsvUpload.propTypes = {
  afterSubmit: func.isRequired,
}

export default ManageProducerCsvUpload
