import React, { useEffect, useState } from "react";
import { string } from "prop-types";
import { Card, Button, Modal, Skeleton, Table, Popconfirm, Tooltip } from "antd";
import { ReadOutlined, DeleteOutlined, CloudDownloadOutlined, LoadingOutlined } from "@ant-design/icons";
import { useQuery, useLazyQuery } from "@apollo/react-hooks";
import { Link } from "react-router-dom";
import { utils, writeFile } from "xlsx";
import { formatDate } from "utils/dateUtils";

import { getStartDate } from "@helpers/fieldbookHelpers";
import ResultError from "@packages/ResultError";
import { getFieldbooksQuery, getFieldbookQuery } from "@graphql/queries";
import { useDeleteFieldbook } from "@graphql/mutations";
import { PORTIONS_PATH, FIELDBOOKS_PATH } from "@constants/RoutesConstants";

import FieldbookForm from "../../ManageFieldbook/FieldbookForm";

const tableProps = {
  size: "middle",
  pagination: false,
};

function ManageCampaignFieldbooks({ campaignId }) {
  const queryVars = { variables: { campaign: campaignId } };
  const { loading, error, data } = useQuery(getFieldbooksQuery, {
    ...queryVars,
    skip: !campaignId,
  });
  const [getFieldbook, { data: fieldbookData }] = useLazyQuery(getFieldbookQuery);
  const [deleteFieldbook] = useDeleteFieldbook();
  const [exporting, setExporting] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const toggleModal = () => setModalVisible(!modalVisible);
  const getFieldbookPath = (id) => `${FIELDBOOKS_PATH}/${id}/installations`;
  const getPortionPath = (id) => `${PORTIONS_PATH}/${id}/details`;

  const handleDelete = (id) => {
    deleteFieldbook({
      variables: { id },
      refetchQueries: ["GetFieldbooksQuery"],
    });
  };

  async function exportExcel(id) {
    await getFieldbook({ variables: { id } });
    setExporting(id);
  }

  useEffect(() => {
    if (!fieldbookData) return;

    async function trigger() {
      const startDate = getStartDate(fieldbookData.fieldbook.installations);
      const { endDate } = fieldbookData.fieldbook;
      const fieldbook = utils.json_to_sheet([
        {
          Produtores: fieldbookData.fieldbook.producers.map(({ producer: { name } }) => name).join(", "),
          Cultura: fieldbookData.fieldbook.campaign.culture.name,
          Ano: fieldbookData.fieldbook.campaign.year,
          Parcelários: fieldbookData.fieldbook.parcels.map(({ parcel: { number } }) => number).join(", "),
          "Área Total": fieldbookData.fieldbook.parcels.reduce((acc, { parcel: { area } }) => acc + area, 0),
          "Valor Água": fieldbookData.fieldbook.water,
          "Valor Solo": fieldbookData.fieldbook.soil,
          "Tipo de Rega": fieldbookData.fieldbook.irrigationType.name,
          "Tipo de Captação": fieldbookData.fieldbook.captureType.name,
          "Início Colheita": startDate ? formatDate(startDate) : "",
          "Final Colheita": endDate ? formatDate(endDate) : "",
        },
      ]);
      const installations = utils.json_to_sheet(
        fieldbookData.fieldbook.installations.map(
          ({
            variety: { name: variety },
            date,
            area,
            plantingWeeks,
            density,
            seedLot,
            provider: { name: provider },
            harvestDate,
            adjacentCrops,
          }) => ({
            Data: formatDate(date),
            Variedade: variety,
            "Área (ha)": area,
            "Sem. Plantação": plantingWeeks,
            "Densidade (n plantas / ha)": density,
            "Lote Sem.": seedLot,
            Fornecedor: provider,
            "Culturas Adjacentes": adjacentCrops,
            "Data Colheita": formatDate(harvestDate),
          }),
        ),
      );
      const pesticides = utils.json_to_sheet(
        fieldbookData.fieldbook.pesticides.map(
          ({
            date,
            area,
            plantingWeeks,
            product: { name: product },
            sauce,
            provider: { name: provider },
            phenologicalState: { name: phenologicalState },
            safeHarvestDate,
          }) => ({
            Data: formatDate(date),
            "Area (ha)": area,
            "Sem. Plantação": plantingWeeks,
            Produto: product,
            "Calda (lt / ha)": sauce,
            Fornecedor: provider,
            "Estado Fenológico": phenologicalState,
            "Data Segura Colheita": formatDate(safeHarvestDate),
          }),
        ),
      );
      const fertilizations = utils.json_to_sheet(
        fieldbookData.fieldbook.fertilizations.map(
          ({
            date,
            fertilizer: { name: fertilizer },
            dose,
            provider: { name: provider },
            applicationType: { name: applicationType },
            unity: { name: unity },
          }) => ({
            Data: formatDate(date),
            Fertilizante: fertilizer,
            Fornecedor: provider,
            Dose: `${dose} ${unity}`,
            "Tipo de Aplicação": applicationType,
          }),
        ),
      );
      const waterings = utils.json_to_sheet(
        fieldbookData.fieldbook.waterings.map(
          ({ startDate, endDate, phenologicalState: { name: phenologicalState }, hours, volume }) => ({
            Início: formatDate(startDate),
            Fim: formatDate(endDate),
            "Estado Fenológico": phenologicalState,
            "Horas de Rega": hours,
            "Volume de Água (m3)": volume,
          }),
        ),
      );
      const weeklyWateringLogs = utils.json_to_sheet(
        fieldbookData.fieldbook.weeklyWateringLogs.map(({ week, mm, hours }) => ({
          Semana: week,
          "Registo de Rega (mm)": mm,
          "Registo de Rega (horas)": hours,
        })),
      );
      const fertilizationPlans = utils.json_to_sheet(
        fieldbookData.fieldbook.fertilizationPlans.map(
          ({ applicationType: { name: applicationType }, unity: { name: unity }, dose, kgOfN, n, p, k }) => ({
            "Tipo de Aplicação": applicationType,
            Unidade: unity,
            Dose: dose,
            "Kg de N": kgOfN,
            NPK: `${n}-${p}-${k}`,
          }),
        ),
      );
      const visitReports = utils.json_to_sheet(
        fieldbookData.fieldbook.visitReports.map(({ date, description, recommendation }) => ({
          Data: formatDate(date),
          "Intervenção / Doença / Praga / Infestante detectada acima do NEA": description,
          "Substância Ativa Recomendada": recommendation,
        })),
      );
      const wb = utils.book_new();

      utils.book_append_sheet(wb, fieldbook, "Caderno");
      utils.book_append_sheet(wb, installations, "Instalações");
      utils.book_append_sheet(wb, pesticides, "Fitofármacos");
      utils.book_append_sheet(wb, fertilizations, "Fertilizações");
      utils.book_append_sheet(wb, waterings, "Regas");
      utils.book_append_sheet(wb, weeklyWateringLogs, "Registos Semanal Rega");
      utils.book_append_sheet(wb, fertilizationPlans, "Planos de Fertilização");
      utils.book_append_sheet(wb, visitReports, "Relatórios de Visita");

      /* export to XLSX */
      writeFile(wb, `Caderndo de Campo - ${fieldbookData.fieldbook.campaign.culture.name}.xlsx`);
      getFieldbook();
      setExporting(undefined);
    }

    trigger();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldbookData]);

  const columns = [
    {
      dataIndex: "portion",
      key: "portionName",
      title: "Parcela",
      render: (value) => <Link to={getPortionPath(value.id)}>{value.name}</Link>,
    },
    {
      dataIndex: "producers",
      key: "producers",
      title: "Produtor",
      render: (value) => {
        if (value?.length > 1) {
          const first = value[0].producer;

          return (
            <Tooltip
              placement="bottom"
              title={value.slice(Math.max(value.length - value.length, 1)).map(({ producer: { id, name } }) => (
                <div key={id}>{name}</div>
              ))}
            >
              {first?.name} +{value.length - 1}
            </Tooltip>
          );
        }

        return value[0]?.producer?.name;
      },
    },
    {
      title: "Ações",
      render: (value, { id }) => {
        const isExporting = exporting === id;

        return (
          <>
            <Link to={getFieldbookPath(id)} style={{ marginRight: 20 }}>
              <ReadOutlined /> Gerir
            </Link>

            <Button type="link" onClick={() => exportExcel(id)}>
              {isExporting ? (
                <>
                  <LoadingOutlined /> A exportar...
                </>
              ) : (
                <>
                  <CloudDownloadOutlined /> Exportar
                </>
              )}
            </Button>
          </>
        );
      },
    },
    {
      align: "center",
      render: (value, { id }) => (
        <Popconfirm
          title="Tem a certeza que quer eliminar?"
          onConfirm={() => handleDelete(id)}
          okText="Sim"
          cancelText="Não"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];

  return (
    <>
      <Modal
        title="Novo caderno de campo"
        visible={modalVisible}
        onCancel={() => toggleModal()}
        width={960}
        footer={null}
      >
        <FieldbookForm campaignId={campaignId} fieldbooks={data && data.fieldbooks} afterSubmit={() => toggleModal()} />
      </Modal>

      <Card
        title="Cadernos de Campo"
        extra={[
          <Button icon={<ReadOutlined />} onClick={() => toggleModal()} key="newFieldBook">
            Adicionar Caderno de Campo
          </Button>,
        ]}
      >
        {error && <ResultError />}

        <Skeleton loading={loading} active>
          {data && (
            <Table
              columns={columns}
              dataSource={data.fieldbooks}
              rowKey={({ id }) => id}
              rowClassName={() => "editable-row"}
              {...tableProps}
            />
          )}
        </Skeleton>
      </Card>
    </>
  );
}

ManageCampaignFieldbooks.propTypes = {
  campaignId: string.isRequired,
};

export default ManageCampaignFieldbooks;
