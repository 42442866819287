import React from 'react'
import { PageHeader, Card } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { useParams, useHistory } from 'react-router-dom'

import Loading from '@packages/Loading'
import ResultError from '@packages/ResultError'
import { getCultureQuery } from '@graphql/queries'
import { CULTURES_ICON } from '@constants/IconsConstants'

import ManageCultureVarieties from '../ManageCultureVarieties'

function ManageCultureDetails() {
  const { id } = useParams()
  const { goBack } = useHistory()
  const queryVars = { variables: { id } }
  const { loading, error, data } = useQuery(getCultureQuery, { ...queryVars })

  if (loading) return <Loading />
  if (error) return <ResultError />

  const { name, varieties } = data.culture

  return (
    <>
      <PageHeader
        title={name}
        onBack={() => goBack()}
        avatar={{ src: CULTURES_ICON }}
      />

      <Card title="Variedades">
        <ManageCultureVarieties cultureId={id} data={varieties} />
      </Card>
    </>
  )
}

export default ManageCultureDetails
