import React, { useState, useEffect } from "react";
import {
  PageHeader,
  Button,
  Table,
  Skeleton,
  Typography,
  Popconfirm,
  Modal,
} from "antd";
import { useQuery } from "@apollo/react-hooks";
import {
  PlusOutlined,
  DeleteOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import { json2excel } from "js2excel";

import ResultError from "@packages/ResultError";
import { FERTILIZERS_ICON } from "@constants/IconsConstants";
import { useColumnFilterProps } from "@utils/columnFilter";
import { getFertilizersQuery } from "@graphql/queries";
import { useUpdateFertilizer, useDeleteFertilizer } from "@graphql/mutations";

import EditableCell from "../../EditableCell";
import ManageFertilizerForm from "../ManageFertilizerForm";
import ManageFertilizerCsvUpload from "../ManageFertilizerCsvUpload";

const { Text } = Typography;
const tableProps = {
  size: "middle",
  pagination: false,
};
const newButtonProps = {
  type: "primary",
  icon: <PlusOutlined />,
  key: "newFertilizer",
};
const csvButtonProps = {
  icon: <CloudUploadOutlined />,
  key: "uploadCsv",
};

function ManageFertilizerTable() {
  const [fertilizersData, setFertilizersData] = useState(false);
  const [formModalVisible, setFormModalVisible] = useState(false);
  const [csvModalVisible, setCsvModalVisible] = useState(false);
  const { error, data } = useQuery(getFertilizersQuery);
  const [updateFertilizer] = useUpdateFertilizer();
  const [deleteFertilizer] = useDeleteFertilizer();
  const useFilterField = (field) => useColumnFilterProps(field);
  const toggleFormModal = () => setFormModalVisible(!formModalVisible);
  const toggleCsvModal = () => setCsvModalVisible(!csvModalVisible);

  useEffect(() => {
    if (data) setFertilizersData(data.fertilizers);
  }, [data]);

  const handleSave = (data) => {
    const { id, name, n, p, k } = data;
    const newData = [...fertilizersData];
    const index = newData.findIndex((item) => item.id === data.id);
    const item = newData[index];

    newData.splice(index, 1, { ...item, ...data });

    if (JSON.stringify(newData) !== JSON.stringify(fertilizersData)) {
      updateFertilizer({
        variables: {
          id,
          data: {
            name: { set: name },
            n: { set: Number(n) },
            p: { set: Number(p) },
            k: { set: Number(k) },
          },
        },
      });

      setFertilizersData(newData);
    }
  };

  const handleDelete = (id) => {
    deleteFertilizer({
      variables: { id },
      refetchQueries: ["GetFertilizersQuery"],
    });
  };
  const columns = [
    {
      dataIndex: "name",
      title: "Nome",
      editable: true,
      ...useFilterField("name"),
    },
    {
      dataIndex: "n",
      title: "N",
      editable: true,
      align: `center`,
      ...useFilterField("n"),
      render: (value) => <Text code>{value}</Text>,
    },
    {
      dataIndex: "p",
      title: "P",
      editable: true,
      align: `center`,
      ...useFilterField("p"),
      render: (value) => <Text code>{value}</Text>,
    },
    {
      dataIndex: "k",
      title: "K",
      editable: true,
      align: `center`,
      ...useFilterField("k"),
      render: (value) => <Text code>{value}</Text>,
    },
    {
      align: "center",
      render: (value, { id, name }) => (
        <Popconfirm
          title={`Tem a certeza que quer eliminar ${name} ?`}
          onConfirm={() => handleDelete(id)}
          okText="Sim"
          cancelText="Não"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];
  const mappedColumns = columns.map((col) => {
    if (!col.editable) return col;

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  const downloadFile = () => {
    json2excel({
      data: fertilizersData.map(({ name, n, p, k }) => ({
        Nome: name,
        N: n,
        P: p,
        K: k,
      })),
      name: "Fertilizantes",
    });
  };

  return (
    <>
      <Modal
        title="Novo fertilizante"
        visible={formModalVisible}
        onCancel={() => toggleFormModal()}
        footer={null}
      >
        <ManageFertilizerForm afterSubmit={() => toggleFormModal()} />
      </Modal>

      <Modal
        title="Carregar ficheiro CSV"
        visible={csvModalVisible}
        onCancel={() => toggleCsvModal()}
        footer={null}
      >
        <ManageFertilizerCsvUpload afterSubmit={() => toggleCsvModal()} />
      </Modal>

      <PageHeader
        title="Fertilizantes"
        avatar={{ src: FERTILIZERS_ICON }}
        extra={[
          <Button {...newButtonProps} onClick={() => toggleFormModal()}>
            Novo fertilizante
          </Button>,
          <Button {...csvButtonProps} onClick={() => toggleCsvModal()}>
            Carregar CSV
          </Button>,
          <Button
            icon={<CloudDownloadOutlined />}
            key="download-csv"
            onClick={downloadFile}
          >
            Exportar CSV
          </Button>,
        ]}
      />

      {error && <ResultError />}

      <Skeleton loading={!error && !fertilizersData} active>
        {fertilizersData && (
          <Table
            components={EditableCell.Components}
            columns={mappedColumns}
            dataSource={fertilizersData}
            rowKey={({ id }) => id}
            rowClassName={() => "editable-row"}
            {...tableProps}
          />
        )}
      </Skeleton>
    </>
  );
}

export default ManageFertilizerTable;
