import { gql } from 'apollo-boost'

import useMutation, { mutationTypes } from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation(
    $id: String!
  ) {
    deleteOneProducer(
      where: {
        id: $id
      }
    ) {
      id
    }
  }
`

export default () => (
  useMutation(MUTATION, `Produtor eliminado`, mutationTypes.DELETE)
)

