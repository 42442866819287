import React, { useState, useEffect } from "react";
import { PageHeader, Button, Table, Skeleton, Modal, Popconfirm } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import { CAMPAIGNS_ICON } from "@constants/IconsConstants";
import { CAMPAIGNS_PATH } from "@constants/RoutesConstants";
import { getCampaignsQuery, getCulturesQuery } from "@graphql/queries";
import { useUpdateCampaign, useDeleteCampaign } from "@graphql/mutations";
import { useColumnFilterProps } from "@utils/columnFilter";
import ResultError from "@packages/ResultError";

import EditableCell from "../../EditableCell";
import ManageCampaignForm from "../ManageCampaignForm";

const tableProps = {
  size: "middle",
  pagination: false,
};
const buttonProps = {
  type: "primary",
  icon: <PlusOutlined />,
  key: "newCampaign",
};

function ManageCampaignTable() {
  const [campaignsData, setCampaignsData] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { error, data } = useQuery(getCampaignsQuery);
  const { data: culturesData } = useQuery(getCulturesQuery);
  const [updateCampaign] = useUpdateCampaign();
  const [deleteCampaign] = useDeleteCampaign();
  const useFilterField = (field) => useColumnFilterProps(field);
  const getDetailsPath = (id) => `${CAMPAIGNS_PATH}/${id}/details`;
  const toggleModal = () => setModalVisible(!modalVisible);

  useEffect(() => {
    if (data) setCampaignsData(data.campaigns);
  }, [data]);

  const handleSave = (data) => {
    const { id, year, culture } = data;
    const newData = [...campaignsData];
    const index = newData.findIndex((item) => item.id === data.id);
    const item = newData[index];

    newData.splice(index, 1, { ...item, ...data });

    if (JSON.stringify(newData) !== JSON.stringify(campaignsData)) {
      updateCampaign({
        variables: {
          id,
          data: {
            year,
            culture: {
              connect: { id: culture?.id ?? culture },
            },
          },
        },
        refetchQueries: ["GetCampaignsQuery"],
      });

      setCampaignsData(newData);
    }
  };

  const handleDelete = (id) => {
    deleteCampaign({
      variables: { id },
      refetchQueries: ["GetCampaignsQuery"],
    });
  };
  const columns = [
    {
      dataIndex: "culture",
      title: "Cultura",
      editable: true,
      inputType: `select`,
      inputData: culturesData?.cultures,
      render: (value) => value?.name,
    },
    {
      ...useFilterField("year"),
      dataIndex: "year",
      title: "Ano",
      editable: true,
    },
    {
      title: "Gerir",
      render: (value, { id }) => <Link to={getDetailsPath(id)}>Gerir campanha</Link>,
    },
    {
      align: "center",
      render: (value, { id, culture, year }) => (
        <Popconfirm
          title={`Tem a certeza que quer eliminar ${culture.name} ${year} ?`}
          onConfirm={() => handleDelete(id)}
          okText="Sim"
          cancelText="Não"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];
  const mappedColumns = columns.map((col) => {
    if (!col.editable) return col;

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        inputType: col.inputType,
        inputData: col.inputData,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <>
      <Modal title="Nova campanha" visible={modalVisible} onCancel={() => toggleModal()} footer={null}>
        <ManageCampaignForm afterSubmit={() => toggleModal()} />
      </Modal>

      <PageHeader
        title="Campanhas"
        avatar={{ src: CAMPAIGNS_ICON }}
        extra={[
          <Button {...buttonProps} onClick={() => toggleModal()}>
            Nova campanha
          </Button>,
        ]}
      />

      {error && <ResultError />}

      <Skeleton loading={!error && !campaignsData} active>
        {campaignsData && (
          <Table
            components={EditableCell.Components}
            columns={mappedColumns}
            dataSource={campaignsData}
            rowKey={({ id }) => id}
            rowClassName={() => "editable-row"}
            {...tableProps}
          />
        )}
      </Skeleton>
    </>
  );
}

export default ManageCampaignTable;
