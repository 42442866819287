import React, { useState, useEffect } from 'react'
import { PageHeader, Button, Table, Skeleton, Popconfirm, Modal } from 'antd'
import { useQuery } from '@apollo/react-hooks'
import { PlusOutlined, DeleteOutlined } from '@ant-design/icons'

import ResultError from '@packages/ResultError'
import { PHENOLOGICAL_STATES_ICON } from '@constants/IconsConstants'
import { useColumnFilterProps } from '@utils/columnFilter'
import { getPhenologicalStatesQuery } from '@graphql/queries'
import { useUpdatePhenologicalState, useDeletePhenologicalState } from '@graphql/mutations'

import EditableCell from '../../EditableCell'
import ManagePhenologicalStateForm from '../ManagePhenologicalStateForm'

const tableProps = {
  size: 'middle',
  pagination: false,
}
const newButtonProps = {
  type: 'primary',
  icon: <PlusOutlined />,
  key: 'newPhenologicalState',
}

function ManagePhenologicalStateTable() {
  const [phenologicalStatesData, setPhenologicalStatesData] = useState(false)
  const [formModalVisible, setFormModalVisible] = useState(false)
  const { error, data } = useQuery(getPhenologicalStatesQuery)
  const [updatePhenologicalState] = useUpdatePhenologicalState()
  const [deletePhenologicalState] = useDeletePhenologicalState()
  const useFilterField = field => useColumnFilterProps(field)
  const toggleFormModal = () => setFormModalVisible(!formModalVisible)

  useEffect(() => {
    if (data) setPhenologicalStatesData(data.phenologicalStates)
  }, [data])

  const handleSave = data => {
    const { id, name } = data
    const newData = [...phenologicalStatesData]
    const index = newData.findIndex(item => item.id === data.id)
    const item = newData[index]

    newData.splice(index, 1, { ...item, ...data })

    if (JSON.stringify(newData) !== JSON.stringify(phenologicalStatesData)) {
      updatePhenologicalState({
        variables: {
          id,
          data: {
            name: { set: name },
          },
        },
      })

      setPhenologicalStatesData(newData)
    }
  }

  const handleDelete = id => {
    deletePhenologicalState({
      variables: { id },
      refetchQueries: ['GetPhenologicalStatesQuery'],
    })
  }
  const columns = [
    {
      dataIndex: 'name',
      title: 'Nome',
      editable: true,
      ...useFilterField('name'),
    },
    {
      align: 'center',
      render: (value, { id, name }) => (
        <Popconfirm
          title={`Tem a certeza que quer eliminar ${name} ?`}
          onConfirm={() => handleDelete(id)}
          okText="Sim"
          cancelText="Não"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ]
  const mappedColumns = columns.map(col => {
    if (!col.editable) return col

    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    }
  })

  return (
    <>
      <Modal
        title="Novo tipo de aplicação"
        visible={formModalVisible}
        onCancel={() => toggleFormModal()}
        footer={null}
      >
        <ManagePhenologicalStateForm afterSubmit={() => toggleFormModal()} />
      </Modal>

      <PageHeader
        title="Estados Fenológicos"
        avatar={{ src: PHENOLOGICAL_STATES_ICON }}
        extra={[
          <Button {...newButtonProps} onClick={() => toggleFormModal()}>
            Novo estado fenológico
          </Button>,
        ]}
      />

      {error && <ResultError />}

      <Skeleton loading={!error && !phenologicalStatesData} active>
        {phenologicalStatesData && (
          <Table
            components={EditableCell.Components}
            columns={mappedColumns}
            dataSource={phenologicalStatesData}
            rowKey={({ id }) => id}
            rowClassName={() => 'editable-row'}
            {...tableProps}
          />
        )}
      </Skeleton>
    </>
  )
}

export default ManagePhenologicalStateTable
