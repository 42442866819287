import { gql } from 'apollo-boost'

import useMutation, { mutationTypes } from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation(
    $id: String!
    $data: ProducerUpdateInput!
  ) {
    updateOneProducer(
      where: {
        id: $id
      }
      data: $data
    ) {
      name
    }
  }
`

export default () => (
  useMutation(MUTATION, `Produtor actualizado`, mutationTypes.UPDATE)
)

