import { gql } from 'apollo-boost'

import useMutation, { mutationTypes } from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation(
    $portion: String!
    $id: String!
    $data: ParcelUpdateWithoutPortionInput!
  ) {
    updateOnePortion(
      where: {
        id: $portion
      }
      data: {
        parcels: {
          update: {
            where: {
              id: $id
            }
            data: $data
          }
        }
      }
    ) {
      id
      parcels {
        id
        number
        area
        history(
          take: 3,
          orderBy: {
            year: desc
          }
        ) {
          id
          year
          culture {
            id
            name
          }
        }
      }
    }
  }
`

export default () => (
  useMutation(MUTATION, `Parcelário actualizado`, mutationTypes.UPDATE)
)
