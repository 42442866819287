export const trim = value => value.trimStart().trimEnd()

export const camelize = text => {
  // eslint-disable-next-line no-confusing-arrow
  const txt = text.trim().replace(/[-_\s.]+(.)?/g, (_, c) => c ? c.toUpperCase() : '')

  return txt.substr(0, 1).toLowerCase() + txt.substr(1)
}

export const normalize = value => value.normalize(`NFD`).replace(/[\u0300-\u036f]/g, ``)
