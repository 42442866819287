import { gql } from "apollo-boost";

import useMutation, { mutationTypes } from "@utils/graphqlUtils";

const MUTATION = gql`
  mutation(
    $id: String!
    $data: VisitReportUpdateInput!
  ) {
    updateOneVisitReport(
      where: { id: $id }
      data: $data
    ) {
      id
    }
  }
`;

export default () => (
  useMutation(MUTATION, `Relatório de visita actualizado`, mutationTypes.UPDATE)
);

