import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Select,
  Form,
  Button,
  InputNumber,
  Skeleton,
  DatePicker,
} from "antd";
import { arrayOf, shape, string, number, func } from "prop-types";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";

import {
  getFieldbookQuery,
  getPhenologicalStatesQuery,
} from "@graphql/queries";
import { useCreateWatering, useUpdateWatering } from "@graphql/mutations";

const { Option } = Select;

function FieldbookWateringForm({ data, afterSubmit, editableData }) {
  const [form] = Form.useForm();
  const [createWatering, { loading }] = useCreateWatering();
  const [updateWatering, { loading: updateLoading }] = useUpdateWatering();
  const [editMode, setEditMode] = useState(false);
  const { loading: loadingPhenologicalStates, data: phenologicalStatesData } = useQuery(getPhenologicalStatesQuery);
  const { id } = data;

  const onFinish = fields => {
    const { phenologicalState, hours, volume, startDate, endDate } = fields;
    const mutation = editMode ? updateWatering : createWatering;
    const refetchQueries = [
      {
        query: getFieldbookQuery,
        variables: { id },
      },
    ].filter(Boolean);

    mutation({
      variables: {
        ...(editMode && editableData && { id: editableData.id }),
        data: {
          fieldbook: {
            connect: { id },
          },
          phenologicalState: {
            connect: { id: phenologicalState },
          },
          hours: editMode ? { set: Number(hours) } : Number(hours),
          volume: editMode ? { set: Number(volume) } : Number(volume),
          startDate: editMode ? { set: startDate } : startDate,
          endDate: editMode ? { set: endDate } : endDate,
        },
      },
      refetchQueries,
    }).then(() => {
      afterSubmit();
      form.resetFields();
    });
  };

  useEffect(() => {
    setEditMode(false);

    if (editableData) {
      const { phenologicalState, hours, volume, startDate, endDate, ...rest } = editableData;

      form.setFieldsValue({
        phenologicalState: phenologicalState.id,
        hours: parseFloat(hours),
        volume: parseFloat(volume),
        startDate: moment(startDate),
        endDate: moment(endDate),
        ...rest,
      });

      setEditMode(true);
    }
  }, [editableData, form]);

  const calculateVolumeValue = () => {
    const { hours } = form.getFieldsValue(["hours"]);

    return form.setFieldsValue({ volume: (hours * 33 * 0.8).toFixed(2) });
  };

  return (
    <>
      <Form onFinish={onFinish} form={form}>
        <Skeleton loading={loadingPhenologicalStates} active>
          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="startDate"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <DatePicker
                  placeholder="Início"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="endDate"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <DatePicker
                  placeholder="Fim"
                  size="large"
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="phenologicalState"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <Select
                  placeholder="Escolha um estado fenológico"
                  size="large"
                  optionFilterProp="children"
                  showSearch
                  loading={loadingPhenologicalStates}
                >
                  {phenologicalStatesData?.phenologicalStates.map(
                    ({ id, name }) => (
                      <Option value={id} key={id}>
                        {name}
                      </Option>
                    )
                  )}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="hours"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <InputNumber
                  placeholder="Horas de Rega"
                  size="large"
                  style={{ width: "100%" }}
                  onChange={calculateVolumeValue}
                />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="volume"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <InputNumber
                  placeholder="Volume de Água"
                  size="large"
                  style={{ width: "100%" }}
                  disabled
                />
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ textAlign: "right" }}>
            <Col span={24}>
              <Button
                htmlType="submit"
                size="large"
                type="primary"
                disabled={loading || updateLoading}
              >
                {editMode ? "Actualizar" : "Criar"} rega
              </Button>
            </Col>
          </Row>
        </Skeleton>
      </Form>
    </>
  );
}

FieldbookWateringForm.propTypes = {
  data: shape({
    campaign: shape({
      culture: shape({
        varieties: arrayOf(
          shape({
            id: string,
            name: string,
            cicle: number,
          }),
        ),
      }),
    }),
  }).isRequired,
  editableData: shape({}),
  afterSubmit: func.isRequired,
};

FieldbookWateringForm.defaultProps = {
  editableData: null,
};

export default FieldbookWateringForm;
