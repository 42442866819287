import React from "react";
import {
  Table,
  TableHeader,
  TableBody,
  DataTableCell,
} from "@david.kucsai/react-pdf-table";
import { arrayOf, shape, func } from "prop-types";

import { formatDate } from "@utils/dateUtils";

import { cellStyle } from "./style";

function Installations({ data, cell: Cell }) {
  return (
    <Table data={data}>
      <TableHeader>
        <Cell>Data</Cell>
        <Cell>Variedade</Cell>
        <Cell>Área (ha)</Cell>
        <Cell>Sem. Plantação</Cell>
        <Cell>Dens. (n plantas / ha)</Cell>
        <Cell>Lote Sem.</Cell>
        <Cell>Fornecedor</Cell>
        <Cell>Culturas Adjacentes</Cell>
        <Cell>Data Colheita</Cell>
      </TableHeader>
      <TableBody>
        <DataTableCell
          style={cellStyle}
          getContent={({ date }) => formatDate(date)}
        />
        <DataTableCell
          style={cellStyle}
          getContent={({ variety }) => variety?.name}
        />
        <DataTableCell style={cellStyle} getContent={({ area }) => area} />
        <DataTableCell
          style={cellStyle}
          getContent={({ plantingWeeks }) => plantingWeeks}
        />
        <DataTableCell
          style={cellStyle}
          getContent={({ density }) => density}
        />
        <DataTableCell
          style={cellStyle}
          getContent={({ seedLot }) => seedLot}
        />
        <DataTableCell
          style={cellStyle}
          getContent={({ provider }) => provider?.name}
        />
        <DataTableCell
          style={cellStyle}
          getContent={({ adjacentCrops }) => adjacentCrops}
        />
        <DataTableCell
          style={cellStyle}
          getContent={({ harvestDate }) => formatDate(harvestDate)}
        />
      </TableBody>
    </Table>
  );
}

Installations.propTypes = {
  data: arrayOf(shape({})),
  cell: func.isRequired,
};

Installations.defaultProps = {
  data: [],
};

export default Installations;
