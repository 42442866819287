import { gql } from "apollo-boost";

import useMutation, { mutationTypes } from "@utils/graphqlUtils";

const MUTATION = gql`
  mutation($id: String!) {
    deleteOneVisitReport(
      where: { id: $id }
    ) {
      id
    }
  }
`;

export default () => (
  useMutation(MUTATION, `Relatório de visita eliminado`, mutationTypes.DELETE)
);

