import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { Skeleton, Divider, Alert, Table, Row, Col, Button } from "antd";
import { arrayOf, string, func } from "prop-types";

import {
  getFertilizationsHistoricQuery,
  getFieldbookQuery,
} from "@graphql/queries";
import { useCreateFertilization } from "@graphql/mutations";
import { formatDate } from "@utils/dateUtils";

const tableProps = {
  size: "middle",
  pagination: false,
};

function FieldbookFertilizationsHistoric({
  fieldbookId,
  producersIds,
  afterSubmit,
}) {
  const [createFertilization] = useCreateFertilization();
  const mappedIds = producersIds.map((id) => id);
  const [selectedRows, setSelectedRows] = useState([]);
  const queryVars = { variables: { where: mappedIds } };
  const { data, error, loading } = useQuery(getFertilizationsHistoricQuery, {
    ...queryVars,
  });
  const rowSelection = {
    onChange: (_, selectedRows) => setSelectedRows(selectedRows),
  };
  const columns = [
    {
      dataIndex: "date",
      title: "Data",
      render: formatDate,
    },
    {
      dataIndex: "fertilizer",
      title: "Fertilizante",
      render: (value) => value?.name,
    },
    {
      dataIndex: "provider",
      title: "Fornecedor",
      render: (value) => value?.name,
    },
    {
      dataIndex: "dose",
      title: "Dose",
      render: (value, data) => `${value} ${data?.unity?.name}`,
    },
    {
      dataIndex: "applicationType",
      title: "Tipo de Aplicação",
      render: (value) => value?.name,
    },
  ];

  const createMultiplePesticides = () => {
    selectedRows.map((fields) => {
      const { fertilizer, date, provider, dose, unity, applicationType } =
        fields;
      const refetchQueries = [
        {
          query: getFieldbookQuery,
          variables: { id: fieldbookId },
        },
      ].filter(Boolean);

      return createFertilization({
        variables: {
          data: {
            fieldbook: {
              connect: { id: fieldbookId },
            },
            fertilizer: {
              connect: { id: fertilizer.id },
            },
            provider: {
              connect: { id: provider.id },
            },
            unity: {
              connect: { id: unity.id },
            },
            applicationType: {
              connect: { id: applicationType.id },
            },
            dose,
            date,
          },
        },
        refetchQueries,
      }).then(() => afterSubmit());
    });
  };

  if (loading) return <Skeleton />;
  if (error) {
    return (
      <Alert
        message="Algo correu mal"
        description={
          <pre>
            <code>{JSON.stringify(error, null, 2)}</code>
          </pre>
        }
        type="error"
        showIcon
      />
    );
  }

  return (
    <>
      {data?.fieldbooks.map(({ id, portion, fertilizations }) => (
        <div key={id}>
          <Divider>Parcela: {portion.name}</Divider>

          <Table
            columns={columns}
            dataSource={fertilizations}
            rowKey={({ id }) => id}
            pagination={false}
            rowSelection={{
              ...rowSelection,
            }}
            {...tableProps}
          />
        </div>
      ))}

      <Row style={{ marginTop: 30, textAlign: "center" }}>
        <Col span={24}>
          <Button
            type="primary"
            disabled={selectedRows.length < 1}
            onClick={createMultiplePesticides}
          >
            {selectedRows.length > 0
              ? `Copiar ${selectedRows.length} fertilizações`
              : `Selecione fertilizações`}
          </Button>
        </Col>
      </Row>
    </>
  );
}

FieldbookFertilizationsHistoric.propTypes = {
  fieldbookId: string.isRequired,
  producersIds: arrayOf(string).isRequired,
  afterSubmit: func.isRequired,
};

export default FieldbookFertilizationsHistoric;
