import React, { useState, useEffect } from "react";
import { PageHeader, Affix, Button, Alert, Modal } from "antd";
import {
  BookOutlined,
  InboxOutlined,
  LockOutlined,
  UnlockOutlined,
  DownloadOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { Link, useHistory } from "react-router-dom";
import { PDFDownloadLink } from "@react-pdf/renderer";
import Helmet from "react-helmet";
import { shape, arrayOf, string } from "prop-types";

import { getFieldbookQuery } from "@graphql/queries";
import { useUpdateFieldbook } from "@graphql/mutations";
import { PORTIONS_PATH, FIELDBOOKS_PATH } from "@constants/RoutesConstants";
import { FIELDBOOKS_ICON } from "@constants/IconsConstants";
import { OWNER } from "@constants/AppConstants";

import FieldbookPdf from "../FieldbookPdf";
import FieldbookArchive from "../FieldbookArchive";
import FieldbookForm from "../FieldbookForm";

function FieldbookHeader({ fieldbook }) {
  const [generatePdf, setGeneratePdf] = useState();
  const [wait, setWait] = useState(false);
  const { push, goBack } = useHistory();
  const [confirmArchive, setConfirmArchive] = useState(false);
  const [editFieldbook, setEditFieldbook] = useState(false);
  const getPortionPath = (id) => `${PORTIONS_PATH}/${id}/details`;
  const { campaign, portion, isArchived } = fieldbook;
  const toggleModal = () => setConfirmArchive(!confirmArchive);
  const [updateFieldbook, { loading }] = useUpdateFieldbook();

  const unarchiveFieldbook = () => {
    updateFieldbook({
      variables: {
        id: fieldbook.id,
        data: {
          isArchived: { set: false },
        },
      },
      refetchQueries: [
        {
          query: getFieldbookQuery,
          variables: { id: fieldbook.id },
        },
      ],
    }).then(() => push(`${FIELDBOOKS_PATH}/${fieldbook.id}/installations`));
  };

  useEffect(() => {
    if (generatePdf) {
      setTimeout(() => setWait(false), 3000);
    }
  }, [generatePdf]);

  return (
    <>
      <Helmet>
        <title>{`${portion.name} ∙ ${OWNER}`}</title>
      </Helmet>

      <FieldbookArchive fieldbook={fieldbook} toggleModal={toggleModal} visible={confirmArchive} />

      <Modal
        title="Editar detalhes de caderno de campo"
        visible={editFieldbook}
        onCancel={() => setEditFieldbook(false)}
        footer={null}
        width={960}
      >
        <FieldbookForm
          campaignId={fieldbook?.campaign?.id}
          afterSubmit={() => setEditFieldbook(false)}
          editableData={fieldbook}
        />
      </Modal>

      <Affix offsetTop={10}>
        {isArchived && (
          <Alert
            message="Este caderno de campo encontra-se arquivado."
            type="warning"
            icon={<LockOutlined />}
            style={{ marginBottom: 15 }}
            showIcon
          />
        )}
        <PageHeader
          title={<Link to={getPortionPath(portion.id)}>{portion.name}</Link>}
          subTitle="Caderno de Campo"
          onBack={() => goBack()}
          avatar={{ src: FIELDBOOKS_ICON }}
          style={{
            background: `#fff`,
            boxShadow: `0 3px 2px rgba(0, 0, 0, .05)`,
          }}
          extra={[
            !fieldbook?.isArchived ? (
              <Button onClick={() => setEditFieldbook(!editFieldbook)} icon={<EditOutlined />} key="editFieldbook">
                Editar
              </Button>
            ) : null,
            generatePdf && !wait ? (
              <PDFDownloadLink
                document={<FieldbookPdf data={fieldbook} />}
                fileName={`${campaign.culture.name}.${campaign.year}.pdf`}
                key="downloadPdf"
              >
                <Button type="primary" icon={<DownloadOutlined />} onClick={() => setGeneratePdf(!generatePdf)}>
                  Descarregar caderno
                </Button>
              </PDFDownloadLink>
            ) : (
              <Button
                disabled={wait}
                loading={wait}
                key="generatePdf"
                icon={<BookOutlined />}
                onClick={() => {
                  setWait(true);
                  setGeneratePdf(!generatePdf);
                }}
              >
                {wait ? "Aguarde ..." : "Gerar caderno de campo"}
              </Button>
            ),
            !fieldbook.isArchived ? (
              <Button type="danger" onClick={toggleModal} icon={<InboxOutlined />} key="archiveFieldbook">
                Arquivar
              </Button>
            ) : (
              <Button
                disabled={loading}
                loading={loading}
                onClick={unarchiveFieldbook}
                icon={<UnlockOutlined />}
                key="unarchiveFieldbook"
              >
                {loading ? "Aguarde ..." : "Desbloquear"}
              </Button>
            ),
          ]}
        />
      </Affix>

      {/* <PDFViewer style={{ width: "100%", height: 540 }}>
        <FieldbookPdf data={fieldbook} />
      </PDFViewer> */}
    </>
  );
}

FieldbookHeader.propTypes = {
  fieldbook: shape({
    id: string,
    campaign: shape({
      id: string,
      culture: shape({
        id: string,
        name: string,
        year: string,
      }),
    }).isRequired,
    producers: arrayOf(
      shape({
        id: string,
        name: string,
      }),
    ).isRequired,
    portion: shape({
      id: string,
      markers: arrayOf(shape({})),
    }).isRequired,
  }).isRequired,
};

export default FieldbookHeader;
