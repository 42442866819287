import React from 'react'
import { BrowserRouter as Router, Route, Switch, Redirect } from 'react-router-dom'

import * as routes from '@constants/RoutesConstants'

import Routes from '../Routes'
import Login from '../Login'
import PrivateRoute from '../PrivateRoute'

const App = () => (
  <div className="app">
    <Router>
      <Switch>
        <Route path={routes.LOGIN_PATH} component={Login} exact />

        {Routes.map(({ path, component: Component, exact }) => (
          <PrivateRoute key={path} path={path} component={Component} exact={exact} />
        ))}

        {/* Redirect should be kept at the end of all routes */}
        <Redirect from={routes.ROOT_PATH} to={routes.CAMPAIGNS_PATH} />
      </Switch>
    </Router>
  </div>
)

export default App
