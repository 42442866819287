import React from 'react'
import { Form, Input, Button } from 'antd'
import { func } from 'prop-types'

import { useCreateProducer } from '@graphql/mutations'

function ManageProducerForm({ afterSubmit }) {
  const [form] = Form.useForm()
  const [createProducer, { loading }] = useCreateProducer()

  const onFinish = data => {
    createProducer({
      variables: {
        data: { ...data, number: parseInt(data.number, 0) },
      },
      refetchQueries: ['GetProducersQuery'],
    }).then(() => afterSubmit(), form.resetFields())
  }

  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: `Campo obrigatório`,
          },
        ]}
        hasFeedback
      >
        <Input placeholder="Nome do produtor" size="large" autoFocus />
      </Form.Item>

      <Form.Item
        name="number"
        rules={[
          {
            required: true,
            message: `Campo obrigatório`,
          },
        ]}
        hasFeedback
      >
        <Input placeholder="Número do produtor" size="large" />
      </Form.Item>

      <Form.Item name="vatNumber">
        <Input placeholder="Nº contribuinte" size="large" />
      </Form.Item>

      <Form.Item name="nifap">
        <Input placeholder="NIFAP" size="large" />
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        size="large"
        disabled={loading}
        block
      >
        Criar produtor
      </Button>
    </Form>
  )
}

ManageProducerForm.propTypes = {
  afterSubmit: func,
}

ManageProducerForm.defaultProps = {
  afterSubmit: null,
}

export default ManageProducerForm
