import React, { useState, useEffect } from 'react'
import { Row, Col, Input, Button, Form, Alert } from 'antd'
import Helmet from 'react-helmet'
import jwt from 'jsonwebtoken'

import { CAMPAIGNS_PATH } from '@constants/RoutesConstants'
import { JWT_TOKEN, AUTH_TOKEN } from '@constants/AuthConstants'
import { OWNER } from '@constants/AppConstants'

function Login() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState()

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const authToken = localStorage.getItem(JWT_TOKEN)

    if (authToken) return global.location.assign(CAMPAIGNS_PATH)
  }, [])

  const handleSubmit = ({ username, password }) => {
    setLoading(true)
    setError()

    if (
      username === process.env.REACT_APP_ADMIN_USERNAME
      && password === process.env.REACT_APP_ADMIN_PASSWORD
    ) {
      const randomToken = Math.random().toString(36).substring(2) + Date.now().toString(36)
      const token = jwt.sign({ token: randomToken }, process.env.REACT_APP_JWT_SECRET)

      if (token) {
        localStorage.setItem(AUTH_TOKEN, randomToken)
        localStorage.setItem(JWT_TOKEN, token)

        return global.location.assign(CAMPAIGNS_PATH)
      }
    }

    setError('Utilizador e/ou password incorrectos.')
    setLoading(false)

    return password
  }

  return (
    <>
      <Helmet>
        <title>Login · {OWNER}</title>
      </Helmet>

      <div className="login-logo">
        {OWNER}
      </div>

      <Form onFinish={handleSubmit}>
        <Row justify="center">
          <Col md={6}>
            {error && (
              <>
                <Alert message={error} type="error" showIcon />
                <br />
              </>
            )}

            <Form.Item
              name="username"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <Input
                placeholder="Utilizador"
                size="large"
                disabled={loading}
              />
            </Form.Item>

            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <Input.Password
                placeholder="Password"
                size="large"
                disabled={loading}
              />
            </Form.Item>

            <Form.Item>
              <Button
                htmlType="submit"
                size="large"
                type="primary"
                loading={loading}
                disabled={loading}
                block
              >
                {loading ? `Aguarde ...` : `Entrar`}
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </>
  )
}

export default Login
