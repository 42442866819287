import React, { useState, useEffect } from 'react'
import { Form, Upload, Result, Button } from 'antd'
import { InboxOutlined } from '@ant-design/icons'
import { csv } from 'csvtojson'
import { func } from 'prop-types'
import { useQuery } from '@apollo/react-hooks'

import { camelize, normalize } from '@utils/textUtils'
import { useCreatePortion } from '@graphql/mutations'
import { getCulturesQuery } from '@graphql/queries'

const options = {
  trim: true,
  ignoreEmpty: true,
  delimiter: ';',
}

function ManagePortionCsvUpload({ afterSubmit }) {
  const [data, setData] = useState()
  const [uploadComplete, setUploadComplete] = useState(false)
  const [createPortion, { loading }] = useCreatePortion()
  const { data: culturesData } = useQuery(getCulturesQuery)

  const normFile = e => {
    if (Array.isArray(e)) return e

    return e && e.fileList
  }

  useEffect(() => {
    const createAllPortions = data => {
      const uniquePortions = [...new Map(data.map(item => [item.nomeDaParcela, item])).values()]

      Array.from(uniquePortions).map(async portion => {
        // Get parcels
        const parcels = data.filter(d => d.nomeDaParcela === portion.nomeDaParcela)
        const toCreate = parcels.map(p => {
          const y2019 = p['2019'] && culturesData?.cultures?.filter(({ name }) => name === p['2019'].trim())[0]
          const y2018 = p['2018'] && culturesData?.cultures?.filter(({ name }) => name === p['2018'].trim())[0]
          const y2017 = p['2017'] && culturesData?.cultures?.filter(({ name }) => name === p['2017'].trim())[0]

          return ({
            number: p.nParcela,
            area: parseFloat(p.areaUtilDaParcela),
            history: {
              create: [
                y2019 && { year: `2019`, culture: { connect: { id: y2019.id } } },
                y2018 && { year: `2018`, culture: { connect: { id: y2018.id } } },
                y2017 && { year: `2017`, culture: { connect: { id: y2017.id } } },
              ],
            },
          })
        })

        await createPortion({
          variables: {
            data: {
              name: portion.nomeDaParcela,
              location: portion.descricaoConcelhoParcela,
              identification: portion.identificacaoParcelar,
              parcels: {
                create: toCreate,
              },
            },
          },
        })
      })

      setUploadComplete(true)
    }

    if (data) createAllPortions(data)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, createPortion])

  const handleUpload = () => {}
  const props = {
    accept: `.txt, .csv, .xlsx, .xls`,
    name: `files`,
    beforeUpload: file => {
      const reader = new FileReader()

      reader.onload = e => {
        csv(options)
          .fromString(e.target.result)
          .preFileLine((fileLineString, lineIdx) => {
            if (lineIdx === 0) {
              return normalize(
                camelize(fileLineString.toLowerCase()),
              )
            }

            return fileLineString
          })
          .then(data => setData(data))
      }

      reader.readAsText(file)

      return false
    },
  }

  if (uploadComplete) {
    return (
      <Result
        status="success"
        title="Produtores importados"
        subTitle="Pode fechar esta caixa."
        extra={[
          <Button type="primary" key="close" onClick={() => afterSubmit()}>
            Fechar
          </Button>,
        ]}
      />
    )
  }

  return (
    <Form onFinish={handleUpload}>
      <Form.Item>
        {!loading ? (
          <Form.Item name="dragger" valuePropName="fileList" getValueFromEvent={normFile} noStyle>
            <Upload.Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">
                Clique ou arraste uma ficheiro para efectuar o upload.
              </p>
            </Upload.Dragger>
          </Form.Item>
        ) : `Loading ...`}
      </Form.Item>
    </Form>
  )
}

ManagePortionCsvUpload.propTypes = {
  afterSubmit: func.isRequired,
}

export default ManagePortionCsvUpload
