import React from 'react'
import ReactDOM from 'react-dom'
import { ConfigProvider } from 'antd'
import { ApolloProvider } from '@apollo/react-hooks'
import ApolloClient from 'apollo-boost'
import ptPT from 'antd/es/locale/pt_PT'

import { JWT_TOKEN } from '@constants/AuthConstants'

import './styles/base.scss'
import App from './components/App'

const client = new ApolloClient({
  uri: process.env.REACT_APP_API_ENDPOINT,
  request: operation => {
    const token = localStorage.getItem(JWT_TOKEN)

    operation.setContext({
      headers: {
        authorization: token ? `Bearer ${token}` : '',
      },
    })
  },
})

ReactDOM.render(
  <ApolloProvider client={client}>
    <ConfigProvider locale={ptPT}>
      <App />
    </ConfigProvider>
  </ApolloProvider>,
  document.getElementById('root'),
)
