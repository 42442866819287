import { gql } from 'apollo-boost'

import useMutation, { mutationTypes } from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation(
    $culture: String!
    $id: String!
    $data: VarietyUpdateWithoutCultureInput!
  ) {
    updateOneCulture(
      where: {
        id: $culture
      }
      data: {
        varieties: {
          update: {
            where: {
              id: $id
            }
            data: $data
          }
        }
      }
    ) {
      id
      varieties {
        id
        name
        cicle
      }
    }
  }
`

export default () => (
  useMutation(MUTATION, `Variedade actualizada`, mutationTypes.UPDATE)
)
