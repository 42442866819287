import { Button, Col, Input, Row } from "antd";
import React, { useEffect, useState } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { json2excel } from "js2excel";
import { func } from "prop-types";

import { getProducersByNumberQuery, getPortionsByIdentificationQuery } from "@graphql/queries";

function ManagePortionExportCsv({ afterSubmit }) {
  const [producerNumber, setProducerNumber] = useState(null);
  const [getProducers, { loading: loadingProducers, data: { producers } = {} }] =
    useLazyQuery(getProducersByNumberQuery);
  const [getPortions, { loading: loadingPortions, data: { portions } = {} }] = useLazyQuery(
    getPortionsByIdentificationQuery,
  );

  useEffect(() => {
    if (producers?.length === 0) {
      alert("Nenhum produtor encontrado");

      return;
    }

    async function fetchPortions() {
      await getPortions({
        variables: {
          identification: {
            equals: String(producerNumber),
          },
        },
      });
    }

    fetchPortions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [producers]);

  useEffect(() => {
    if (!portions?.length) return;

    // Gather all parcels
    const parcels = portions?.reduce((acc, curr) => acc.concat(curr.parcels), []);
    let currentPortionName = null;

    json2excel({
      data: parcels?.map((parcel, index) => {
        const portion = portions.find((portion) => portion.id === parcel.portionId);
        const samePortion = currentPortionName === portion.name;

        currentPortionName = portion.name;

        return {
          Produtor: index === 0 ? producerNumber : "",
          Parcela: samePortion ? "" : portion.name,
          Parcelário: parcel.number,
          "Área (ha)": parcel.area,
        };
      }),
      name: `Parcelas de ${producerNumber}`,
    });

    if (afterSubmit) afterSubmit();

    setProducerNumber(null);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [portions]);

  const loading = loadingProducers || loadingPortions;

  return (
    <div>
      <Row gutter={16}>
        <Col span={16}>
          <Input
            placeholder="Número do produtor"
            value={producerNumber}
            onChange={(e) => setProducerNumber(e.target.value)}
            size="large"
          />
        </Col>

        <Col span={8}>
          <Button
            size="large"
            type="primary"
            block
            onClick={() => {
              getProducers({
                variables: {
                  number: {
                    equals: Number(producerNumber),
                  },
                },
              });
            }}
            disabled={loading}
            loading={loading}
          >
            Exportar
          </Button>
        </Col>
      </Row>
    </div>
  );
}

ManagePortionExportCsv.propTypes = {
  afterSubmit: func,
};

ManagePortionExportCsv.defaultProps = {
  afterSubmit: null,
};

export default ManagePortionExportCsv;
