import styled from '@react-pdf/styled-components'

export const CoverPage = styled.Page`
  text-align: center;
  padding-top: 15vh;
`

export const Page = styled.Page`
`

export const Section = styled.View`
  margin: 20px;
`

export const H1 = styled.Text`
  font-size: 48px;
  font-family: Helvetica-Bold;
  color: #454ade;
  letter-spacing: -2;
`
export const H2 = styled.Text`
  font-size: 28px;
  color: #222;
`

export const H3 = styled.Text`
  font-size: 20px;
  color: #222;
`

export const Footer = styled.Text`
  position: absolute;
  font-size: 8px;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  color: grey;
`

export const MapContainer = styled.Image`
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  display: block;
  height: 300px;
  width: 100%;
  margin-top: 10vh;
  border-radius: 6px;
`

export const cellHeadStyle = {
  padding: 4,
  color: '#666',
  fontSize: 5,
  textTransform: 'uppercase',
  fontWeight: 900,
}

export const cellStyle = {
  fontSize: 7,
  padding: 4,
}
