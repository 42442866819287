import { gql } from "apollo-boost";

import useMutation, { mutationTypes } from "@utils/graphqlUtils";

const MUTATION = gql`
  mutation ($data: FieldbookCreateInput!) {
    createOneFieldbook(data: $data) {
      id
    }
  }
`;

export default () => useMutation(MUTATION, `Caderno de campo criado`, mutationTypes.CREATE);
