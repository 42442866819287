import { gql } from 'apollo-boost'

import useMutation, { mutationTypes } from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation(
    $portion: String!
    $id: String!
  ) {
    updateOnePortion(
      where: { id: $portion }
      data: {
        parcels: {
          delete: { id: $id }
        }
      }
    ) {
      parcels {
        id
        number
        area
        history(
          take: 3,
          orderBy: {
            year: desc
          }
        ) {
          id
          year
          culture {
            id
            name
          }
        }
      }
    }
  }
`

export default () => (
  useMutation(MUTATION, `Parcelário eliminado`, mutationTypes.DELETE)
)
