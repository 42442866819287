const STATIC_URL = `https://maps.googleapis.com/maps/api/staticmap`
const MAP_TYPE = `hybrid`

export const getGoogleMapsStaticMap = ({ dimensions, coords, polygons, markers, mapZoom }) => {
  let pathsParams = ``
  let markersParams = ``
  const { lat, lng } = coords
  const CENTER = `center=${lat},${lng}`

  if (polygons) {
    polygons.map(({ paths }) => {
      pathsParams = `${pathsParams}&path=color:blue|weight:2|fillcolor:blue|`

      const mappedPaths = paths.map(({ lat, lng }) => `${lat},${lng}`).join(`|`)

      pathsParams = `${pathsParams}${mappedPaths}`

      return pathsParams
    })
  }

  if (markers) {
    markers.map(({ position }, index) => {
      markersParams = `${markersParams}&markers=color:red|label:${index + 1}|`
      markersParams = `${markersParams}${position.lat},${position.lng}`

      return markersParams
    })
  }

  return (
    `${STATIC_URL}`
    + `?${CENTER}`
    + `&zoom=${mapZoom - 1}`
    + `&size=${dimensions}`
    + `&maptype=${MAP_TYPE}`
    + `${pathsParams}`
    + `${markersParams}`
    + `&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`
  )
}

// http://maps.googleapis.com/maps/api/staticmap?center=33.402285,-111.942715&zoom=20&size=600x600&maptype=satellite&sensor=false&path=color:red|weight:1|fillcolor:white|33.4022475,-111.9426775|33.4022475,-111.9427525|33.4023225,-111.9427525|33.4023225,-111.9426775|33.4022475,-111.9426775
