import React from 'react'
import { LoadingOutlined } from '@ant-design/icons'

import * as S from './style'

function Loading() {
  return (
    <S.Loading>
      <LoadingOutlined />
    </S.Loading>
  )
}

export default Loading
