import { gql } from 'apollo-boost'

import useMutation, { mutationTypes } from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation(
    $culture: String!
    $id: String!
  ) {
    updateOneCulture(
      where: { id: $culture }
      data: {
        varieties: {
          delete: { id: $id }
        }
      }
    ) {
      id
      varieties {
        id
        name
        cicle
      }
    }
  }
`

export default () => (
  useMutation(MUTATION, `Variedade eliminada`, mutationTypes.DELETE)
)
