import { gql } from 'apollo-boost'

import useMutation, { mutationTypes } from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation($id: String!) {
    deleteOneFertilization(
      where: { id: $id }
    ) {
      id
    }
  }
`

export default () => (
  useMutation(MUTATION, `Fertilização eliminada`, mutationTypes.DELETE)
)

