import React, { useState, useEffect } from "react";
import { Row, Col, Select, Form, Button, DatePicker, InputNumber, Modal, Skeleton } from "antd";
import { arrayOf, shape, string, number, func } from "prop-types";
import { PlusCircleOutlined } from "@ant-design/icons";
import { useQuery } from "@apollo/react-hooks";
import moment from "moment";

import {
  getProvidersQuery,
  getUnitiesQuery,
  getFieldbookQuery,
  getFertilizersQuery,
  getApplicationTypesQuery,
} from "@graphql/queries";
import { useCreateFertilization, useUpdateFertilization } from "@graphql/mutations";

import ManageFertilizerForm from "../../../ManageFertilizer/ManageFertilizerForm";
import ManageProviderForm from "../../../ManageProvider/ManageProviderForm";
import ManageApplicationTypeForm from "../../../ManageApplicationType/ManageApplicationTypeForm";

const { Option } = Select;

function FieldbookFertilizationForm({ data, afterSubmit, editableData }) {
  const [form] = Form.useForm();
  const [createFertilization, { loading }] = useCreateFertilization();
  const [updateFertilization, { loading: updateLoading }] = useUpdateFertilization();
  const [newFertilizer, setNewFertilizer] = useState(false);
  const [newProvider, setNewProvider] = useState(false);
  const [newApplicationType, setNewApplicationType] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { loading: loadingProviders, data: providersData } = useQuery(getProvidersQuery);
  const { loading: loadingFertilizers, data: fertilizersData } = useQuery(getFertilizersQuery);
  const { loading: loadingUnities, data: unitiesData } = useQuery(getUnitiesQuery);
  const { loading: loadingApplicationTypes, data: applicationTypesData } = useQuery(getApplicationTypesQuery);
  const { id } = data;

  const onFinish = (fields) => {
    const { fertilizer, date, provider, dose, unity, applicationType } = fields;
    const mutation = editMode ? updateFertilization : createFertilization;
    const refetchQueries = [
      {
        query: getFieldbookQuery,
        variables: { id },
      },
    ].filter(Boolean);

    mutation({
      variables: {
        ...(editMode && editableData && { id: editableData.id }),
        data: {
          fieldbook: {
            connect: { id },
          },
          fertilizer: {
            connect: { id: fertilizer },
          },
          provider: {
            connect: { id: provider },
          },
          unity: {
            connect: { id: unity },
          },
          applicationType: {
            connect: { id: applicationType },
          },
          dose: editMode ? { set: dose } : dose,
          date: editMode ? { set: date } : date,
        },
      },
      refetchQueries,
    }).then(() => {
      setNewFertilizer(false);
      setNewProvider(false);
      afterSubmit();
      form.resetFields();
    });
  };

  useEffect(() => {
    setEditMode(false);
    form.resetFields();

    if (editableData) {
      const { date, fertilizer, provider, unity, applicationType, ...rest } = editableData;

      form.setFieldsValue({
        date: moment(date),
        fertilizer: fertilizer.id,
        provider: provider.id,
        unity: unity.id,
        applicationType: applicationType.id,
        ...rest,
      });

      setEditMode(true);
    }
  }, [editableData, form]);

  return (
    <>
      <Form onFinish={onFinish} form={form}>
        <Skeleton loading={loadingFertilizers || loadingProviders || loadingUnities} active>
          <Row>
            <Col span={24}>
              <Button
                icon={<PlusCircleOutlined />}
                onClick={() => setNewFertilizer(!newFertilizer)}
                style={{ marginRight: 10 }}
              >
                Fertilizante
              </Button>

              <Button
                icon={<PlusCircleOutlined />}
                onClick={() => setNewProvider(!newProvider)}
                style={{ marginRight: 10 }}
              >
                Fornecedor
              </Button>

              <Button icon={<PlusCircleOutlined />} onClick={() => setNewApplicationType(!newApplicationType)}>
                Tipo de Aplicação
              </Button>
            </Col>
          </Row>

          <Row gutter={16} style={{ marginTop: 30 }}>
            <Col span={8}>
              <Modal
                title="Novo produto"
                onCancel={() => setNewFertilizer(false)}
                footer={null}
                visible={newFertilizer}
              >
                <ManageFertilizerForm afterSubmit={() => setNewFertilizer(false)} />
              </Modal>

              <Modal
                title="Novo tipo de aplicação"
                onCancel={() => setNewApplicationType(false)}
                footer={null}
                visible={newApplicationType}
              >
                <ManageApplicationTypeForm afterSubmit={() => setNewApplicationType(false)} />
              </Modal>

              <Form.Item
                name="fertilizer"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <Select
                  placeholder="Escolha um fertilizante"
                  size="large"
                  optionFilterProp="children"
                  showSearch
                  loading={loadingFertilizers}
                >
                  {fertilizersData &&
                    fertilizersData?.fertilizers.map(({ id, name }) => (
                      <Option value={id} key={id}>
                        {name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="date"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <DatePicker placeholder="Data da aplicação" size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              {newProvider && (
                <Modal title="Novo fornecedor" onCancel={() => setNewProvider(false)} footer={null} visible>
                  <ManageProviderForm afterSubmit={() => setNewProvider(false)} />
                </Modal>
              )}

              <Form.Item
                name="provider"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <Select
                  loading={loadingProviders}
                  placeholder="Escolha um fornecedor"
                  size="large"
                  optionFilterProp="children"
                  showSearch
                >
                  {providersData &&
                    providersData.providers.map(({ id, name }) => (
                      <Option value={id} key={id}>
                        {name}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={16}>
            <Col span={8}>
              <Form.Item
                name="dose"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <InputNumber placeholder="Dose" size="large" style={{ width: "100%" }} />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="unity"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <Select
                  loading={loadingUnities}
                  placeholder="Escolha uma unidade"
                  size="large"
                  optionFilterProp="children"
                  showSearch
                >
                  {unitiesData?.unities.map(({ id, name }) => (
                    <Option value={id} key={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                name="applicationType"
                rules={[
                  {
                    required: true,
                    message: `Campo obrigatório`,
                  },
                ]}
                hasFeedback
              >
                <Select
                  placeholder="Tipo de aplicação"
                  size="large"
                  optionFilterProp="children"
                  showSearch
                  loading={loadingApplicationTypes}
                >
                  {applicationTypesData?.applicationTypes.map(({ id, name }) => (
                    <Option value={id} key={id}>
                      {name}
                    </Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          </Row>

          <Row style={{ textAlign: "right" }}>
            <Col span={24}>
              <Button htmlType="submit" size="large" type="primary" disabled={loading || updateLoading}>
                {editMode ? "Actualizar" : "Criar"} fertilização
              </Button>
            </Col>
          </Row>
        </Skeleton>
      </Form>
    </>
  );
}

FieldbookFertilizationForm.propTypes = {
  data: shape({
    campaign: shape({
      culture: shape({
        varieties: arrayOf(
          shape({
            id: string,
            name: string,
            cicle: number,
          }),
        ),
      }),
    }),
  }).isRequired,
  editableData: shape({}),
  afterSubmit: func.isRequired,
};

FieldbookFertilizationForm.defaultProps = {
  editableData: null,
};

export default FieldbookFertilizationForm;
