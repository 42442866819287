import React from 'react'
import { Form, Input, Button } from 'antd'
import { func } from 'prop-types'

import { useCreateActiveSubstanceFamily } from '@graphql/mutations'

function ManageActiveSubstanceFamilyForm({ afterSubmit }) {
  const [form] = Form.useForm()
  const [createActiveSubstanceFamily, { loading }] = useCreateActiveSubstanceFamily()

  const onFinish = data => {
    createActiveSubstanceFamily({
      variables: {
        data: { ...data },
      },
      refetchQueries: ['GetActiveSubstanceFamiliesQuery'],
    }).then(callback => afterSubmit(callback), form.resetFields())
  }

  return (
    <Form onFinish={onFinish} form={form}>
      <Form.Item
        name="name"
        rules={[
          {
            required: true,
            message: `Campo obrigatório`,
          },
        ]}
        hasFeedback
      >
        <Input placeholder="Nome da familía" size="large" autoFocus />
      </Form.Item>

      <Button
        type="primary"
        htmlType="submit"
        size="large"
        disabled={loading}
        block
      >
        Criar familía
      </Button>
    </Form>
  )
}

ManageActiveSubstanceFamilyForm.propTypes = {
  afterSubmit: func,
}

ManageActiveSubstanceFamilyForm.defaultProps = {
  afterSubmit: null,
}

export default ManageActiveSubstanceFamilyForm
