import React, { useState, useEffect } from 'react'
import { oneOfType, func, object, string } from 'prop-types'
import { Route } from 'react-router-dom'
import jwt from 'jsonwebtoken'

import Loading from '@packages/Loading'
import { getJwtToken, logoutUser } from '@utils/authUtils'
import { LOGIN_PATH } from '@constants/RoutesConstants'
import { JWT_TOKEN, AUTH_TOKEN } from '@constants/AuthConstants'

import MainContainer from '../../containers/MainContainer'

function PrivateRoute({ hasValidToken, ...rest }) {
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const authToken = localStorage.getItem(JWT_TOKEN)

    // eslint-disable-next-line consistent-return
    jwt.verify(authToken, process.env.REACT_APP_JWT_SECRET, (err, decoded) => {
      const validToken = decoded?.token === localStorage.getItem(AUTH_TOKEN)

      if (err || !validToken) {
        logoutUser()

        return global.location.assign(LOGIN_PATH)
      }

      setLoading(false)
    })
  }, [])

  const renderOrRedirect = () => {
    if (hasValidToken) {
      return (
        <MainContainer>
          <Route {...rest} />
        </MainContainer>
      )
    }

    return global.location.assign(LOGIN_PATH)
  }

  return (
    loading ? <Loading /> : renderOrRedirect()
  )
}

PrivateRoute.propTypes = {
  component: oneOfType([func, object]).isRequired,
  hasValidToken: string,
}

PrivateRoute.defaultProps = {
  hasValidToken: getJwtToken(),
}

export default PrivateRoute
