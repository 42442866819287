import React from "react";
import Helmet from "react-helmet";
import { useQuery } from "@apollo/react-hooks";
import { PageHeader, Affix } from "antd";
// import { InboxOutlined } from '@ant-design/icons'
import { useParams, useHistory } from "react-router-dom";

import Loading from "@packages/Loading";
import ResultError from "@packages/ResultError";
import { CAMPAIGN_DETAILS_ICON } from "@constants/IconsConstants";
import { OWNER } from "@constants/AppConstants";
import { getCampaignQuery } from "@graphql/queries";

import ManageCampaignFieldbooks from "../ManageCampaignFieldbooks";

function ManageCampaignDetails() {
  const { id } = useParams();
  const { goBack } = useHistory();
  const queryVars = { variables: { id } };
  const { loading, error, data } = useQuery(getCampaignQuery, { ...queryVars });

  if (loading) return <Loading />;
  if (error) return <ResultError />;

  const { year, culture } = data && data.campaign;

  return (
    !loading &&
    !error && (
      <>
        <Helmet>
          <title>
            {`${culture.name} ${year}`} ∙ {OWNER}
          </title>
        </Helmet>

        <Affix offsetTop={10}>
          <PageHeader
            title={culture.name}
            subTitle={year}
            onBack={() => goBack()}
            avatar={{ src: CAMPAIGN_DETAILS_ICON }}
            style={{
              background: `#fff`,
              boxShadow: `0 3px 2px rgba(0, 0, 0, .05)`,
            }}
            // extra={[
            //   <Button type="danger" icon={<InboxOutlined />} key="arquiveCampaign">
            //     Arquivar
            //   </Button>,
            // ]}
          />
        </Affix>

        <ManageCampaignFieldbooks campaignId={id} />
      </>
    )
  );
}

export default ManageCampaignDetails;
