import { DeleteOutlined, EditOutlined, PlusCircleOutlined, RedoOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Popconfirm, Row, Skeleton, Table } from "antd";
import { shape } from "prop-types";
import React, { useEffect, useState } from "react";

import { useDeleteFertilizationPlan } from "@graphql/mutations";
import { getFieldbookQuery } from "@graphql/queries";

import FieldbookFertilizationPlansHistoric from "../FieldbookFertilizationPlansHistoric";

import Form from "./form";

const tableProps = {
  size: "middle",
  pagination: false,
};

function FieldbookFertilizationPlans({ fieldbook }) {
  const [modalVisible, setModalVisible] = useState(false);
  const toggleModal = () => setModalVisible(!modalVisible);
  const [editableData, setEditableData] = useState();
  const [importHistoric, setImportHistoric] = useState(false);
  const toggleImportHistoric = () => setImportHistoric(!importHistoric);
  const [deleteFertilizationPlan] = useDeleteFertilizationPlan();
  const data = fieldbook.fertilizationPlans;

  const handleEdit = record => {
    setEditableData(record);
    setModalVisible(true);
  };

  const handleDelete = id => {
    deleteFertilizationPlan({
      variables: { id },
      refetchQueries: [
        {
          query: getFieldbookQuery,
          variables: { id: fieldbook.id },
        },
      ],
    });
  };
  const columns = [
    {
      dataIndex: "applicationType",
      title: "Tipo de Aplicação",
      render: value => value.name,
    },
    {
      dataIndex: "unity",
      title: "Unidade",
      render: value => value.name,
    },
    {
      dataIndex: "dose",
      title: "Dose",
    },
    {
      dataIndex: "kgOfN",
      title: "Kg de N",
    },
    {
      title: "NPK",
      render: (_, record) => `${record.n}-${record.p}-${record.k}`,
    },
    {
      align: "center",
      render: (_, record) => !fieldbook.isArchived && (
        <>
            <EditOutlined style={{ marginRight: 10 }} onClick={() => handleEdit(record)} />

            <Popconfirm
              title="Tem a certeza que quer eliminar este plano de fertilização?"
              onConfirm={() => handleDelete(record.id)}
              okText="Sim"
              cancelText="Não"
            >
              <DeleteOutlined />
            </Popconfirm>
        </>
      ),
    },
  ];

  useEffect(() => {
    if (!modalVisible) setEditableData();
  }, [modalVisible]);

  return (
    <>
      {!fieldbook.isArchived && (
        <>
          <Modal
            title="Histórico de Planos de Fertilização"
            width="90%"
            footer={null}
            onCancel={toggleImportHistoric}
            visible={importHistoric}
          >
            <FieldbookFertilizationPlansHistoric
              fieldbookId={fieldbook.id}
              afterSubmit={toggleImportHistoric}
              producersIds={
                fieldbook && fieldbook.producers ? fieldbook.producers.map(({ producer }) => producer.id) : []
              }
            />
          </Modal>

          <Modal
            title={`${editableData ? "Editar" : "Criar"} plano de fertilização`}
            visible={modalVisible}
            onCancel={() => toggleModal()}
            footer={null}
            width={900}
            centered
          >
            <Form
              data={fieldbook}
              afterSubmit={() => {
                toggleModal();
                setEditableData();
              }}
              editableData={editableData}
            />
          </Modal>

          <Row style={{ marginTop: 30 }}>
            <Col md={24} style={{ textAlign: "right" }}>
              <Button icon={<RedoOutlined />} onClick={toggleImportHistoric} style={{ marginRight: 10 }}>
                Importar Histórico
              </Button>

              <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => toggleModal()}>
                Plano de Fertilização
              </Button>
            </Col>
          </Row>
        </>
      )}

      <br />

      <Skeleton loading={!data} active>
        {data && (
          <Table
            columns={columns}
            dataSource={data}
            rowKey={({ id }) => id}
            rowClassName={() => "editable-row"}
            pagination={false}
            {...tableProps}
          />
        )}
      </Skeleton>
    </>
  );
}

FieldbookFertilizationPlans.propTypes = {
  fieldbook: shape({}).isRequired,
};

export default FieldbookFertilizationPlans;
