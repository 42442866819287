import { JWT_TOKEN, AUTH_TOKEN } from '@constants/AuthConstants'

export const getJwtToken = () => localStorage.getItem(JWT_TOKEN)

export const logoutUser = () => {
  const itemsToRemove = [
    JWT_TOKEN,
    AUTH_TOKEN,
  ]

  itemsToRemove.map(item => localStorage.removeItem(item))
}
