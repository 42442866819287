import React from 'react'
import { Typography, notification } from 'antd'
import { useMutation } from '@apollo/react-hooks'
import { CheckCircleOutlined, InfoCircleOutlined, DeleteOutlined } from '@ant-design/icons'

const NOTIFICATION_PLACEMENT = 'bottomRight'

export const mutationTypes = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
}

const mappedDescriptions = {
  [mutationTypes.CREATE]: `Informação criada com successo.`,
  [mutationTypes.UPDATE]: `Informação actualizada com successo.`,
  [mutationTypes.DELETE]: `Informação eliminada com successo.`,
}
const mappedIcons = {
  [mutationTypes.CREATE]: <CheckCircleOutlined style={{ color: `#52c51a` }} />,
  [mutationTypes.UPDATE]: <InfoCircleOutlined style={{ color: `#1990ff` }} />,
  [mutationTypes.DELETE]: <DeleteOutlined style={{ color: `#faae13` }} />,
}

export const notificationSuccess = (msg, type) => (
  notification.info({
    message: msg,
    description: mappedDescriptions[type],
    placement: NOTIFICATION_PLACEMENT,
    icon: mappedIcons[type],
  })
)

export const notificationError = data => (
  notification.error({
    message: `Algo correu mal`,
    description: <Typography.Text copyable>{data.message}</Typography.Text>,
    placement: NOTIFICATION_PLACEMENT,
    duration: 0,
  })
)

export default (mutation, msg, type) => useMutation(mutation, msg && {
  onCompleted: () => notificationSuccess(msg, type),
  onError: data => notificationError(data),
})
