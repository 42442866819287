import React, { useState } from 'react'
import { Input, Button } from 'antd'
import Highlighter from 'react-highlight-words'
import { FilterOutlined, SearchOutlined } from '@ant-design/icons'

const handleSearch = (selectedKeys, confirm, dataIndex, setSearchedColumn, setSearchText) => {
  confirm()
  setSearchText(selectedKeys[0])
  setSearchedColumn(dataIndex)
}

const handleReset = (clearFilters, setSearchText) => {
  clearFilters()
  setSearchText()
}

export const useColumnFilterProps = dataIndex => {
  const [searchedColumn, setSearchedColumn] = useState()
  const [searchText, setSearchText] = useState()
  let searchInput

  return ({
    // eslint-disable-next-line react/prop-types
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { searchInput = node }}
          placeholder="Filtrar"
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex, setSearchedColumn, setSearchText)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />

        <Button
          type="primary"
          onClick={() => handleSearch(selectedKeys, confirm, dataIndex, setSearchedColumn, setSearchText)}
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Filtrar
        </Button>
        <Button onClick={() => handleReset(clearFilters, setSearchText)} size="small" style={{ width: 90 }}>
          Limpar
        </Button>
      </div>
    ),
    filterIcon: filtered => (
      filtered ? (
        <FilterOutlined style={{ color: '#454ADE' }} />
      ) : (
        <SearchOutlined />
      )
    ),
    onFilter: (value, record) => record[dataIndex] && record[dataIndex]
      .toString()
      .toLowerCase()
      .includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: visible => {
      if (visible) {
        setTimeout(() => searchInput.select())
      }
    },
    render: text => (searchedColumn === dataIndex ? (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ) : text),
  })
}
