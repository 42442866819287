import { gql } from 'apollo-boost'

import useMutation, { mutationTypes } from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation(
    $data: ProductCreateInput!
  ) {
    createOneProduct(data: $data) {
      id
      activeSubstance {
        id
        name
      }
    }
  }
`

export default () => (
  useMutation(MUTATION, `Fitofármaco criado`, mutationTypes.CREATE)
)
