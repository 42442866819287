import { Form } from '@ant-design/compatible'

import EditableCell, { EditableRow } from './EditableCell'

const Editable = {
  Components: {
    body: {
      row: EditableRow,
      cell: Form.create({ name: 'editableCell' })(EditableCell),
    },
  },
}

export default Editable
