import styled, { css } from 'styled-components'

export const Logo = styled.div`
  color: #fff;
  font-family: 'Montserrat';
  font-size: 17px;
  font-weight: 600;
  height: 30px;
  margin: 20px 25px 9px;
  text-align: center;
  text-transform: uppercase;

  ${props => props.collapsed && css`
    background: rgba(0, 0, 0, .2);
    border-radius: 6px;
    box-sizing: border-box;
    display: block;
    margin: 20px auto 9px;
    padding: 3px 0 0;
    width: 30px;
  `}
`
