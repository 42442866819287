import React, { useState, useEffect } from 'react'
import { node } from 'prop-types'
import { Link } from 'react-router-dom'
import { Layout, Menu } from 'antd'
import { Helmet } from 'react-helmet'
import {
  FlagOutlined,
  UserOutlined,
  LayoutOutlined,
  AppleOutlined,
  ExperimentOutlined,
  LogoutOutlined,
  ApiOutlined,
  DownloadOutlined,
} from '@ant-design/icons'

import * as routes from '@constants/RoutesConstants'
import { OWNER } from '@constants/AppConstants'
import { logoutUser } from '@utils/authUtils'

import * as S from './style'

const { Content, Footer, Sider } = Layout
const { SubMenu } = Menu
const mainMenus = [
  {
    path: routes.CAMPAIGNS_PATH,
    icon: <FlagOutlined />,
    name: 'Campanhas',
  },
  {
    path: routes.CULTURES_PATH,
    icon: <AppleOutlined />,
    name: 'Culturas',
  },
  {
    icon: <UserOutlined />,
    name: 'Entidades',
    subMenus: [
      {
        path: routes.PRODUCERS_PATH,
        name: `Produtores`,
      },
      {
        path: routes.PROVIDERS_PATH,
        name: `Fornecedores`,
      },
      {
        path: routes.MANUFACTURERS_PATH,
        name: `Fabricantes`,
      },
      {
        path: routes.EXPERTS_PATH,
        name: `Técnicos`,
      },
      {
        path: routes.OPERATORS_PATH,
        name: `Operadores`,
      },
    ],
  },
  {
    path: routes.PORTIONS_PATH,
    icon: <LayoutOutlined />,
    name: 'Parcelas',
  },
  {
    icon: <ExperimentOutlined />,
    name: 'Produtos',
    subMenus: [
      {
        path: routes.FERTILIZERS_PATH,
        name: `Fertilizantes`,
      },
      {
        path: routes.PRODUCTS_PATH,
        name: `Fitofármacos`,
      },
    ],
  },
  {
    icon: <ApiOutlined />,
    name: 'Outros',
    subMenus: [
      {
        path: routes.EQUIPMENTS_PATH,
        name: 'Equipamentos',
      },
      {
        path: routes.APPLICATION_TYPES_PATH,
        name: 'Tipos de Aplicação',
      },
      {
        path: routes.IRRIGATION_TYPES_PATH,
        name: 'Tipos de Rega',
      },
      {
        path: routes.PHENOLOGICAL_STATES_PATH,
        name: 'Estados Fenológicos',
      },
      {
        path: routes.ACTIVE_SUBSTANCE_FAMILIES_PATH,
        name: 'Familias',
      },
      {
        path: routes.PUMPING_SYSTEMS_PATH,
        name: 'Sistemas de Bombagem',
      },
      {
        path: routes.GOALS_PATH,
        name: 'Finalidades',
      },
    ],
  },
  {
    icon: <DownloadOutlined />,
    name: 'Exportar',
    path: routes.EXPORT_PATH,
  },
]
const contentCss = {
  padding: 24,
  background: '#fff',
  margin: '25px 15px',
  borderRadius: 6,
  boxShadow: '0 4px 0 rgba(0, 0, 0, .05)',
}
const SIDEBAR_COLLAPSED = 'SIDEBAR_COLLAPSED'
const htmlTitles = {
  dashboard: 'Dashboard',
  campaignd: 'Campanhas',
  producers: 'Produtores',
  portions: 'Parcelas',
  cultures: 'Culturas',
  products: 'Produtos',
}

function MainContainer({ children }) {
  const sidebarCollapsed = localStorage.getItem(SIDEBAR_COLLAPSED) === 'true'
  const [collapsed, setCollapsed] = useState(sidebarCollapsed)
  const routeParent = global.location.pathname.split('/')[1]
  const pageTitle = htmlTitles[routeParent] || 'Cadernos de Campo'
  const toggleSidebar = () => setCollapsed(!collapsed)

  useEffect(() => localStorage.setItem(SIDEBAR_COLLAPSED, collapsed), [collapsed])

  const logout = () => {
    logoutUser()

    return global.location.assign(routes.LOGIN_PATH)
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle} ∙ {OWNER}</title>
      </Helmet>

      <Layout style={{ minHeight: '100vh' }}>
        <Sider
          collapsed={collapsed}
          onCollapse={toggleSidebar}
          style={{ height: '100vh', position: 'fixed' }}
          collapsible
        >
          <S.Logo collapsed={collapsed}>
            {collapsed ? OWNER[0] : OWNER}
          </S.Logo>

          <Menu
            theme="dark"
            mode="inline"
            selectedKeys={[`/${routeParent}`]}
          >
            {mainMenus.map(({ path, icon, name, subMenus }) => (
              !subMenus ? (
                <Menu.Item key={path}>
                  <Link to={path}>
                    {icon}
                    <span>{name}</span>
                  </Link>
                </Menu.Item>
              ) : (
                <SubMenu
                  key={name}
                  title={
                    <span>
                      {icon}
                      <span>{name}</span>
                    </span>
                  }
                >
                  {subMenus.map(({ path, name }) => (
                    <Menu.Item key={path}>
                      <Link to={path}>
                        <span>{name}</span>
                      </Link>
                    </Menu.Item>
                  ))}
                </SubMenu>
              )
            ))}
            <Menu.Item key="logou" style={{ bottom: 50, zIndex: 9999, position: 'absolute' }}>
              <Link to="/" onClick={() => logout()}>
                <LogoutOutlined />
                <span>Sair</span>
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>

        <Layout style={{ transition: 'all .2s linear', marginLeft: collapsed ? 85 : 200 }}>
          <Content>
            <div style={contentCss}>
              {children}
            </div>
          </Content>

          <Footer style={{ textAlign: 'center' }}>
            {OWNER} &copy; {new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
    </>
  )
}

MainContainer.propTypes = {
  children: node.isRequired,
}

export default MainContainer
