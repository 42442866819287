import React, { useState, useEffect } from "react";
import { shape } from "prop-types";
import { Row, Col, Button, Modal, Table, Skeleton, Popconfirm } from "antd";
import { PlusCircleOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";

import { formatDate } from "@utils/dateUtils";
import { getFieldbookQuery } from "@graphql/queries";
import { useDeleteWatering } from "@graphql/mutations";

import FieldbookWateringForm from "./form";

const tableProps = {
  size: "middle",
  pagination: false,
};

function FieldbookWaterings({ fieldbook }) {
  const [modalVisible, setModalVisible] = useState(false);
  const toggleModal = () => setModalVisible(!modalVisible);
  const [editableData, setEditableData] = useState();
  const [deleteWatering] = useDeleteWatering();
  const data = fieldbook.waterings;

  const handleEdit = (record) => {
    setEditableData(record);
    setModalVisible(true);
  };

  const handleDelete = (id) => {
    deleteWatering({
      variables: { id },
      refetchQueries: [
        {
          query: getFieldbookQuery,
          variables: { id: fieldbook.id },
        },
      ],
    });
  };
  const columns = [
    {
      dataIndex: "startDate",
      title: "Início",
      render: formatDate,
    },
    {
      dataIndex: "endDate",
      title: "Fim",
      render: formatDate,
    },
    {
      dataIndex: "phenologicalState",
      title: "Estado Fenológico",
      render: (value) => value?.name,
    },
    {
      dataIndex: "hours",
      title: "Horas de Rega",
    },
    {
      dataIndex: "volume",
      title: "Volume de Água (m3)",
    },
    {
      align: "center",
      render: (_, record) =>
        !fieldbook.isArchived && (
          <>
            <EditOutlined style={{ marginRight: 10 }} onClick={() => handleEdit(record)} />

            <Popconfirm
              title="Tem a certeza que quer eliminar esta rega?"
              onConfirm={() => handleDelete(record.id)}
              okText="Sim"
              cancelText="Não"
            >
              <DeleteOutlined />
            </Popconfirm>
          </>
        ),
    },
  ];

  useEffect(() => {
    if (!modalVisible) {
      setEditableData();
    }
  }, [modalVisible]);

  return (
    <>
      {!fieldbook.isArchived && (
        <>
          <Modal
            title={`${editableData ? "Editar" : "Criar"} rega`}
            visible={modalVisible}
            onCancel={() => toggleModal()}
            footer={null}
            width={900}
            centered
          >
            <FieldbookWateringForm
              data={fieldbook}
              afterSubmit={() => {
                toggleModal();
                setEditableData();
              }}
              editableData={editableData}
            />
          </Modal>

          <Row style={{ marginTop: 30 }}>
            <Col md={24} style={{ textAlign: "right" }}>
              <Button type="primary" icon={<PlusCircleOutlined />} onClick={() => toggleModal()}>
                Rega
              </Button>
            </Col>
          </Row>
        </>
      )}

      <br />

      <Skeleton loading={!data} active>
        {data && (
          <Table
            columns={columns}
            dataSource={data}
            rowKey={({ id }) => id}
            rowClassName={() => "editable-row"}
            pagination={false}
            {...tableProps}
          />
        )}
      </Skeleton>
    </>
  );
}

FieldbookWaterings.propTypes = {
  fieldbook: shape({}).isRequired,
};

export default FieldbookWaterings;
