import { gql } from 'apollo-boost'

import useMutation, { mutationTypes } from '@utils/graphqlUtils'

const MUTATION = gql`
  mutation(
    $data: ActiveSubstanceCreateInput!
  ) {
    createOneActiveSubstance(data: $data) {
      id
      name
    }
  }
`

export default () => (
  useMutation(MUTATION, `Substância Activa criada`, mutationTypes.CREATE)
)
