import React, { useState } from 'react'
import { arrayOf, shape, string, number } from 'prop-types'
import { useQuery } from '@apollo/react-hooks'
import { Popconfirm, Table, Typography, Form, Button, Row, Col, Input, InputNumber, Select } from 'antd'
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons'

import { useColumnFilterProps } from '@utils/columnFilter'
import {
  useCreateParcel,
  useUpdateParcel,
  useDeleteParcel,
  useUpdateParcelHistory,
  useCreateParcelHistory,
} from '@graphql/mutations'
import { getCulturesQuery, getPortionQuery } from '@graphql/queries'

import EditableCell from '../../EditableCell'

const { Option } = Select
const { Text } = Typography
const tableProps = {
  size: 'middle',
  pagination: false,
}
const goBack = [...Array(3).keys()]
const historyYears = [...goBack.map(index => new Date().getFullYear() - (index + 1))]

function ManagePortionParcels({ portionId, data }) {
  const [form] = Form.useForm()
  const [parcelsData, setParcelsData] = useState(data)
  const [createParcel] = useCreateParcel()
  const [updateParcel] = useUpdateParcel()
  const [deleteParcel] = useDeleteParcel()
  const [updateParcelHistory] = useUpdateParcelHistory()
  const [createParcelHistory] = useCreateParcelHistory()
  const { loading: loadingCultures, data: culturesData } = useQuery(getCulturesQuery)
  const useFilterField = (field, fieldType) => useColumnFilterProps(field, fieldType)

  const handleSave = data => {
    const { id, number, area, ...rest } = data
    const newData = [...parcelsData]
    const index = newData.findIndex(item => item.id === data.id)
    const item = newData[index]
    const historyData = { ...rest }

    // eslint-disable-next-line array-callback-return
    Object.keys(historyData).map(key => {
      if (historyYears.includes(parseInt(key, 0))) {
        const { history } = historyData
        const historyExists = history.find(({ year }) => year === `${key}`)

        if (historyExists) {
          updateParcelHistory({
            variables: {
              id: historyExists?.id,
              data: {
                culture: {
                  connect: { id: historyData[key] },
                },
              },
            },
          })
        } else {
          createParcelHistory({
            variables: {
              data: {
                year: key,
                parcel: {
                  connect: { id },
                },
                culture: {
                  connect: { id: historyData[key] },
                },
              },
            },
          })
        }
      }
    })

    newData.splice(index, 1, { ...item, ...data })

    if (JSON.stringify(newData) !== JSON.stringify(parcelsData)) {
      updateParcel({
        variables: {
          portion: portionId,
          id,
          data: {
            number: { set: number },
            area: { set: Number(area) },
          },
        },
      })
        .then(({ data: { updateOnePortion: { parcels } } }) => setParcelsData(parcels))
    }
  }

  const handleDelete = id => {
    deleteParcel({
      variables: { portion: portionId, id },
      refetchQueries: ['GetPortionQuery'],
    }).then(({ data: { updateOnePortion: { parcels } } }) => setParcelsData(parcels))
  }

  const onFinish = data => {
    const { number, area, ...rest } = data
    const historyData = { ...rest }
    const history = Object.keys({ ...rest })
    const mappedHistory = history.map(year => historyData[year] && ({
      year,
      culture: {
        connect: { id: historyData[year] },
      },
    }))
    const filteredHistory = mappedHistory.filter(Boolean)

    createParcel({
      variables: {
        id: portionId,
        data: {
          parcels: {
            create: {
              number,
              area,
              history: {
                create: [...Object.values(filteredHistory)],
              },
            },
          },
        },
      },
      refetchQueries: [
        {
          query: getPortionQuery,
          variables: {
            id: portionId,
          },
        },
      ],
    })
      .then(({ data: { updateOnePortion: { parcels } } }) => setParcelsData(parcels), form.resetFields())
  }
  const columns = [
    {
      dataIndex: 'number',
      title: 'Número',
      editable: true,
      ...useFilterField(`number`),
      render: value => <Text code>{value}</Text>,
    },
    {
      dataIndex: 'area',
      title: 'Área',
      editable: true,
      inputType: `number`,
      ...useFilterField('area'),
      render: value => <Text code>{value}</Text>,
    },
    ...historyYears.map(year => ({
      dataIndex: `${year}`,
      title: year,
      inputType: `select`,
      inputData: culturesData?.cultures,
      editable: true,
      render: (_, { history }) => {
        const getHistory = history?.find(h => h.year === `${year}`)

        return getHistory?.culture?.name
      },
    })),
    {
      align: 'center',
      render: (value, { id, number }) => (
        <Popconfirm
          title={`Tem a certeza que quer eliminar ${number} ?`}
          onConfirm={() => handleDelete(id)}
          okText="Sim"
          cancelText="Não"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ]
  const mappedColumns = columns.map(col => {
    if (!col.editable) return col

    return {
      ...col,
      onCell: record => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        inputType: col.inputType,
        inputData: col.inputData,
        title: col.title,
        handleSave,
      }),
    }
  })

  return (
    <>
      <Form onFinish={onFinish} form={form}>
        <Row gutter={16}>
          <Col span={6}>
            <Form.Item
              name="number"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <Input placeholder="Número de parcelário" size="large" autoFocus />
            </Form.Item>
          </Col>

          <Col span={4}>
            <Form.Item
              name="area"
              rules={[
                {
                  required: true,
                  message: `Campo obrigatório`,
                },
              ]}
              hasFeedback
            >
              <InputNumber
                placeholder="Área do parcelário"
                size="large"
                style={{ width: '100%' }}
              />
            </Form.Item>
          </Col>

          {historyYears.map(year => (
            <Col span={3} key={year}>
              <Form.Item name={year}>
                <Select
                  loading={loadingCultures}
                  placeholder={year}
                  size="large"
                  optionFilterProp="children"
                  showSearch
                >
                  {culturesData?.cultures.map(({ id, name }) => (
                    <Option value={id} key={id}>{name}</Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
          ))}

          <Col span={5}>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              icon={<PlusCircleOutlined />}
              block
            >
              Parcelário
            </Button>
          </Col>
        </Row>
      </Form>

      {parcelsData && (
        <Table
          components={EditableCell.Components}
          columns={mappedColumns}
          dataSource={parcelsData}
          rowKey={({ id }) => id}
          rowClassName={() => 'editable-row'}
          {...tableProps}
        />
      )}
    </>
  )
}

ManagePortionParcels.propTypes = {
  portionId: string.isRequired,
  data: arrayOf(
    shape({
      id: string,
      number: string,
      area: number,
    }),
  ),
}

ManagePortionParcels.defaultProps = {
  data: [],
}

export default ManagePortionParcels
