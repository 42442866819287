import React, { useState } from 'react'
import { useQuery } from '@apollo/react-hooks'
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons'
import { PageHeader, Card, Affix } from 'antd'
import { useParams, useHistory } from 'react-router-dom'

import Loading from '@packages/Loading'
import ResultError from '@packages/ResultError'
import { PORTIONS_ICON } from '@constants/IconsConstants'
import { getPortionQuery } from '@graphql/queries'

import ManagePortionParcels from '../ManagePortionParcels'
import ManagePortionMap from '../ManagePortionMap'

import * as S from './style'

function ManagePortionDetails() {
  const { id } = useParams()
  const { goBack } = useHistory()
  const queryVars = { variables: { id } }
  const { loading, error, data } = useQuery(getPortionQuery, { ...queryVars })
  const [showMap, setShowMap] = useState(true)
  const cardExtraContent = (
    <S.MapToggle
      onClick={() => setShowMap(!showMap)}
      role="button"
      tabIndex="0"
    >
      {showMap ? <EyeInvisibleOutlined /> : <EyeOutlined />}
    </S.MapToggle>
  )

  if (loading) return <Loading />
  if (error) return <ResultError />

  const { name, parcels, coords, polygons, markers, mapZoom } = data && data.portion
  const mapMarkers = { coords, polygons, markers, mapZoom }

  return (
    !loading && !error && (
      <>
        <Affix offsetTop={10}>
          <PageHeader
            title={name}
            onBack={() => goBack()}
            avatar={{ src: PORTIONS_ICON }}
            style={{
              background: `#fff`,
              boxShadow: `0 3px 2px rgba(0, 0, 0, .05)`,
            }}
          />
        </Affix>

        <Card title="Parcelários">
          <ManagePortionParcels portionId={id} data={parcels} />
        </Card>

        <br />

        <Card title="Localização" extra={cardExtraContent}>
          {showMap ? (
            <ManagePortionMap portionId={id} data={mapMarkers} />
          ) : (
            <div>
              Clique em <EyeOutlined /> para ver o mapa.
            </div>
          )}
        </Card>
      </>
    )
  )
}

export default ManagePortionDetails
