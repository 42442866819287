import { gql } from "apollo-boost";

import useMutation from "@utils/graphqlUtils";

const MUTATION = gql`
  mutation ($id: String!, $data: FieldbookUpdateInput!) {
    updateOneFieldbook(where: { id: $id }, data: $data) {
      id
    }
  }
`;

export default () => useMutation(MUTATION);
