import React from "react";
import {
  PageHeader,
  Divider,
  Row,
  Col,
  Spin,
} from "antd";
import { useQuery } from "@apollo/react-hooks";
import { LoadingOutlined } from "@ant-design/icons";

import { EXPORT_ICON } from "@constants/IconsConstants";
import { getFieldbooksExportQuery } from "@graphql/queries";

import Pesticides from "./pesticides";

function Export() {
  const { loading, data } = useQuery(
    getFieldbooksExportQuery,
  );

  return (
    <>
      <PageHeader title="Exportar" avatar={{ src: EXPORT_ICON }} />

      {loading ? (
         <div style={{ fontWeight: "bold", margin: "150px 0", textAlign: "center" }}>
          <Spin
            indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
          />
          <div style={{ marginTop: 20 }}>A carregar dados...</div>
        </div>
      ) : (
        <>
          <Divider orientation="left">O que deseja exportar?</Divider>

          <br />

          <Row gutter={16}>
            <Col span={8}>
              <Pesticides fieldbooks={data?.fieldbooks} />
            </Col>
          </Row>
        </>
      )}
    </>
  );
}

export default Export;
