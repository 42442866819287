import React, { useState, useEffect } from "react";
import { PageHeader, Button, Table, Skeleton, Modal, Popconfirm } from "antd";
import { useQuery } from "@apollo/react-hooks";
import { PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import ResultError from "@packages/ResultError";
import { CULTURES_ICON } from "@constants/IconsConstants";
import { CULTURES_PATH } from "@constants/RoutesConstants";
import { useColumnFilterProps } from "@utils/columnFilter";
import { getCulturesQuery } from "@graphql/queries";
import { useUpdateCulture, useDeleteCulture } from "@graphql/mutations";

import EditableCell from "../../EditableCell";
import ManageCultureForm from "../ManageCultureForm";

const tableProps = {
  size: "middle",
  pagination: false,
};
const buttonProps = {
  type: "primary",
  icon: <PlusOutlined />,
  key: "newCulture",
};

function ManageCultureTable() {
  const [culturesData, setCulturesData] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const { error, data } = useQuery(getCulturesQuery);
  const [updateCulture] = useUpdateCulture();
  const [deleteCulture] = useDeleteCulture();
  const toggleModal = () => setModalVisible(!modalVisible);
  const useFilterField = (field) => useColumnFilterProps(field);
  const getCulturePath = (id) => `${CULTURES_PATH}/${id}/details`;

  useEffect(() => {
    if (data) setCulturesData(data.cultures);
  }, [data]);

  const handleSave = (data) => {
    const { id, name } = data;
    const newData = [...culturesData];
    const index = newData.findIndex((item) => item.id === data.id);
    const item = newData[index];

    newData.splice(index, 1, { ...item, ...data });

    if (JSON.stringify(newData) !== JSON.stringify(culturesData)) {
      updateCulture({
        variables: {
          id,
          data: {
            name,
          },
        },
      });

      setCulturesData(newData);
    }
  };

  const handleDelete = (id) => {
    deleteCulture({
      variables: { id },
      refetchQueries: ["GetCulturesQuery"],
    });
  };
  const columns = [
    {
      dataIndex: "name",
      title: "Nome",
      editable: true,
      ...useFilterField("name"),
    },
    {
      title: "Gerir",
      render: (value, { id }) => <Link to={getCulturePath(id)}>Gerir cultura</Link>,
    },
    {
      align: "center",
      render: (value, { id, name }) => (
        <Popconfirm
          title={`Tem a certeza que quer eliminar ${name} ?`}
          onConfirm={() => handleDelete(id)}
          okText="Sim"
          cancelText="Não"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];
  const mappedColumns = columns.map((col) => {
    if (!col.editable) return col;

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <>
      <Modal title="Nova cultura" visible={modalVisible} onCancel={() => toggleModal()} footer={null}>
        <ManageCultureForm afterSubmit={() => toggleModal()} />
      </Modal>

      <PageHeader
        title="Culturas"
        avatar={{ src: CULTURES_ICON }}
        extra={[
          <Button {...buttonProps} onClick={() => toggleModal()}>
            Nova cultura
          </Button>,
        ]}
      />

      {error && <ResultError />}

      <Skeleton loading={!error && !culturesData} active>
        {culturesData && (
          <Table
            components={EditableCell.Components}
            columns={mappedColumns}
            dataSource={culturesData}
            rowKey={({ id }) => id}
            rowClassName={() => "editable-row"}
            {...tableProps}
          />
        )}
      </Skeleton>
    </>
  );
}

export default ManageCultureTable;
