import { gql } from "apollo-boost";

import useMutation, { mutationTypes } from "@utils/graphqlUtils";

const MUTATION = gql`
  mutation($id: String!) {
    deleteOneFertilizationPlan(
      where: { id: $id }
    ) {
      id
    }
  }
`;

export default () => (
  useMutation(MUTATION, `Plano de fertilização eliminado`, mutationTypes.DELETE)
);

