import React from "react";
import { useQuery } from "@apollo/react-hooks";
import { Card } from "antd";
import { Route, useHistory, useParams } from "react-router-dom";

import Loading from "@packages/Loading";
import ResultError from "@packages/ResultError";
import { getFieldbookQuery } from "@graphql/queries";
import { FIELDBOOKS_PATH, ARCHIVED_FIELDBOOK_PATH } from "@constants/RoutesConstants";

import FieldbookHeader from "../FieldbookHeader";
import FieldbookMenu from "../FieldbookMenu";
import FieldbookInstallations from "../FieldbookInstallations";
import FieldbookPesticides from "../FieldbookPesticides";
import FieldbookFertilizations from "../FieldbookFertilizations";
import FieldbookWaterings from "../FieldbookWaterings";
import FieldbookFertilizationPlans from "../FieldbookFertilizationPlans";
import FieldbookVisitReports from "../FieldbookVisitReports";
import FieldbookDetails from "../FieldbookDetails";
import FieldbookWeeklyWateringLog from "../FieldbookWeeklyWateringLogs";

const getArchivedPath = ({ id, currentVersion }) => `${ARCHIVED_FIELDBOOK_PATH}/${id}/${currentVersion}/installations`;

function FieldbookSetup() {
  const { push } = useHistory();
  const { id } = useParams();
  const queryVars = { variables: { id } };
  const { loading, error, data } = useQuery(getFieldbookQuery, {
    ...queryVars,
  });

  if (loading) return <Loading />;
  if (error) return <ResultError />;

  const { fieldbook } = data;

  if (fieldbook.isArchived) push(getArchivedPath(fieldbook));

  return (
    !loading &&
    !error && (
      <>
        <FieldbookHeader fieldbook={fieldbook} />
        <FieldbookDetails fieldbook={fieldbook} />

        <br />
        <br />

        <Card>
          <FieldbookMenu id={fieldbook?.id} />

          <Route
            path={`${FIELDBOOKS_PATH}/:id/installations`}
            render={() => <FieldbookInstallations fieldbook={fieldbook} />}
          />

          <Route
            path={`${FIELDBOOKS_PATH}/:id/pesticides`}
            render={() => <FieldbookPesticides fieldbook={fieldbook} />}
          />

          <Route
            path={`${FIELDBOOKS_PATH}/:id/fertilizations`}
            render={() => <FieldbookFertilizations fieldbook={fieldbook} />}
          />

          <Route
            path={`${FIELDBOOKS_PATH}/:id/waterings`}
            render={() => <FieldbookWaterings fieldbook={fieldbook} />}
          />

          <Route
            path={`${FIELDBOOKS_PATH}/:id/weekly-watering-logs`}
            render={() => <FieldbookWeeklyWateringLog fieldbook={fieldbook} />}
          />

          <Route
            path={`${FIELDBOOKS_PATH}/:id/fertilization-plans`}
            render={() => <FieldbookFertilizationPlans fieldbook={fieldbook} />}
          />

          <Route
            path={`${FIELDBOOKS_PATH}/:id/visit-reports`}
            render={() => <FieldbookVisitReports fieldbook={fieldbook} />}
          />
        </Card>
      </>
    )
  );
}

export default FieldbookSetup;
