import { gql } from "apollo-boost";

import useMutation, { mutationTypes } from "@utils/graphqlUtils";

const MUTATION = gql`
  mutation ($id: String!, $data: WeeklyWateringLogUpdateInput!) {
    updateOneWeeklyWateringLog(where: { id: $id }, data: $data) {
      id
    }
  }
`;

export default () => useMutation(MUTATION, `Registo Semanal Rega actualizado`, mutationTypes.UPDATE);
