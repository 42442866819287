import React, { useContext, useState, useEffect, useRef } from 'react'
import { Input, Form, InputNumber, Select } from 'antd'
import { string, shape, bool, node, func, number, arrayOf } from 'prop-types'

const EditableContext = React.createContext()
const { Option } = Select

export const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm()

  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  )
}

EditableRow.propTypes = {
  index: number,
}

EditableRow.defaultProps = {
  index: undefined,
}

function EditableCell({
  title,
  editable,
  inputType,
  inputData,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) {
  const [editing, setEditing] = useState(false)
  const inputRef = useRef()
  const form = useContext(EditableContext)

  useEffect(() => {
    if (editing) {
      inputRef.current.focus()
    }
  }, [editing])

  const toggleEdit = () => {
    let value = record[dataIndex]

    if (value && typeof value === 'object') {
      value = value.id
    }

    setEditing(!editing)
    form.setFieldsValue({ [dataIndex]: value })
  }

  const save = async () => {
    try {
      const values = await form.validateFields()

      toggleEdit()
      handleSave({ ...record, ...values })
    } catch (errInfo) {
      console.log('Save failed:', errInfo)
    }
  }

  const renderInput = type => {
    switch (type) {
      case `number`: {
        return (
          <InputNumber
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
            style={{ width: `100%` }}
          />
        )
      }
      case `text`: {
        return (
          <Input
            ref={inputRef}
            onPressEnter={save}
            onBlur={save}
          />
        )
      }
      case `select`: {
        return (
          <Select
            ref={inputRef}
            optionFilterProp="children"
            onChange={save}
            showSearch
          >
            {inputData.map(({ id, name }) => (
              <Option value={id} key={id}>{name}</Option>
            ))}
          </Select>
        )
      }
      default: {
        return `.`
      }
    }
  }
  let childNode = children

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} é obrigatório.`,
          },
        ]}
      >
       {renderInput(inputType)}
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
        role="button"
        tabIndex={dataIndex}
      >
        {children}
      </div>
    )
  }

  return <td {...restProps}>{childNode}</td>
}

EditableCell.propTypes = {
  title: node,
  editable: bool,
  inputType: string,
  inputData: arrayOf(
    shape({
      id: string,
      name: string,
    }),
  ),
  children: node.isRequired,
  dataIndex: string,
  record: shape({
    key: string,
    name: string,
    age: string,
    address: string,
  }),
  handleSave: func,
}

EditableCell.defaultProps = {
  title: undefined,
  editable: false,
  inputType: `text`,
  inputData: null,
  dataIndex: undefined,
  handleSave: () => {},
  record: {},
}

export default EditableCell

