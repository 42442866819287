import React from "react";
import { Table, TableHeader, TableBody, DataTableCell } from "@david.kucsai/react-pdf-table";
import { arrayOf, shape, func, number } from "prop-types";
import { View } from "@react-pdf/renderer";

import { formatDate } from "@utils/dateUtils";

import { cellStyle } from "./style";

function Fertilizations({ data, soil, nitrogenUnits, cell: Cell, p, k }) {
  const calculateN = data.map(({ fertilizer: { n }, dose }) => (n / 100) * dose).reduce((a, b) => a + b, 0);
  const setToData = [
    {
      n: calculateN,
      p,
      k,
    },
  ];

  return (
    <>
      <Table data={data}>
        <TableHeader>
          <Cell>Data</Cell>
          <Cell>Tipo de Aplicação</Cell>
          <Cell>Fertilizante</Cell>
          <Cell>Formulação</Cell>
          <Cell>Dose</Cell>
          <Cell>Fornecedor</Cell>
        </TableHeader>
        <TableBody>
          <DataTableCell style={cellStyle} getContent={({ date }) => formatDate(date)} />
          <DataTableCell style={cellStyle} getContent={({ applicationType }) => applicationType?.name} />
          <DataTableCell style={cellStyle} getContent={({ fertilizer }) => fertilizer?.name} />
          <DataTableCell style={cellStyle} getContent={({ fertilizer: { n, p, k } }) => `${n}:${p}:${k}`} />
          <DataTableCell style={cellStyle} getContent={({ dose, unity }) => `${dose} ${unity?.name}`} />
          <DataTableCell style={cellStyle} getContent={({ provider }) => provider?.name} />
        </TableBody>
      </Table>

      <View style={{ marginTop: 40 }}>
        <Table data={setToData}>
          <TableHeader>
            <Cell>Azoto (N)</Cell>
            <Cell>Fósforo (P)</Cell>
            <Cell>Potássio (K)</Cell>
          </TableHeader>

          <TableBody>
            <DataTableCell style={cellStyle} getContent={({ n }) => (n + nitrogenUnits + soil).toFixed(2)} />
            <DataTableCell style={cellStyle} getContent={({ p }) => p.toFixed(2)} />
            <DataTableCell style={cellStyle} getContent={({ k }) => k.toFixed(2)} />
          </TableBody>
        </Table>
      </View>
    </>
  );
}

Fertilizations.propTypes = {
  data: arrayOf(shape({})),
  cell: func.isRequired,
  nitrogenUnits: number.isRequired,
  soil: number.isRequired,
  p: number.isRequired,
  k: number.isRequired,
};

Fertilizations.defaultProps = {
  data: [],
};

export default Fertilizations;
