
import {
  Card,
  Button,
  Select,
} from "antd";
import { arrayOf } from "prop-types";
import {
  DownloadOutlined,
  ExperimentOutlined,
} from "@ant-design/icons";
import { json2excel } from "js2excel";
import React, { useEffect, useState } from "react";
import uniq from "lodash/uniq";
import moment from "moment";

import { meteorologyTypes } from "@constants/AppConstants";

const iconStyle = { fontSize: 48, marginBottom: 40 };
const { Option } = Select;

function Pesticides({ fieldbooks }) {
  const availableYears = uniq(
    fieldbooks?.map(({ campaign }) => campaign.year)?.sort(),
  );
  const [year, setYear] = useState(null);
  const [data, setData] = useState([]);

  useEffect(() => {
    const generated = [];
    const d = year
      ? fieldbooks?.filter(
          ({ campaign }) => campaign.year === year
        )
      : fieldbooks;

    if (d) {
      // eslint-disable-next-line no-unused-expressions
      d?.map(fieldbook => {
        const producers = fieldbook?.producers;
        const parcel = fieldbook?.parcels[0]?.parcel;

        if (!parcel) return;

        // eslint-disable-next-line no-unused-expressions
        fieldbook.pesticides?.map(pesticide => {
          generated.push({
            Organização: "Provape",
            Produtor: producers
              .map(({ producer }) => producer?.name)
              .join(", "),
            Número: producers
              .map(({ producer }) => producer?.number)
              .join(", "),
            Parcela: parcel.portion?.name,
            Localização: parcel.portion?.location,
            Área: pesticide.area,
            Finalidade: pesticide.product?.activeSubstance?.goal?.name,
            "Data Aplicação": moment(pesticide.date).format("DD-MM-YYYY"),
            "Subs. Activa": pesticide.product?.activeSubstance?.name,
            "Nome Comercial": pesticide.product?.name,
            Fabricante: pesticide.product?.manufacturer?.name,
            "Quantidade / ha": pesticide.product?.dose,
            Unidade: pesticide.product?.unity?.name,
            Calda: pesticide.sauce,
            Meteorologia: meteorologyTypes[pesticide.meteorology],
          });
        });
      });

      setData(generated);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldbooks, year]);

  const downloadFile = () => {
    if (data.length > 0) {
      json2excel({
        data,
        name: "Fitofarmacos",
      });
    }
  };

  return (
    <Card title="Fitofármacos" style={{ textAlign: "center" }}>
      <div>
        <ExperimentOutlined style={iconStyle} />
      </div>

      <Select
        placeholder="Ano"
        optionFilterProp="children"
        onChange={setYear}
        disabled={availableYears.length === 0}
        style={{ marginRight: 10 }}
      >
        <Option value={null}>Todos</Option>

        {availableYears?.map(year => (
          <Option value={year} key={year}>
            {year}
          </Option>
        ))}
      </Select>

      <Button
        type="primary"
        icon={<DownloadOutlined />}
        onClick={downloadFile}
      >
        Exportar {data.length} fitofármacos
      </Button>
    </Card>
  );
}

Pesticides.propTypes = {
  fieldbooks: arrayOf({}).isRequired,
};

export default Pesticides;
