import React, { useState, useEffect } from "react";
import { PageHeader, Button, Table, Skeleton, Modal, Popconfirm } from "antd";
import { useQuery } from "@apollo/react-hooks";
import {
  PlusOutlined,
  DeleteOutlined,
  CloudUploadOutlined,
  CloudDownloadOutlined,
} from "@ant-design/icons";
import { shape, func } from "prop-types";
import { Link } from "react-router-dom";

import ResultError from "@packages/ResultError";
import { PORTIONS_ICON } from "@constants/IconsConstants";
import { PORTIONS_PATH } from "@constants/RoutesConstants";
import { useColumnFilterProps } from "@utils/columnFilter";
import { getPortionsQuery } from "@graphql/queries";
import { useUpdatePortion, useDeletePortion } from "@graphql/mutations";

import ManagePortionForm from "../ManagePortionForm";
import ManagePortionCsvUpload from "../ManagePortionCsvUpload";
import ManagePortionExportCsv from "../ManagePortionExportCsv";
import EditableCell from "../../EditableCell";

const tableProps = {
  size: "middle",
  pagination: false,
};
const buttonProps = {
  type: "primary",
  icon: <PlusOutlined />,
  key: "newPortion",
};
const csvButtonProps = {
  icon: <CloudUploadOutlined />,
  key: "uploadCsv",
};

function ManagePortionTable({ history }) {
  const [portionsData, setPortionsData] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [csvModalVisible, setCsvModalVisible] = useState(false);
  const [exportCsvModalVisible, setExportCsvModalVisible] = useState(false);
  const { error, data } = useQuery(getPortionsQuery);
  const [updatePortion] = useUpdatePortion();
  const [deletePortion] = useDeletePortion();
  const toggleModal = () => setModalVisible(!modalVisible);
  const toggleCsvModal = () => setCsvModalVisible(!csvModalVisible);
  const toggleExportCsvModal = () =>
    setExportCsvModalVisible(!exportCsvModalVisible);
  const useFilterField = (field) => useColumnFilterProps(field);
  const getDetailsPath = (id) => `${PORTIONS_PATH}/${id}/details`;

  useEffect(() => {
    if (data) setPortionsData(data.portions);
  }, [data]);

  const handleSave = (data) => {
    const { id, name, location, identification } = data;
    const newData = [...portionsData];
    const index = newData.findIndex((item) => item.id === data.id);
    const item = newData[index];

    newData.splice(index, 1, { ...item, ...data });

    if (JSON.stringify(newData) !== JSON.stringify(portionsData)) {
      updatePortion({
        variables: {
          id,
          data: {
            name: { set: name },
            location: { set: location },
            identification: { set: identification },
          },
        },
        refetchQueries: [
          {
            query: getPortionsQuery,
          },
        ],
      });

      setPortionsData(newData);
    }
  };

  const handleDelete = (id) => {
    deletePortion({
      variables: { id },
      refetchQueries: [
        {
          query: getPortionsQuery,
        },
      ],
    });
  };

  const handleCreate = (id) => {
    toggleModal();

    return history.push(getDetailsPath(id));
  };
  const columns = [
    {
      dataIndex: "name",
      title: "Nome",
      editable: true,
      ...useFilterField("name"),
    },
    {
      dataIndex: "location",
      title: "Localização",
      editable: true,
      ...useFilterField("location"),
    },
    {
      dataIndex: "identification",
      title: "Identificação",
      editable: true,
      ...useFilterField("identification"),
    },
    {
      title: "Gerir",
      render: (value, { id }) => (
        <Link to={getDetailsPath(id)}>Gerir parcela</Link>
      ),
    },
    {
      align: "center",
      render: (value, { id, name }) => (
        <Popconfirm
          title={`Tem a certeza que quer eliminar ${name} ?`}
          onConfirm={() => handleDelete(id)}
          okText="Sim"
          cancelText="Não"
        >
          <DeleteOutlined />
        </Popconfirm>
      ),
    },
  ];
  const mappedColumns = columns.map((col) => {
    if (!col.editable) return col;

    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });

  return (
    <>
      <Modal
        title="Nova parcela"
        visible={modalVisible}
        onCancel={() => toggleModal()}
        footer={null}
      >
        <ManagePortionForm afterSubmit={(id) => handleCreate(id)} />
      </Modal>

      <Modal
        title="Carregar ficheiro CSV"
        visible={csvModalVisible}
        onCancel={() => toggleCsvModal()}
        footer={null}
      >
        <ManagePortionCsvUpload afterSubmit={() => toggleCsvModal()} />
      </Modal>

      <Modal
        title="Exportar CSV"
        visible={exportCsvModalVisible}
        onCancel={() => toggleExportCsvModal()}
        footer={null}
      >
        <ManagePortionExportCsv afterSubmit={() => toggleExportCsvModal()} />
      </Modal>

      <PageHeader
        title="Parcelas"
        avatar={{ src: PORTIONS_ICON }}
        extra={[
          <Button {...buttonProps} onClick={() => toggleModal()}>
            Nova parcela
          </Button>,

          <Button {...csvButtonProps} onClick={() => toggleCsvModal()}>
            Carregar CSV
          </Button>,
          <Button
            key="open-download-csv"
            icon={<CloudDownloadOutlined />}
            onClick={() => toggleExportCsvModal()}
          >
            Exportar CSV
          </Button>,
        ]}
      />

      {error && <ResultError />}

      <Skeleton loading={!error && !portionsData} active>
        {portionsData && (
          <Table
            components={EditableCell.Components}
            columns={mappedColumns}
            dataSource={portionsData}
            rowKey={({ id }) => id}
            rowClassName={() => "editable-row"}
            {...tableProps}
          />
        )}
      </Skeleton>
    </>
  );
}

ManagePortionTable.propTypes = {
  history: shape({
    push: func,
  }).isRequired,
};

export default ManagePortionTable;
